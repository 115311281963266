import { Inject, Injectable, Optional } from '@angular/core';
import { HlBackendInterceptorService } from './hl-backend-interceptor/hl-backend-interceptor.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, first, of, switchMap, tap } from 'rxjs';
import { AddressInfoVersion } from '../components/hl-display-order-details/hl-display-order-details.component';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayPortalFeaturesService {
  portalName: string;

  constructor(
    protected hlBackendInterceptorService: HlBackendInterceptorService,
    protected http: HttpClient,
    @Optional()
    @Inject('environment')
    protected environment: { [s: string]: any }
  ) {
    this.portalName = this.getPortalName();
    // this.getAllPortalFeatures().pipe(first()).subscribe();
    this.getAllPublicPortalFeatures().pipe(first()).subscribe();
  }

  private _portalFeaturesSubject: BehaviorSubject<PortalFeatures> = new BehaviorSubject<PortalFeatures>(undefined);
  public portalFeaturesObservable = this._portalFeaturesSubject.asObservable();

  private _publicPortalFeaturesSubject: BehaviorSubject<PublicPortalFeatures> =
    new BehaviorSubject<PublicPortalFeatures>(undefined);
  public publicPortalFeaturesObservable = this._publicPortalFeaturesSubject.asObservable();

  getPortalName(): string {
    //For development
    if (this.environment.portalName) {
      return this.environment.portalName;
    }
    // Hostname gives us for example: sales-fr.hl-display.com
    // We want to return the first part i.e. sales-fr
    const name = window.location.hostname;
    return name.split('.')[0];
  }

  getAllPortalFeatures(): Observable<PortalFeatures> {
    const getFeaturesUrl = `json/hl-display/portal-features/${this.portalName}`;

    let headers = new HttpHeaders();
    headers = headers.set('maxAge', '-1');

    return this.http.get<PortalFeatures>(getFeaturesUrl, { headers: headers }).pipe(
      tap(portalFeatures => {
        this._portalFeaturesSubject.next(portalFeatures);
      })
    );
  }

  getAllPublicPortalFeatures(): Observable<PublicPortalFeatures> {
    const getFeaturesUrl = `public/json/hl-display/portal-features/${this.portalName}`;

    let headers = new HttpHeaders();
    headers = headers.set('maxAge', '-1');

    return this.http.get<PublicPortalFeatures>(getFeaturesUrl, { headers: headers }).pipe(
      tap(publicPortalFeatures => {
        this._publicPortalFeaturesSubject.next(publicPortalFeatures);
      })
    );
  }

  getPortalFeatureGroup(featureGroup: string): Observable<IFeatureGroup> {
    // const test: IFeatureGroup = { name: 'example' };
    // return of(test);
    let portalFeatures = this._portalFeaturesSubject.getValue();

    if (!portalFeatures) {
      return this.getAllPortalFeatures().pipe(
        switchMap(fetchedPortalFeatures => {
          if (!fetchedPortalFeatures[featureGroup]) {
            throw new Error(`Feature group ${featureGroup} does not exist`);
          } else {
            return of(fetchedPortalFeatures[featureGroup]);
          }
        })
      );
    }
    if (!portalFeatures[featureGroup]) {
      throw new Error(`Feature group ${featureGroup} does not exist`);
    }

    return of(portalFeatures[featureGroup]);
  }

  getPublicPortalFeatureGroup(featureGroup: string): Observable<IFeatureGroup> {
    // const test: IFeatureGroup = { name: 'example' };
    // return of(test);
    let publicPortalFeatures = this._publicPortalFeaturesSubject.getValue();

    if (!publicPortalFeatures) {
      return this.getAllPublicPortalFeatures().pipe(
        switchMap(fetchedPublicPortalFeatures => {
          if (!fetchedPublicPortalFeatures[featureGroup]) {
            throw new Error(`Feature group ${featureGroup} does not exist`);
          } else {
            return of(fetchedPublicPortalFeatures[featureGroup]);
          }
        })
      );
    }
    if (!publicPortalFeatures[featureGroup]) {
      throw new Error(`Feature group ${featureGroup} does not exist`);
    }

    return of(publicPortalFeatures[featureGroup]);
  }

  savePortalFeatures(portalFeatures: any): Observable<PortalFeatures> {
    const saveFeaturesUrl = `json/hl-display/portal-features/save-features/${this.portalName}`;

    return this.http.put<PortalFeatures>(saveFeaturesUrl, portalFeatures);
  }

  getAllPortalsWithFeatures(): Observable<Portals> {
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', '-1');
    const url = `json/hl-display/portal-features`;
    return this.http.get<Portals>(url, { headers: headers });
  }

  getPortalFeatures(portalName: string): Observable<PortalFeatures> {
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', '-1');
    const url = `json/hl-display/portal-features/${portalName}`;
    return this.http.get<PortalFeatures>(url, { headers: headers });
  }

  putPortalFeatures(portalFeatures: PortalFeatures, portalName: string): Observable<PortalFeatures> {
    const url = `json/hl-display/portal-features/save-features/${portalName}`;
    return this.http.put<PortalFeatures>(url, portalFeatures);
  }
}

export interface PublicPortalFeatures {
  languageSettingsGroup: LanguageSettingsGroup;
}

export interface PortalFeatures {
  //TODO: Make this interface as the backend settings-object
  priceSettingsGroup: PriceSettingsGroup;
  filterSettingsGroup: FilterSettingsGroup;
  languageSettingsGroup: LanguageSettingsGroup;
  loginSettingsGroup: LoginSettingsGroup;
  orderCheckoutSettingsGroup: CheckoutSettingsGroup;
  availabilitySettingsGroup: AvailabilitySettingsGroup;
  orderEditSettingsGroup: OrderEditSettingsGroup;
  openOrdersSettingsGroup: OpenOrdersSettingsGroup;
  orderSearchSettingsGroup: OrderSearchSettingsGroup;
  navbarSettingsGroup: NavbarSettingsGroup;
  customerServiceSettingsGroup: CustomerServiceSettingsGroup;
  orderProcessSettingsGroup: OrderProcessSettingsGroup;
  deliverySettingsGroup: DeliverySettingsGroup;
  addressSettingsGroup: AdressSettingsGroup;
  customerListSettingsGroup: CustomerListSettingsGroup;
  customerInformationSettingsGroup: CustomerInformationSettingsGroup;
  podSettingsGroup: PODSettingsGroup;
  productListInfoSettingsGroup: ProductListInfoSettingsGroup;
}

export interface IFeatureGroup {
  name: string;
  //Has to be extended by all groups
}

export interface OrderSearchSettingsGroup extends IFeatureGroup {
  orderSearchByProductId: boolean;
  filterOrdersOnPOD: boolean;
  showOrderStatus: boolean;
  showOrderTrackingLink: boolean;
  filterOrdersOnStatusAndType: boolean;
  showCancelledOrders: boolean;
}

export interface AvailabilitySettingsGroup extends IFeatureGroup {
  hasAvailabilityLeadTime: boolean;
  availabilityBufferDays: number;
}

export interface CustomerInformationSettingsGroup extends IFeatureGroup {
  meetingTabEnabled: boolean;
  productsTabEnabled: boolean;
}

export interface OrderProcessSettingsGroup extends IFeatureGroup {
  adaptCustomerIdAndDeliveryPointStepEnabled: boolean;
  orpArtbeskrStepEnabled: boolean;
  orderValidationStepEnabled: boolean;
  setOrderTypeStepEnabled: boolean;
  shouldAdjustDropShippingStepEnabled: boolean;
  msFlowOrderConfirmationStepEnabled: boolean;
  setZeroRowPriceStepEnabled: boolean;
  quoteApprovalStepEnabled: boolean;
  shouldApplyFinancialGroupDiscount: boolean;
  mainCustomer: string;
  subCustomer: string;
}

export interface LanguageSettingsGroup extends IFeatureGroup {
  portalLanguages: string[];
  defaultLanguage: string;
  dateFormat: string;
  showLanguageSelectionOnLogin: boolean;
}

export interface CustomerListSettingsGroup extends IFeatureGroup {
  customerFiltersEnabled: boolean;
  podShowOnlyDeliveryCustomers: boolean;
}

export interface LoginSettingsGroup extends IFeatureGroup {
  oauthLoginEnabled: boolean;
}

export interface DeliverySettingsGroup extends IFeatureGroup {
  fetchDeliveryCustomerFromOrdLevPlats: boolean;
}

export interface AdressSettingsGroup extends IFeatureGroup {
  addressInfoDisplayVersion: AddressInfoVersion;
}

export interface PriceSettingsGroup extends IFeatureGroup {
  includeWarehouseProductsInProductList: boolean;
  displayStaffedPrices: boolean;
  displayDiscountFromPriceList: boolean;
  requestPricelistAssortmentWithWebCondition: boolean;
  defaultCurrencyCode: string;
}

export interface CheckoutSettingsGroup extends IFeatureGroup {
  shouldShowFirstAvailableDateInCheckout: boolean;
  shouldShowCommentsInCartList: boolean;
  useTradePolicy: boolean;
  replyToEmail: string;
  showApprovalQuote: boolean;
  showCreateOrder: boolean;
  showCreateSampleOrder: boolean;
  changeToAnyQtyAsUser: boolean;
  addInstallationChargeAutomatically: boolean;
  addInstallationChargeManually: boolean;
  installationChargeProductId: string;
  showDateInCheckout: boolean;
  minimumOrderValue: number;
  cardPaymentEnabled: boolean;
  useCustomerDefaultEmailForConfirmation: boolean;
}

export interface PODSettingsGroup extends IFeatureGroup {
  podenabled: boolean;
}

export interface OpenOrdersSettingsGroup extends IFeatureGroup {
  filterOrdersOnPOD: boolean;
  mainCountry: string;
  showInvoiceTab: boolean;
  showMeetingsTab: boolean;
  showOffersTab: boolean;
}

export interface OrderEditSettingsGroup extends IFeatureGroup {
  canEditOrderRow: boolean;
}

export interface CustomerServiceSettingsGroup extends IFeatureGroup {
  contactName: string;
  email: string;
  phoneNumber: string;
  address: string;
  address2: string;
  zipCode: string;
  city: string;
  country: string;

  hoursOpen: string;
  daysOpen: string;
  openingExtraInfo: string;
}

export interface NavbarSettingsGroup extends IFeatureGroup {
  hasDisplayPreviousOrders: boolean;
  hasShowFavourites: boolean;
}

export interface FilterSettingsGroup extends IFeatureGroup {
  hasProductLengthFilterEnabled: boolean;
  hasProductShelfTypeFilterEnabled: boolean;
  hasProductEtiquetteFilterEnabled: boolean;
  hasProductDepthFilterEnabled: boolean;
  hasProductWidthFilterEnabled: boolean;
  hasProductCategoryFilterEnabled: boolean;
  hasProductAssortmentTypeFilterEnabled: boolean;
  hasProductCapacityFilterEnabled: boolean;
  hasProductBeenPurchasedByCustomerFilterEnabled: boolean;
  hasProductMousquetairesFilterEnabled: boolean;
  hasProductDiscountedItemsFilterEnabled: boolean;
  hasProductExternalCategoryFilterEnabled: boolean;
  hasProductExternalSubCategoryFilterEnabled: boolean;
  hasProductItemGroupFilterEnabled: boolean;
  hasProductMainFunctionFilterEnabled: boolean;
  hasProductStoreCategoryFilterEnabled: boolean;
  hasProductRollOutFilterEnabled: boolean;
  hasProductHeightFilterEnabled: boolean;

  //Metadata
  hasProductColorFilterEnabled: boolean;
  hasProductMaterialFilterEnabled: boolean;
  hasProductShelfManufacturerFilterEnabled: boolean;
  hasProductStoreNonFoodFilterEnabled;

  //Inriver
  hasProductSizeFilterEnabled: boolean;
  hasProductItemDiameterMmEnabled: boolean;
  hasProductMerchandiseTypeFilterEnabled: boolean;
  hasProductMerchandiseBrandFilterEnabled: boolean;
  //DUPLICATE WITH STORE CATEGORY
  //hasProductStoreDepartmentFilterEnabled: boolean;
  hasProductTrademarkFilterEnabled: boolean;
  hasProductSystemEnabled: boolean;
  hasProductFrontFilterEnabled: boolean;
  hasProductLabelTypeFilterEnabled: boolean;
  hasProductSpringFilterEnabled: boolean;
}

export interface ProductListInfoSettingsGroup extends IFeatureGroup {
  displayExtraDescription: boolean;
}

export interface Portals {
  portals: {
    [portal: string]: PortalFeatures;
  };
}

import { Injectable } from '@angular/core';
import { SimpleConfigBaseFilter } from 'gung-list';
import { Product } from 'gung-standard';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductLengthFilterService extends SimpleConfigBaseFilter<Product> {
  private isManagersCoop = environment.managers && environment.mainCountry === 'ch' && environment.mainCustomer === 'coop';
  private isManagers = environment.managers;
  getName(): string {
    return 'LENGTH';
  }
  getOptionIds(item: Product): string[] {
    if(this.isManagersCoop || this.isManagers){
      return [item.extra.hlPimItem?.Widthmm];
    }
    return [item.extra.hlPimItem.ItemShelfLength];
  }
  getOptionName(key: string): string {
    return key + ' mm';
  }
}

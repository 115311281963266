import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CommonModalService } from 'gung-common';
import {
  AuthService,
  BaseViewConfigService,
  CustomerService,
  gungAddRemoveSpinner,
  GungModalService,
  Order,
  OrderDetailsComponent,
  OrderRow,
  PriceConfigService,
  ProductAssortmentParentService,
  ProductService
} from 'gung-standard';
import { first, forkJoin, map } from 'rxjs';
import { HlBackendInterceptorService } from '../../services/hl-backend-interceptor/hl-backend-interceptor.service';
import { HlDisplayDateUtilService } from '../../services/hl-display-date-util.service';
import { HlDisplayOrderActionConfigService } from '../../services/hl-display-order-action-config.service';
import { HlDisplayOrderService } from '../../services/hl-display-order.service';
import { AdressSettingsGroup, DeliverySettingsGroup, HlDisplayPortalFeaturesService } from '../../services/hl-display-portal-features.service';
import { HlDisplayOrderAddRowModalComponent } from '../hl-display-order-add-row-modal/hl-display-order-add-row-modal.component';

export enum AddressInfoVersion {
  Default = 'DEFAULT',
  PointOfDelivery = 'POD',
  Delivery = 'DELIVERY',
}

@Component({
  selector: 'app-hl-display-order-details',
  templateUrl: './hl-display-order-details.component.html',
  styleUrls: ['./hl-display-order-details.component.scss']
})
export class HlDisplayOrderDetailsComponent extends OrderDetailsComponent {
  public isSales: boolean;
  public isManager: boolean;
  public dateFormat: string = 'yyyy-MM-dd'; //Fallback
  public fetchDeliveryCustomerFromOrdLevPlats: boolean = false;

  activeIds = ['panel-1', 'panel-3'];

  addressInfoDisplayVersion: AddressInfoVersion = AddressInfoVersion.Default;

  constructor(
    protected route: ActivatedRoute,
    protected orderService: HlDisplayOrderService,
    protected authService: AuthService,
    protected hlBackendInterceptor: HlBackendInterceptorService,
    protected modalService: NgbModal,
    public configService: HlDisplayOrderActionConfigService,
    protected productService: ProductService,
    public dateUtilService: HlDisplayDateUtilService,
    protected baseViewConfigService: BaseViewConfigService,
    protected productAssortmentParentService: ProductAssortmentParentService,
    protected translateService: TranslateService,
    protected gungModalService: GungModalService,
    protected customerService: CustomerService,
    protected commonModalService: CommonModalService,
    protected hldDisplayPortalFeaturesService: HlDisplayPortalFeaturesService,
    public priceConfigService: PriceConfigService
  ) {
    super(
      route,
      orderService,
      authService,
      hlBackendInterceptor,
      productService,
      dateUtilService,
      baseViewConfigService,
      productAssortmentParentService,
      commonModalService,
      priceConfigService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    forkJoin([
      this.authService.getRoles().pipe(first()),
      this.hldDisplayPortalFeaturesService.getPortalFeatureGroup('deliverySettingsGroup').pipe(first(), map(deliverySettingsGroup => (deliverySettingsGroup as DeliverySettingsGroup))),
      this.hldDisplayPortalFeaturesService.getPortalFeatureGroup('addressSettingsGroup').pipe(first(), map(addressSettingsGroup => (addressSettingsGroup as AdressSettingsGroup))),
      this.dateUtilService.getLangSettingsObservable().pipe(first())
    ]).pipe(first()).subscribe(([roles, deliverySettingsGroup, addressSettingsGroup, langSettings]) => {
      this.isSales = roles.indexOf('SALES') > -1;
      this.isManager = roles.indexOf('MANAGER') > -1;

      this.dateFormat = langSettings.dateFormat;

      this.fetchDeliveryCustomerFromOrdLevPlats = deliverySettingsGroup.fetchDeliveryCustomerFromOrdLevPlats;

      this.addressInfoDisplayVersion = addressSettingsGroup.addressInfoDisplayVersion;
    });
  }

  protected mapOrder(order: Order): Order {
    order.rows = order.rows.filter(row => row.extra.orp.ordradnrstrpos === 0);
    if (this.fetchDeliveryCustomerFromOrdLevPlats) {
      this.customerService
        .getCustomer(order.deliveryCustomerId + '@' + order.extra.oh.ordlevplats1)
        .pipe(first())
        .subscribe(customer => {
          order.extra._deliveryCustomerName = customer?.name;
        });
    }
    return order;
  }

  getTotal(rows: OrderRow[]): number {
    const valueArray = rows.map(row => row.extra.orp.vbordradsum as number);
    const sum = valueArray.reduce((acc, next) => acc + next, 0);
    return sum;
  }

  addRow() {
    const modalRef = this.modalService.open(HlDisplayOrderAddRowModalComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.order = this.order;
  }

  saveOrderMessage(element) {
    gungAddRemoveSpinner(element.target);
    let oh = { oh: { editext: this.order.extra.oh.editext } };
    if (this.isSales) {
      oh = { oh: { edit: this.order.extra.oh.edit } } as any;
    }

    this.orderService.updateOrderHeader({ id: this.order.id, extra: oh } as any).subscribe(
      result => {
        this.gungModalService
          .openConfirmYesNoModal(
            undefined,
            this.translateService.instant('CHANGE_SAVED_SUCCESS'),
            { size: 'sm' },
            'OK',
            null
          )
          .then(_ => { });
      },
      error => {
        gungAddRemoveSpinner(element.target);
        this.gungModalService
          .openConfirmYesNoModal(
            undefined,
            this.translateService.instant('AN_ERROR_HAS_OCCURED'),
            { size: 'sm' },
            'OK',
            null
          )
          .then(_ => { });
      },
      () => {
        gungAddRemoveSpinner(element.target);
      }
    );
  }
}

<footer class="footer" style="min-height: 40vh">
  <div class="row">
    <div class="col-12 col-lg-10 offset-lg-1">
      <div class="row">
        <div class="col-12 col-sm-6 col-lg-3">
          <!--<a *ngIf="isFRCustomerPortal1600General" class="footer-header" href="https://www.hl-display.com/fr/"
            >hl-display.com</a
          >
          <a *ngIf="!isFRCustomerPortal1600General" class="footer-header" href="https://www.hl-display.com/"
            >hl-display.com</a
          >
          <a *ngIf="!sales" class="footer-link" routerLink="/history" translate>OUR_HISTORY</a>
          <a *ngIf="!sales" class="footer-link" routerLink="/responsibilities" translate>CORPORATE_RESPONSIBILITY</a>-->
        </div>
        <div class="col-12 col-sm-6 col-lg-3">
          <!--<a *ngIf="!sales" class="footer-header" routerLink="/contact" translate>CONTACT_US</a>
          <a *ngIf="currentLang != 'en' && !sales && !isCustNlSuperunie1600" class="footer-link" routerLink="/faq" translate>FAQ</a>
          <a *ngIf="!sales" class="footer-link" routerLink="/terms-and-conditions" translate>TERMS_AND_CONDITIONS</a>-->
        </div>
        <div class="col-12 col-sm-6 col-lg-3">
          <!--<a *ngIf="!sales" class="footer-header" routerLink="/orders" translate>ORDER_INFORMATION</a>
          <a
            *ngIf="selectedCustomer && !sales"
            class="footer-link"
            routerLink="/customers/{{ selectedCustomer.id }}"
            translate
            >MY_PAGES</a
          >
          <a *ngIf="!sales" class="footer-link" href="" translate>TRACK_ORDER</a>
          <a *ngIf="!sales" class="footer-link" href="/checkout" translate>CART</a>-->
        </div>
        <div class="col-12 col-sm-6 col-lg-3">
          <div class="logo-wrapper">
            <img class="img-fluid" src="assets/logo-white.png" alt="" />
          </div>
          <a
            *ngIf="!isFRCustomerPortal1600General && !isIntermarcheOrCarrefour"
            href="https://www.linkedin.com/company/hl-display-ab"
            target="_blank"
          >
            <div class="round-link">
              <span class="fab fa-linkedin-in"></span>
            </div>
          </a>
          <a
            *ngIf="isFRCustomerPortal1600General && !isIntermarcheOrCarrefour"
            href="https://www.linkedin.com/company/hl-display-france/"
            target="_blank"
          >
            <div class="round-link">
              <span class="fab fa-linkedin-in"></span>
            </div>
          </a>
          <a
            *ngIf="isIntermarcheOrCarrefour"
            href="https://www.linkedin.com/company/hldisplaybenelux/"
            target="_blank"
          >
            <div class="round-link">
              <span class="fab fa-linkedin-in"></span>
            </div>
          </a>
          <a href="https://vimeo.com/user33178545" target="_blank">
            <div class="round-link">
              <span class="fab fa-vimeo-v"></span>
            </div>
          </a>
          <a href="https://www.youtube.com/channel/UC_agBKH9fzMENa1iqGjLazQ/featured" target="_blank">
            <div class="round-link">
              <span class="fab fa-youtube"></span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="row pt-4" *ngIf="(isFRCustomerPortalPharma || isFRCustomerPortal1600General) && isCardPaymentCustomer">
    <div class="col-12 col-lg-10 offset-lg-1">
      <div class="row">
        <div class="col-12 col-sm-6 col-lg-4">
          <img class="img-fluid card-payment-logos" src="./assets/card-payment-logos-footer_v1.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</footer>

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Customer, MetadataService, SelectedCustomerService } from 'gung-standard';
import { first } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-faq-page',
  templateUrl: './faq-page.component.html',
  styleUrls: ['./faq-page.component.scss']
})
export class FaqPageComponent implements OnInit {
  isFRCustomerPortalPharma =
    !environment.sales && environment.mainCountry === 'fr' && environment.mainCustomer === 'Pharmacies';
  public isFRCustomerPortal1600General =
    !environment.sales &&
    environment.mainCountry === 'fr' &&
    environment.bolag === '1600' &&
    environment.mainCustomer === 'General';
  
  isNOCustomerPortal = !environment.sales && environment.mainCountry === 'no';
  isNOCustomerPortalCoop = false;
  isNOCustomerPortalNorgesGroupAndSpar = false;
  isNOCustomerPortalReitanGroup = false;

  public idIntermarcheOrCarrefourFR = false;
  public idIntermarcheOrCarrefourNL = false;
  public minimumFreight: number;
  public freightCost: number;
  public minimumOrder: number;
  public adminCost: number;

  currentLang: string;

  constructor(
    protected selectedCustomer: SelectedCustomerService, 
    protected metadataService: MetadataService, 
    protected translate: TranslateService
  ) { }

  customerId: string;

  ngOnInit() {
    if (this.isNOCustomerPortal) {
      this.setNoCustomerPortal();
    }

    this.selectedCustomer
      .getSelectedCustomer()
      .pipe(first(customer => customer != null))
      .subscribe(customer => {
        this.customerId = customer.id;
        this.getValuesFromQHlTpSalesmarket(customer);
      });

    this.currentLang = this.translate.currentLang;
    this.idIntermarcheOrCarrefourNL = (environment.subCustomer === 'intermarche' || environment.subCustomer === 'carrefour') && this.currentLang === 'nl';
    this.idIntermarcheOrCarrefourFR = (environment.subCustomer === 'intermarche' || environment.subCustomer === 'carrefour') && this.currentLang === 'fr';
  }

  private setNoCustomerPortal(): void {
    const locationOrigin = location.origin || '';
    if (
      locationOrigin.indexOf('meny') > -1 ||
      locationOrigin.indexOf('joker') > -1 ||
      locationOrigin.indexOf('kiwi') > -1 ||
      locationOrigin.indexOf('spar') > -1
    ) {
      this.isNOCustomerPortalNorgesGroupAndSpar = true;
    } else if (locationOrigin.indexOf('rema') > -1) {
      this.isNOCustomerPortalReitanGroup = true;
    } else if (locationOrigin.indexOf('coop') > -1) {
      this.isNOCustomerPortalCoop = true;
    }
  }

  getValuesFromQHlTpSalesmarket(customer: Customer) {
    const qHlTpSalesmarket = this.metadataService.getMetadataTableList('q_hl_tp_salesmarket');
    if (qHlTpSalesmarket) {
      const salesmarket = qHlTpSalesmarket.find(s => s.q_salesmarket_code === (customer.extra.fr.landskod || 'FR'));
      this.minimumFreight = Number(salesmarket?.q_hl_fc_amount || 0);
      this.minimumOrder = Number(salesmarket?.q_hl_minordervalue || 0);
      this.adminCost = Number(salesmarket?.q_hl_sof_charged_amount || 0);
      this.freightCost = Number(salesmarket?.q_hl_fc_threshold_amount || 0);
    }
  }
}

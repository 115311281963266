import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CartRow, CalendarDate, DeliveryDateService } from 'gung-standard';
import { DateUtilService } from 'gung-common';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs';
import { HlDeliveryDateService } from '../../services/hl-delivery-date.service';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css']
})
export class DatePickerComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  protected deliveryMethod: string;

  @Input()
  protected initialDate?: Date;

  @Input()
  protected row?: CartRow;

  @Output()
  protected selectedDate = new EventEmitter<CalendarDate>();

  public ngModel?: NgbDateStruct;
  public loaded: boolean;
  public firstDatePickerDate: NgbDate;
  public firstDateAvailable: CalendarDate;
  public minDate: NgbDate;

  protected dates: CalendarDate[];

  markDisabled: (date: NgbDate, _current: { year: number; month: number }) => boolean;

  unsubscribe: Subject<boolean> = new Subject();

  constructor(protected deliveryDateService: HlDeliveryDateService, protected dateUtilService: DateUtilService) {
    this.markDisabled = this._markDisabled.bind(this);
    this.loaded = false;
  }

  ngOnInit() {
    if (this.row) {
      const rows = [];
      rows.push(this.row);
      this.datesFromRows(rows);
    } else {
      this.datesFromCart();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.row) {
      this.ngOnInit();
    }
  }

  protected datesFromRows(rows: CartRow[]) {
    this.deliveryDateService
      .getDeliveryDatesFromRows(this.deliveryMethod, rows)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dates => this.dateHandler(dates));
  }

  protected datesFromCart() {
    this.deliveryDateService
      .getDeliveryDates(this.deliveryMethod)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dates => this.dateHandler(dates));
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  protected dateHandler(dates: CalendarDate[]) {
    this.dates = dates;
    this.firstDateAvailable = this.deliveryDateService.findFirstAvailableDate(dates);
    this.minDate = new NgbDate(
      this.firstDateAvailable.date.getFullYear(),
      this.firstDateAvailable.date.getMonth() + 1,
      this.firstDateAvailable.date.getDate()
    );
    if (this.initialDate) {
      this.firstDateAvailable = this.deliveryDateService.findFirstAvailableDate(dates, this.initialDate);
    }
    this.firstDatePickerDate = new NgbDate(
      this.firstDateAvailable.date.getFullYear(),
      this.firstDateAvailable.date.getMonth() + 1,
      this.firstDateAvailable.date.getDate()
    );
    if (!this.ngModel) {
      this.ngModel = this.firstDatePickerDate;
      this.dateSelected(this.firstDatePickerDate);
    } else {
      const model = NgbDate.from(this.ngModel);
      if (!model.equals(this.firstDatePickerDate)) {
        this.ngModel = this.firstDatePickerDate;
        this.dateSelected(this.firstDatePickerDate);
      }
    }
    this.loaded = true;
  }

  _markDisabled(date: NgbDate, _current: { year: number; month: number }) {
    const foundDate = this.dates
      // first filter out all the invalid dates
      .filter(d => d.valid)
      .map(d => d.date)
      // find a matching date with the one to check
      .filter(
        d =>
          d.getFullYear() === date.year &&
          // js date object is zero indexed
          d.getMonth() + 1 === date.month &&
          d.getDate() === date.day
      );

    // return true if the date wasn't found, false if it was.
    return foundDate.length === 0;
  }

  dateSelected(event: NgbDate) {
    const date = this.dateUtilService.createDateFromNgbDate(event);
    const calendarDate = this.dates.find(
      dateElement => this.dateUtilService.compareDates(dateElement.date, date) === 0
    );
    this.selectedDate.emit(calendarDate);
  }
}

import { Component, Input, OnChanges, EventEmitter, Output, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RenderFilter, FilterSelectionAction } from 'gung-list';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

const removeRenderFilterWithZeroItemIncludedInSelectedFilterCategoryItemsIncluded = (
  selectedCategoryValue: any,
  regularFilters: RenderFilter[]
): RenderFilter[] => {
  // included items in selected category
  const itemsIncludedSelectedCategory = selectedCategoryValue.itemsIncluded;

  // create a copy of regular filters
  const regularFiltersCopy = JSON.parse(JSON.stringify(regularFilters)) as RenderFilter[];

  // go through all regular filters and get the items inclued
  regularFiltersCopy.forEach(regularFilter => {
    // get all items included in this filter
    const itemsIncludedRenderFilter = Array.from(
      new Set<string>(
        regularFilter.valueList
          .filter(v => !!v.valueId)
          .map(v => v.itemsIncluded)
          .reduce((a, b) => a.concat(b), [])
      )
    );

    // if none of items included in the selected category
    // exists in the items included in the filter
    // then remove the filter from the regular filters array
    if (itemsIncludedSelectedCategory.filter(x => itemsIncludedRenderFilter.includes(x)).length === 0) {
      const indexToRemove = regularFilters.findIndex(r => r.name === regularFilter.name);
      if (indexToRemove > -1) {
        regularFilters.splice(indexToRemove, 1);
      }
    }
  });

  return regularFilters;
};

const getRegularFilters = (allFilters: RenderFilter[], categoryFilter: RenderFilter): RenderFilter[] => {
  const categorySelected = categoryFilter.valueList.filter(value => value.selected)[0];

  const regularFilters = allFilters.filter(
    f =>
      f.name !== 'MOUSQUETAIRES_SELECTION' &&
      f.name !== 'CATEGORY' &&
      f.name !== 'CUSTOMER_PURCHASED_PRODUCT' &&
      f.name !== 'DISCOUNTED_ITEMS'
  );
  if (!categorySelected) {
    return regularFilters;
  }

  return removeRenderFilterWithZeroItemIncludedInSelectedFilterCategoryItemsIncluded(categorySelected, regularFilters);
};

@Component({
  selector: 'app-hl-product-list-filters',
  templateUrl: './hl-product-list-filters.component.html',
  styleUrls: ['./hl-product-list-filters.component.scss']
})
export class HlProductListFiltersComponent implements OnInit, OnChanges {
  public showMore = false;
  public showMoreLimit = 5;
  @Input()
  renderFilters: RenderFilter[];

  @Output()
  selection: EventEmitter<FilterSelectionAction[]> = new EventEmitter();

  public loaded = false;

  public categoryFilter: RenderFilter;

  public specialSelectionFilters: RenderFilter[];

  public regularFilters: RenderFilter[];

  private isSales = environment.sales;
  private isManagers = environment.managers;

  private isFRCustomerPortalPharma =
    !environment.sales && environment.mainCountry === 'fr' && environment.mainCustomer === 'Pharmacies';
  public isFRCustomerPortal1600General =
    !environment.sales &&
    environment.mainCountry === 'fr' &&
    environment.bolag === '1600' &&
    environment.mainCustomer === 'General';
  private isNOCustomerPortal = !environment.sales && environment.mainCountry === 'no';
  private isSECustomerPortal = !environment.sales && environment.mainCountry === 'se';
  private isFICustomerPortal = !environment.sales && environment.mainCountry === 'fi';
  private isCustNlSuperunie1600 =
    !environment.sales &&
    environment.bolag === '1600' &&
    environment.mainCountry === 'nl' &&
    environment.mainCustomer === 'spar';

  constructor(private route: ActivatedRoute, private router: Router, public translateService: TranslateService) { }

  ngOnInit(): void {
    // as for customer portal the category filter can have duplicated names, so they are merge
    // so the queryParameter CATEGORY contains several filter ; separated
    // but for avoind issues from the categories page allways is passed one filterId
    // so in order to get a correct behaviour we have try to find the corrected filterValueId
    if (!this.isSales && !!this.route.snapshot.queryParams) {
      const from: string = this.route.snapshot.queryParams.from || '';
      const filters: string = this.route.snapshot.queryParams.filters || '';

      if (!!from && !!filters && from === 'categories' && filters.indexOf('CATEGORY') > -1) {
        // get selected category filter
        const categoryFilter = this.checkSelectedCategoryFilter(
          this.renderFilters.filter(f => f.name === 'CATEGORY')[0]
        );
        if (!!categoryFilter) {
          const categoryFilterSelected = categoryFilter.valueList.filter(vl => vl.selected)[0];
          if (!!categoryFilterSelected) {
            if (filters.indexOf(categoryFilterSelected.valueId) < 0) {
              this.router.navigate(['/products'], {
                queryParams: { filters: 'CATEGORY__:__' + categoryFilterSelected.valueId },
                queryParamsHandling: 'merge'
              });
            }
          }
        }
      }
    }
  }

  ngOnChanges() {
    // DO NOT REMOVE OR SPECIAL SELECTION AND CATEGORY FILTERS STOP WORKING!!!!
    this.specialSelectionFilters = this.renderFilters.filter(
      f => f.name === 'MOUSQUETAIRES_SELECTION' || f.name === 'CUSTOMER_PURCHASED_PRODUCT' || f.name === 'DISCOUNTED_ITEMS'
    );
    this.categoryFilter = this.renderFilters.filter(f => f.name === 'CATEGORY')[0];
    this.regularFilters = this.getRegularFilters(this.renderFilters, this.categoryFilter);

    // DO NOT REMOVE THIS LINE OF CODE OR NOTHING WORKS!!!!
    this.loaded = true;
  }

  protected getRegularFilters(allFilters: RenderFilter[], categoryFilter: RenderFilter): RenderFilter[] {
    const categorySelected = categoryFilter.valueList.filter(value => value.selected)[0];

    const regularFilters = allFilters.filter(
      f => f.name !== 'MOUSQUETAIRES_SELECTION' && f.name !== 'CATEGORY' && f.name !== 'CUSTOMER_PURCHASED_PRODUCT' && f.name !== 'DISCOUNTED_ITEMS'
    );
    if (!categorySelected) {
      return regularFilters;
    }

    return this.removeRenderFilterWithZeroItemIncludedInSelectedFilterCategoryItemsIncluded(categorySelected, regularFilters);
  };

  protected removeRenderFilterWithZeroItemIncludedInSelectedFilterCategoryItemsIncluded(
    selectedCategoryValue: any,
    regularFilters: RenderFilter[]
  ): RenderFilter[] {
    // included items in selected category
    const itemsIncludedSelectedCategory = selectedCategoryValue.itemsIncluded;

    // create a copy of regular filters
    const regularFiltersCopy = JSON.parse(JSON.stringify(regularFilters)) as RenderFilter[];

    // go through all regular filters and get the items inclued
    regularFiltersCopy.forEach(regularFilter => {
      // get all items included in this filter
      const itemsIncludedRenderFilter = Array.from(
        new Set<string>(
          regularFilter.valueList
            .filter(v => !!v.valueId)
            .map(v => v.itemsIncluded)
            .reduce((a, b) => a.concat(b), [])
        )
      );

      // if none of items included in the selected category
      // exists in the items included in the filter
      // then remove the filter from the regular filters array
      if (itemsIncludedSelectedCategory.filter(x => itemsIncludedRenderFilter.includes(x)).length === 0) {
        const indexToRemove = regularFilters.findIndex(r => r.name === regularFilter.name);
        if (indexToRemove > -1) {
          regularFilters.splice(indexToRemove, 1);
        }
      }
    });

    return regularFilters;
  };

  public specialSelectionClicked(selectionFilter: RenderFilter) {
    const specialSelectionFilter = this.specialSelectionFilters.find(f => f.name === selectionFilter.name);
    if (specialSelectionFilter) {
      this.selection.emit([
        {
          filterName: specialSelectionFilter.name,
          optionId: specialSelectionFilter.valueList[0].valueId,
          selected: !specialSelectionFilter.valueList[0].selected
        }
      ]);
    }

    return false;
  }

  public setSelection(filterName: string, optionId: string | null, selected: boolean, optionIdRange?: string[]) {
    this.selection.emit([
      {
        filterName,
        optionId,
        selected,
        optionIdRange
      }
    ]);
  }

  public categoryClicked(valueId: string) {
    const actions: FilterSelectionAction[] = [];
    // deselect all currently selected categories
    const toDeselect = this.categoryFilter.valueList
      .filter(value => value.selected)
      .filter(value => value.valueId !== valueId)
      .map(value => ({ filterName: 'CATEGORY', optionId: value.valueId, selected: false }));
    // deselect all other regular filters
    const regularFiltersDeselectActions = this.regularFilters
      .filter(f => f.name !== 'ASSORTMENT_TYPE')
      .map(f =>
        f.valueList
          .filter(value => value.selected)
          .map(value => ({
            filterName: f.name,
            optionId: value.valueId,
            selected: false
          }))
      )
      .reduce((acc, curr) => [...curr, ...acc], []);

    toDeselect.push(...regularFiltersDeselectActions);
    // select or deselect the current value
    const option = this.categoryFilter.valueList.filter(value => value.valueId === valueId)[0];
    if (!option) {
      throw new Error('Invalid option selected, please contact your administrator');
    }

    // add all the actions and emit
    actions.push(...toDeselect);
    actions.push({
      filterName: 'CATEGORY',
      optionId: option.valueId,
      selected: !option.selected
    });
    this.selection.emit(actions);
  }

  private checkSelectedCategoryFilter(renderFilter: RenderFilter): RenderFilter {
    // in case there is not selected filter THEN check from query parameters if there is any
    // this is a fix when coming from categories pages with several ids e.g. FreshProduceSolutions;DisplayAccessories
    // in this case the default behaviour (in GUNG-LIST) which set the selected filter does not occur
    if (!!renderFilter && renderFilter.valueList.filter(v => v.selected).length === 0) {
      if (!!this.route.snapshot.queryParams && !!this.route.snapshot.queryParams.filters) {
        // e.g.: CATEGORY:FreshProduceSolutions;DisplayAccessories or DisplayAccessories;FreshProduceSolutions
        const queryParamsFilters: string = this.route.snapshot.queryParams.filters;

        // try to find the item to selected by the first category in the query parameters
        const itemToSelect = renderFilter.valueList.filter(
          v => v.valueId.indexOf(queryParamsFilters.replace('CATEGORY__:__', '').split(';')[0]) > -1
        )[0];
        if (!!itemToSelect) {
          itemToSelect.selected = true;
        }
      }
    }

    return renderFilter;
  }
}

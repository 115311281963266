import { Injectable } from '@angular/core';
import {
  CartConfigService,
  CartRow,
  CartItemComponent,
  BaseViewConfigService,
  ProductAssortmentParentService
} from 'gung-standard';
import { ListLayout, ListLayoutComponent } from 'gung-list';
import { HlDisplayCartService } from './hl-display-cart.service';
import { Observable, of, forkJoin } from 'rxjs';
import { mergeMap, first, map, tap } from 'rxjs';
import { HlDisplayCartItemComponent } from '../components/hl-display-cart-item/hl-display-cart-item.component';
import { HlDisplayCartListLayoutComponent } from '../components/hl-display-cart-list-layout/hl-display-cart-list-layout.component';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayCartConfigService extends CartConfigService {
  constructor(
    protected cartService: HlDisplayCartService,
    protected baseViewConfigService: BaseViewConfigService,
    protected productAssortmentParentService: ProductAssortmentParentService
  ) {
    super(cartService, baseViewConfigService, productAssortmentParentService);
  }

  getLayouts(): ListLayout<CartRow>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => HlDisplayCartItemComponent,
        getListLayoutComponent: () => HlDisplayCartListLayoutComponent,
        getName: () => 'Product grid'
      }
    ];
  }

  getItems(): Observable<CartRow[]> {
    return this.cartService.getCurrentCart().pipe(
      mergeMap(cartRows => forkJoin([of(cartRows), this.cartService.getCartValidationData().pipe(first())])),
      map(([cartRows, validationDataList]) => {
        return cartRows.map(cartRow => {
          const validationData = validationDataList.find(data => data.productId === cartRow.productId);
          //cartRow.extra = cartRow.extra || {};
          return {
            ...cartRow,
            extra: {
              ...cartRow.extra,
              validationData: validationData
            }
          };
        });
      })
    );
  }

  getSearchGroupCss(): string {
    return 'd-none';
  }

  getNothingFoundTranslateTag(): string {
    return 'CART_IS_EMPTY';
  }
}

import { Injectable } from '@angular/core';
import { SalesCartConfigService, CartRow, BaseViewConfigService, ProductAssortmentParentService } from 'gung-standard';
import { ListLayout, ListLayoutMultipleComponent, ListLayoutSingleComponent } from 'gung-list';
import { HlDisplaySalesCartListComponent } from '../components/hl-display-sales-cart-list/hl-display-sales-cart-list.component';
import { CartService } from 'gung-standard';

@Injectable({
  providedIn: 'root'
})
export class HlDisplaySalesCartConfigService extends SalesCartConfigService {
  constructor(
    cartService: CartService,
    protected baseViewConfigService: BaseViewConfigService,
    protected productAssortmentParentService: ProductAssortmentParentService
    ) {
    super(cartService, baseViewConfigService, productAssortmentParentService);
  }

  getLayouts(): ListLayout<CartRow>[] {
    return [
      {
        getIconClass: () => '',
        getName: () => 'Cart table',
        getListItemComponent: () => HlDisplaySalesCartListComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent
      }
    ];
  }
}

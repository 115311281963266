<div *ngIf="translate.currentLang === 'fr' || translate.currentLang === 'no' || translate.currentLang === 'nl'  || translate.currentLang === 'de'; else showInEn">
  
  <div class="row mt-4" *ngIf="translate.currentLang  === 'fr' && !isIntermarcheOrCarrefourBE && !isCoopPortal">
    <div class="col-5 offset-1">
      <h2 class="text-center">Conditions générales de vente</h2>
      <p class="text-center">(Version à compter du 01/03/2021)</p>
      <p>
        Les présentes Conditions générales de vente (CGV) définissent les modalités de vente de ses produits par la
        société HL DISPLAY. Elles s appliquent ainsi dans leur intégralité à toute commande de produits par le client
        auprès de la société HL DISPLAY. Les présentes CGV sont systématiquement adressées ou remises au client, ou sont
        systématiquement à la disposition de ce dernier. En conséquence, toute commande de produits auprès de la société
        HLDISPLAYentraîne leur acceptation entière et sans réserve. Les présentes CGV s appliquent à l exclusion de toutes
        autres conditions. Toutes autres conditions émanant du client, sous réserve qu ellesne soient pas en contradiction
        avec lesprésentes, ne seront valablesque si elles ont été acceptées par la société HL DISPLAY, de manière
        préalable et expresse. Elles sont indissociables des tarifs en vigueur des produits, et annulent et remplacent
        toutes autres conditions générales émises antérieurement par la société HL DISPLAYou pouvant figurer sur des
        documents ou convenues par tout moyen. Le fait pour la société HL DISPLAY de ne pas exercer, à un moment
        quelconque, une prérogative reconnue par les présentes CGV, ou de ne pas exiger l&#39;exécution d&#39;une
        stipulation quelconque de la convention issue desdites conditions ne pourra en aucun cas être interprété, ni comme
        une modification du contrat, ni comme une renonciation expresse ou tacite au droit d&#39;exercer ladite
        prérogative dans l&#39;avenir, ou au droit d&#39;exiger l&#39;exécution scrupuleuse des engagements souscrits aux
        présentes.
      </p>
      <p>
        <b>1/ PRODUITS</b><br />
        Les photos, textes, caractéristiques techniques relatifs aux produits détaillés dans les catalogues édités et/ou
        communiqués par la société HL DISPLAYsont communiqués à titre indicatif et n ont pas de valeur contractuelle
        visàvisdu client. Dansun souci d amélioration de son offre, la société HL DISPLAY se réserve le droit de modifier
        à tout moment toute caractéristique concernant ses produits ou de les supprimer.
      </p>
      <p>
        <b>2/ PRIX</b><br />
        Les produits fournis par la société HL DISPLAY sont facturés selon leur tarif en vigueur au jour de leur commande
        par le client. Tous les prix s entendent horstaxeset sont majorésde laTVAaux taux en vigueur à la date de
        facturation. Les prix de ses Produits peuvent être modifiés à tout moment par la société HL DISPLAY, notamment en
        cas de réactualisation du catalogue de ses produits. En cas de modification, le prix applicable sera celui en
        vigueur au jour de la commande du client. En tout état de cause, toute commande de produits auprès de la société
        HL DISPLAYest soumise à son acceptation expresse conformément aux dispositionsde l article 5 ci-après.
      </p>
      <p>
        <b>3/ CONDITIONS DE PAIEMENT</b><br />
        Sauf disposition contraire, les factures de la société HLDISPLAYsont payables par VIREMENT au plus tard dans un
        délai de 15 jours suivant la fin du mois au cours duquel les factures ont été émises. LesLCRadresséesà l
        acceptation doivent être retournées à la société HL DISPLAY dans un délai maximum de quinze jours à compter de
        leur émission. Toutefois, toute facture d un montant inférieur à 150 EUROS HT est payable par chèque à réception
        de la facture concernée par le client. Toute somme non réglée àl échéance porteraintérêt au taux de 3 foisle taux
        d intérêt légal +TVA, àdater de cette dernière sansqu aucune mise en demeure ne soit nécessaire. Par ailleurs,
        sauf accord contraire, tout défaut de paiement à l échéance fixée entraînera le droit pour la société HL DISPLAY
        de résilier la totalité des commandes en cours, de prononcer l exigibilité immédiate de toutes les sommes dues non
        encore échues, et l exigibilitéàtitrededommageset intérêtsd une indemnité égale à 10%des sommes dues. Une
        indemnité de recouvrement de 40 sera également due en cas de retard de paiement sur chaque facture en application
        des articles L441-3 et L441-6 du code de commerce, outre les intérêts conventionnels. Sauf disposition contraire,
        aucun escompte ne sera accordé pour les paiements anticipés.
      </p>
      <p>
        <b>4/ MINIMUM DE COMMANDE ET DE FACTURATION</b><br />
        Toutes les commandes sont soumises à un montant minimal de 150 EUROS HT de produits. D autre part, lescommandesd
        un montant entre 150 et 500 EUROS HT sont soumises de plein droit à une facturation forfaitaire supplémentaire par
        la société HL DISPLAY d une somme de 42 EUROS HT (comprenant 25 HT- de frais de transport et 17 HTde traitement
        administratif Les conditionnements livrables sont ceux figurant au catalogue. Un article vendu au conditionnement
        ne peut être vendu à l unité. Une commande pour une quantité ne correspondant pas au conditionnement catalogue
        sera de plein droit prise en compte pour la quantité immédiatement supérieure sans préjudice pour la société HL
        DISPLAY.
      </p>
      <p>
        <b>5/ EXECUTION DES COMMANDES :</b><br />
        Toute commande de produits peut être passée par le client à la société HL DISPLAY par courrier ou télécopie.
        Toutes le commandes seront systématiquement validées et confirmées par la société HL DISPLAY au client par l envoi
        d une confirmation de commande reprenant et précisant notamment le prix, les quantités et délais indicatifs de
        livraison des produits concernés. Cette confirmation de commande, si non dénoncée ou contestée par le client sous
        24 heures, vaut engagement ferme, définitif et irrévocable de commande selon les termes énoncés dans cette
        dernière. En conséquence passé ce délai, la commande concernée ne peut faire l objet d une rétractation ou d une
        modification de la part du client, sauf accord préalable de la société HL DISPLAY. Dans ce cadre, les acomptes
        éventuellement versés resteront définitivement acquis à la société HL DISPLAY, et le solde du prix restant dû,
        sans préjudice de tout droit à indemnisation pour tout dommage résultant de la modification ou de la rétractation
        de la commande. En cas de litige, la confirmation de commande émise par la société HL Display fera seule foi des
        engagements commerciaux réciproques. La société HLDISPLAYs engage à tout mettre en uvre pour exécuter
        lescommandesqui lui sont passées dans la limite des stocks disponibles et se réserve le droit de refuser la
        commande en cas de non-conformité (notamment quant au tarif applicable). En cas de défaut de disponibilité du ou
        des Produits concernés, pour quelque motif que ce soit, la société HLDISPLAYsengage à informer au plus vite le
        client et lui proposer, ce que le client accepte d ores et déjà et sans réserve, un nouveau délai de réception du
        ou des produits concernés.
      </p>
      <p>
        <b>6/ FACTURES ELECTRONIQUES</b><br />
        Sauf accord contraire et préalable entre lesParties, le Client accepte sans réserve de recevoir les factures
        émisespar HLDISPLAYexclusivement par voieélectronique, conformément àl article 289 du Code Général des Impôts.
        Toutefois, le Client peut demander à HL DISPLAYde recevoir une facture au format papier moyennant le paiement de
        fraisde traitement de facture papier d un montant horstaxe de cinq (5) euros par facture.
      </p>
      <p>
        <b>7/ FACTURES ELECTRONIQUES</b><br />
        Les délais de fourniture des produits ne courent qu à partir du moment où la société HL DISPLAY est en possession
        de tous les éléments permettant la mise en fabrication, ou la livraison pour les produits en stock. En tout état
        de cause, les délais de livraison le cas échéant mentionnés par la société HLDISPLAYne sont donnés qu à titre
        indicatif et ne pourront donner lieu à dommages et intérêts. Les retards éventuels ne donnent pas le droit à l
        acheteur d annuler la vente, de refuser la marchandise ou de réclamer des dommages et intérêts. Les marchandises
        sont expédiées franco de port « 1 point de livraison » (en France métropolitaine uniquement hors Corse) à partir d
        un montant de commande de 500 EUROS HT, à l exclusion des livraisons « express » (livraisons en 24 heures) et des
        livraisons partielles demandées spécifiquement et à la charge du client indépendamment du montant de la livraison.
        Les produits sont livrés au lieu convenu lors de la commande. Le client s engage à assurer la réception des
        produits. En cas d absence du client impliquant pour le transporteur d assurer une nouvelle démarche pour livrer
        les produits, des frais seront de plein droit facturé au client. Lors des livraisons, le client devra vérifier le
        nombre de colis et en examiner le contenu et devra impérativement remettre au transporteur le bulletin de
        livraison dûment émargé : date, heure, signature, cachet. Toute variation de quantité livrée tant positive que
        négative par rapport à la quantité initiale commandée par le client fera l objet d un ajustement de facturation et
        sera valorisé au prix unitaire défini lors de la commande. Cette tolérance est fixée à +/- 5%. Par ailleurs, toute
        réserve ou contestation liée aux manquants et/ou avaries des produits liés à leur transport devra être portée par
        le Client sur le bon de livraison et être confirmée par le client au transporteur dans les conditions de l article
        L133-3 du Code de commerce, par lettre recommandée avec accusé de réception, aveccopieassortied unecopiedu bon
        delivraison concerné, par lettrerecommandée avec accusé de réception au siège social de la société HL DISPLAY,
        adressée dans les 3 jours francs à compter
      </p>
    </div>
    <div class="col-5">
      <p>
        de la réception des Produits à peine de forclusion à l égard de la société HL DISPLAY des réserves ou
        réclamations. En outre, tout défaut ou contestation liée à la conformité des produits, notamment défaut apparent,
        devra être notifiée par le Client par tous moyens à la société HL DISPLAYau plus tard dans un délai de 7 jours
        suivant la livraison des Produits. Il appartiendra au client de fournir toute justification quant à la réalité des
        défauts, manquants, vices ou anomalies constatés. Le client devra laisser à la société HL DISPLAYtoute facilité
        pour procéder à la constatation de ces vices et pour y porter remède. A défaut du respect de ces conditions, la
        réception des produits sera réputée sans réserve et les produits conformes, de sorte que la responsabilité de la
        société HL DISPLAY ne pourra plus être mise en cause pour défaut de conformité, le client étant tenu pour
        responsable de tout préjudice subi par la société HL DISPLAY du fait du non-respect de cette procédure. En cas de
        non-conformité ou autresmalfaçonsavérées, et quellequ en soit lanature, la société HL DISPLAY ne sera tenue qu au
        simple remplacement des marchandises défectueuses par des produits similaires, à l exclusion de tousdommageset
        intérêts, clause pénale ou autresindemnités. Lesproduitssimilaires, c&#39;est à dire substituables à ceux
        commandés, sont ceux de même qualité et satisfaisant les mêmes fonctions d&#39;usage.
      </p>
      <p>
        <b>8 / RETOUR DE PRODUITS</b><br />
        Tout retour de marchandise devra obligatoirement faire l objet d un accord préalable de la part de nos services
        commerciaux dans les 15 jours suivants la réception de marchandise ou dans les 15 jours suivants la réception de
        chantier (dansle casspécifique d installation faite par nossoins). Suite à cet accord, leshypothèsessuivantess
        appliqueront : - le retour est du fait de la société HL DISPLAY, notamment en cas de non-conformité avérée des
        produits, l enlèvement des produits concernés sera alors effectué à la charge et par cette dernière, - le retour
        est du fait du client, dans ce cas, les produits seront alors retournéspar ses soins et en port payé sous un délai
        de 15 jours après accord en ce sens de la société HL DISPLAY Dans cette dernière hypothèse, tout retour de
        matériel devra être effectué dans son emballage d origine et en parfait état. Dans le cas contraire, la société HL
        DISPLAY se réserve le droit de retenir, forfaitairement sur le montant de l avoir à établir au client, 5 % de la
        valeur du produit retourné au titre des frais de conditionnement. Dans le cas où la produit subirait, de plus, des
        dommages du fait du mauvais conditionnement, la société HL DISPLAY se réserve également le droit de retenir, en
        supplément, le montant facturé des marchandises défectueuses.
      </p>
      <p>
        <b>9/ RESPONSABILITE DE LA SOCIETE HL DISPLAY</b><br />
        Il est de la responsabilité du client de choisir des produits adaptés à ses besoins, de sorte que la société HL
        DISPLAY décline toute responsabilité dans le choix des caractéristiques techniques des produits effectués par le
        client au titre de sa commande ou dans leur compatibilité avec son environnement technique qui relève du seul fait
        de ce dernier. De même, la société HL DISPLAY ne pourra être tenue responsable des conséquences d’une utilisation
        anormale, d’une usure anormale ou d’une mauvaise installation de ses produits par le client. En tout état de
        cause, la responsabilité de la société HL DISPLAY ne pourra être recherchée qu’en cas de faute prouvée par le
        Client et qui lui serait imputable. En l’absence de cette preuve, la société HL DISPLAY décline toute
        responsabilité concernant les conséquences directes et indirectes des produits dont elle assure la fourniture.
        Dans l’hypothèse où la responsabilité de la société HL DISPLAY serait mise en cause, et sauf disposition contraire
        ou disposition légale impérative, celle-ci sera dans tous les cas expressément limitée au remboursement ou au
        remplacement des produits concernés, à l’exclusion de tous dommages et intérêts ou de tout préjudice indirect subi
        par le client.
      </p>
      <p>
        <b>10/ PROPRIETE INTELLECTUELLE</b><br />
        Toutes études, projets, esquisses, plaquettes, prototypes, travaux, modèles et brevets liés aux produits de la
        société HL DISPLAYà toute création de cette dernière restent la propriété exclusive de la société HL DISPLAY. Le
        client ne peut donc reproduire directement ou par une entreprise tiers les produits, les créations, et plus
        globalement, tout élément de propriété intellectuelle de la société HL DISPLAY sans autorisation écrite et
        préalable de cette dernière fixant les modalités d utilisation ainsi que les conditions financières d
        exploitation. Les contrefaçons seront poursuivies et réprimées conformément aux dispositions de la loi en vigueur.
        Concernant leséventuels visuels, modèlesou maquettes fournispar le client à la société HLDISPLAYdans le cadre d
        une commande, ce dernier doit sassurer de la liberté d exploitation avant leurstransmissions à la société HL
        DISPLAY pour exécution. La société HL DISPLAY est dégagée de toutes poursuites et condamnations en contrefaçon ou
        concurrence déloyale, la société HL DISPLAYle garantissant de toutes pénalités, frais et indemnités auxquels il
        pourrait être condamné.
      </p>
      <p>
        <b>11/ RÉSERVE DE PROPRIÉTÉ</b><br />
        La propriété des produits fournis par la société HLDISPLAYne sera transférée qu après paiement intégral de leur
        prix par le client. En conséquence, la société HL Display se réserve lapropriétédesproduitsjusqu au complet
        paiement de leur prix t et d en reprendre possession à tout moment en cas de non-paiement même partiel. Afin de
        permettre à la société HLDISPLAYde revendiquer les produits et de préserver ses droits, le client est tenu d
        informer la société HL DISPLAY immédiatement de tout changement de sa situation et notamment de sa déclaration de
        redressement ou de liquidation judicaire, ou de toute autre action (saisie, confiscation, etc.) opérée par des
        tiers pouvant mettre en cause son droit de propriété. En tout état de cause, malgré l application de cette clause
        de réserve de propriété, le transfert desrisquesliés aux produits fournis a toujours lieu au moment de leur
        délivrance entre les mains du client, de ses subrogés, ou de tout tiers désigné par ses soins.
      </p>
      <p>
        <b>12/ DONNEES PERSONNELLES</b><br />
        Dansle cadre d une commande de produits, le client peut être amené à fournir à la société HL DISPLAY des
        informations à caractère personnel le concernant ou sur ses salariés. La collecte des données personnelles est en
        premier lieu nécessaire pour permettre à la société HL DISPLAYd assurer la bonne exécution des commandes de
        produits. Ces données sont susceptibles d être transmises à l administration fiscale ou à une juridiction dans le
        cadre du respect de ses obligations légales et administratives par la société HL DISPLAY ou dans le cadre de la
        gestion d un contentieux. En second lieu, certaines des données ainsi collectées peuvent également être utilisées
        par la société HL DISPLAY pour adresser au client et ses salariés, des e-mails et informations afin de faire part
        de ses actualités. La société HLDISPLAYs engage à prendre toutes précautions utiles pour préserver la sécurité des
        données communiquées par la société HL DISPLAY ou ses salariés, ou auxquelles elle pourra avoir accès pour ses
        propresbesoins, et, notamment, empêcher qu elles soient déformées, endommagées ou que des tiers non autorisés y
        aient accès. La société HL DISPLAY informe le client et ses salariés dont des données pourront ainsi être
        collectées qu ils disposent conformément au Règlement général sur la protection des données (Règlement
        UE2016/679), d&#39;un droit d&#39;accès, de modification, de suppression, de limitation, d opposition, et de
        portabilité des données les concernant. Toute personne aégalement le droit de ne pasfaire l objet d une prise de
        décision individuelle exclusivement fondée sur un traitement automatisé tel que le profilage. En outre,
        conformément au droit d opposition applicable selon la réglementation en la matière, le client et ses salariés
        peuvent par ailleurs refuser de continuer à recevoir des e-mails d information de la part de la société
        HLDISPLAYportant sur ses actualités. Ces droits peuvent être exercés en adressant un e-mail :à :
        privacy&#64;hl-display.com, et pour avoir plus d’information sur la gestion des données personnelles en se rendant sur
        le site web d’HL DISPLAY à l’adresse suivante :
        http://www.hl-display.com/fr-FR/France/Contactez-nous/protection-des-donnees/. Les personnes concernées disposent
        en outre du droit d’introduire une réclamation concernant le traitement de leurs données personnelles par la
        société HL DISPLAY auprès de la CNIL. La société HL DISPLAY s’engage à conserver les données personnelles qu’elle
        pourra ainsi collecter pendant une durée n’excédant pas trois ans après la fin de sa relation commerciale avec le
        client.
      </p>
      <p>
        <b>13/ DROIT APPLICABLE - ATTRIBUTION DE COMPÉTENCE</b><br />
        Les présentes CGV sont soumises au droit interne français, exclusion faite de toute convention internationale. En
        cas de contestation relative à la vente d’un produit de la société HL DISPLAY ainsi qu’en cas d’interpellation ou
        d’exécution des clauses des présentes CGV, le Tribunal de Commerce de Tours sera seul compétent.
      </p>
    </div>
  </div>

  <div class="row mt-4" *ngIf="translate.currentLang  === 'fr' && isCoopPortal">
    <div class="col-12"><h2 class="text-center mb-4">Conditions générales de vente et de livraison</h2></div> 
    <!-- <p class="text-center">(Version à compter du 05/07/2022)</p> -->
    <div class="col-md-6">
      <p>HL DISPLAY SCHWEIZ AG</p>
      <p>
        <b>1.	Application </b><br />
        Les conditions générales de vente et de livraison sont valables pour toutes nos livraisons. Tout accord divergent est 
        soumis à notre approbation écrite. Des conditions générales et d’achat contraires à celles de notre acheteur ne nous engagent 
        pas non plus, même si nous n’avons pas expressément fait opposition à celles-ci.
      </p>
      <p>
        <b>2.	Conditions de paiement </b><br />
        Les prix s’entendent sans la taxe à la valeur ajoutée (TVA). Le délai de paiement est de 30 jours net dès la date de facturation. 
        La non-observation du délai de paiement, astreint l’acheteur, en cas de rappel relatif au paiement, aux frais engendrés. 
        Des modifications de prix par rapport à la liste de prix restent réservées. Les déductions injustifiées seront redemandées.
      </p>
      <p>
        <b>3.	Commande minimale </b><br />
        La commande minimale est de CHF 100.--.
      </p>
      <p>
        <b>4.	Unités de vente </b><br />
        La vente a lieu en unités complètes. Un supplément de 10.—par position est facturé pour chaque unité entamée.
      </p>
      <p>
        <b>5.	Factures minimales </b><br />
        Les factures inférieures à un montant de CHF 300.—seront majorées d’un supplément pour factures minimales de CHF 30.--.
      </p>
      <p>
        <b>6.	Frais de transport </b><br />
        HL Display Schweiz AG livre au frais du client, depuis l’entrepôt d’Aarau. Si une commande est distribuée à des adresses de 
        livraison différentes, les frais de transport effectifs seront facturés. Si un emballage spécial est nécessaire, les frais seront facturés en conséquence.
      </p>
      <p>
        <b>7.	Emballage</b><br />
        Les frais d’emballage sont intégrés dans le prix. Les emballages d’une longueur de plus de 1340 mm ou de grandeurs spéciales non-standard, 
        seront comptabilisés séparément.
      </p>
      <p>
        <b>8.	Conditionnement</b><br />
        La vente a lieu par unités complètes. Pour quantités fragmentaires, une majoration sera facturée.
      </p>
      <p>
        <b>9.	Expédition </b><br />
        L’expédition a lieu au risque et péril de l’acheteur. Les envois, qui arrivent détériorés, ne sont à accepter que sous réserve expresse 
        après constatation préalable des dégâts par l’entreprise de transport. Aucun dommage-intérêt ne sera accepté suite à des retards de livraison.
      </p>
    </div>
    <div class="col-md-6">
      <p>
        <b>10.	Livraison </b><br />
        Les délais de livraison indiqués dans les offres et confirmations sont à considérer comme étant approximatifs et sans engagement. Les livraisons de jusqu’ à 10 % en plus ou en moins pour produits
        spécialement fabriqués sont permises. 
      </p>
      <p>
        Des événements imprévisibles, tels que difficultés d’approvisionnement de matériel et d’outillage ou de force majeure, autorisent le fournisseur, 
        en tout cas, à différer en conséquence l’exécution des devoirs de livraison pris ou à renoncer entièrement ou partiellement au contrat. Le même 
        règlement vient en application, si l’acheteur n’arrive plus à faire face à ses devoirs de paiement. Une prétention en dommage et intérêt ou une 
        livraison postérieure est exclue. La non-observation du délai de livraison ne donne en aucun cas droit à l’acheteur d’annuler l’ordre. Des livraisons 
        partielles sont admises. Si l’acheteur ne demande pas la livraison ou s’il s’oppose à sa réception, le fournisseur a droit, sans mesures préalables 
        après écoulement d’un délai de 20 jours dès le rappel, d’exiger le paiement. L’entreposage des marchandises s’effectue à la charge et au risque de 
        l’acheteur retardataire uniquement.
      </p>
      <p>
        <b>11.	Réclamations</b><br />
        Les réclamations sont à communiquer dans les 10 jours après réception de la marchandise. Les renvois de marchand ses sont soumis à notre approbation expresse. 
        Nous ne pouvons pas reprendre de la marchandise correctement délivrée, car celle-ci n'est souvent fabriquée qu'après commande.
      </p>
      <p>
        <b>12.	Dommages de transport </b><br />
        Les envois ayant subi des dommages de transport sont à annoncer immédiatement au camionneur concerné, à la réception de la marchandise, 
        au moyen d'une réserve écrite/procès-verbal du dommage.
      </p>
      <p>
        <b>13.	Droit applicable</b><br />
        Le rapport juridique du contrat entre nous et l’acheteur, ainsi que les conditions générales de vente et de livraison présentes, sont régis par le droit suisse.
      </p>

      <p>
        <b>14.	Lieu de juridiction</b><br />
        Le lieu d’exécution et de juridiction pour toutes les requêtes est Aarau.
      </p>
    </div>
  </div>
  <div class="row mt-4" *ngIf="translate.currentLang  === 'de' && isCoopPortal">
    <div class="col-12"><h2 class="text-center mb-4">Allgemeine Verkaufs- und Lieferbedingungen</h2></div> 
    <div class="col-md-6">
      <p>HL DISPLAY SCHWEIZ AG</p>
      <p>
        <b>1.	Anwendbarkeit </b><br />
        Die allgemeinen Verkaufs- und Lieferbedingungen gelten für alle unsere Lieferungen. Abweichende Vereinbarungen bedürfen unserer 
        schriftlichen Zustimmung. Entgegenstehende Einkaufs- und allgemeine Geschäftsbedingungen unserer Besteller verpflichten uns auch dann nicht,
         wenn wir ihnen nicht ausdrücklich widersprochen haben.
      </p>
      <p>
        <b>2.	Zahlungsbedingungen </b><br />
        Die Preise verstehen sich exklusive Mehrwertsteuer (MWST). Die Zahlungsfrist beträgt 30 Tage ab Rechnungsdatum, rein netto. 
        Die Nichteinhaltung des Zahlungstermins verpflichtet den Besteller bei Mahnung zur Bezahlung von Mahnspesen. 
        Preisänderungen gegenüber der Preisliste bleiben vorbehalten. Unberechtigte Abzüge werden nachgefordert.
      </p>
      <p>
        <b>3.	Mindestbestellwert </b><br />
        Der Mindestbestellwert ist Fr. 100.-.
      </p>
      <p>
        <b>4.	Verkaufseinheiten </b><br />
        Der Verkauf erfolgt in ganzen Einheiten. Für Anbruchmengen wird ein Zuschlag von Fr. 10.- pro Position verrechnet.
      </p>
      <p>
        <b>5.	Kleinfakturen </b><br />
        Für Fakturen unter einem Warenwert von Fr. 300.- wird ein Kleinfakturazuschlag von Fr. 30.- erhoben.      </p>
      <p>
        <b>6.	Transportkosten  </b><br />
        HL Display Schweiz AG liefert Unfrei ab Lager Aarau. Wird ein Auftrag an verschiedene Lieferadressen aufgeteilt, werden die effektiven Transportkosten verrechnet. 
        Sind Sonderverpackungen notwendig, werden diese entsprechend in Rechnung gestellt.      </p>
      <p>
        <b>7.	Verpackung </b><br />
        Die Verpackungskosten sind im Preis inbegriffen. Verpackungen für Längen über 1340 mm oder spezielle, nicht dem Standard entsprechende Grössen, werden separat verrechnet.
      </p>
      <p>
        <b>8.	Versand </b><br />
        Der Versand erfolgt auf Rechnung und Gefahr des Bestellers. Sendungen, die in beschädigtem Zustand eintreffen, sind nur nach vorheriger Schadenfeststellung durch die Transportanstalt und ausdrücklich unter Vorbehalt anzunehmen. 
        Ansprüche wegen Lieferverzögerungen können nicht akzeptiert werden.      
      </p>
    </div>
    <div class="col-md-6">
      <p>
        <b>9.	Lieferung  </b><br />
        Die in den Angeboten und Bestätigungen angegebenen Lieferfristen sind als annähernd und freibleibend zu betrachten. Unter- oder Überlieferungen von bis zu 10 % für speziell 
        angefertigte Produkte, sind zulässig. Unvorhergesehene Ereignisse wie Material- und Werkzeugbeschaffungsschwierigkeiten oder höhere Gewalt, berechtigen den 
        Lieferanten, die Erfüllung der übernommenen Lieferverpflichtungen in jedem Fall entsprechend hinaus zu schieben oder vom Vertrag ganz oder teilweise zurückzutreten.
         Die gleiche Regelung kommt zur Anwendung, wenn der Besteller mit der Erfüllung seiner Zahlungspflichten in Verzug ist. Ein Anspruch auf Schadenersatz oder 
         Nachlieferung ist ausgeschlossen. Die Nichteinhaltung der Lieferfrist berechtigt den Besteller in keinem Fall zur Annullierung des Geschäftes. 
         Teillieferungen sind zulässig. Ruft der Besteller die Lieferung nicht ab oder verweigert er deren Annahme, so ist der Lieferant nach Ablauf 
         einer 20-tägigen Frist ab Mahnung ohne weitere Vorkehrungen berechtigt, die Bezahlung zu verlangen. Die Lagerung der Ware geschieht auf Kosten und 
         ausschliessliches Risiko des säumigen Bestellers.      
        </p>
      <p>
        <b>10.	Beanstandungen </b><br />
        Beanstandungen sind innerhalb von 10 Tagen nach Erhalt der Ware anzubringen. Warenrücksendungen setzen unser ausdrückliches Einverständnis voraus. Korrekt gelieferte Ware können wir nicht zurücknehmen, 
        da diese oft nach Auftrag produziert worden ist.      </p>
      <p>
        <b>11.	Transportschäden </b><br />
        Sendungen mit Transportschäden sind dem entsprechenden Transportunternehmer sofort bei der Übernahme der Ware mit schriftlichem Vorbehalt/Schadenprotokoll anzumelden.      </p>
      <p>
        <b>12.	Anwendbares Recht  </b><br />
        Auf das Vertragsverhältnis zwischen uns und dem Besteller sowie auf die vorliegenden allgemeinen Verkaufs- und Lieferbedingungen, findet schweizerisches Recht Anwendung.      </p>
      <p>
        <b>13.	Gerichtsstand </b><br />
        Erfüllungsort und Gerichtsstand ist für alle Ansprüche ist Aarau.      </p>
      <p>
    </div>
  </div>
  <div class="row mt-4" *ngIf="translate.currentLang  === 'fr' && isIntermarcheOrCarrefourBE">
    <div class="col-5 offset-1">
      <h2 class="text-center">CONDITIONS GÉNÉRALES DE LIVRAISON</h2>
      <p class="text-center">HL Display Belgique S.A., HL Display Pays-Bas B.V. et HL Display France S.A.S.</p>
      <h3>1. Définitions</h3>
      <p>
        1.1. Entreprise : Il est entendu par ce terme : HL Display Belgique S.A., HL Display Pays-Bas B.V. et HL Display France S.A.S et/ou leur établissement secondaire ou filiale ayant émis une offre, accepté une commande et/ou conclut un accord avec le Client ou se retrouvant autrement dans une relation (pré ou post) contractuelle.
      </p>
      <p>
        1.2. Client : la contrepartie contractuelle à qui une offre est émise et /ou qui a placé une commande et/ou avec laquelle l’Entreprise a conclu un accord pour la livraison de biens ou de services et/ou ses ayants droit.
      </p>
      <p>
        1.3. Biens et Services : tous les matériaux et outils, travaux ou services qui relèvent de la Commande et/ou de l’Accord.
      </p>
      <p>
        1.4 (Confirmation de) commande et/ou Accord : la spécification et la portée/l’étendue des travaux tels que convenus entre l’Entreprise et le Client.
      </p>
      <h3>2. Application. </h3>
      <p>
        Les présentes conditions générales (« Conditions générales ») s’appliquent à toute relation juridique dans laquelle l’Entreprise agit en qualité de (futur) vendeur de Biens et/ou Services. Les dérogations aux dites Conditions Générales peuvent être convenues uniquement par écrit. L’application des conditions générales du Client est, à tout moment, expressément exclue. En outre, le Client renonce à recourir à ses conditions générales.
      </p>
      <h3>3. Offres/conclusion d’un Accord. </h3>
      <p>
        Tous les offres, tarifs et autre documentations de l’Entreprise sont sans engagement. L’Accord est conclu par l'Entreprise au moyen d'une acceptation écrite de la commande passée par le Client. Les échantillons, les modèles, les illustrations et descriptions dans les brochures, les prospectus, les listes de prix, les catalogues et autres informations relatives à l'Entreprise exposés ou mis à disposition ne sont donnés qu'à titre indicatif et ne sont pas déterminants pour l'exécution des Biens ou Services livrés.
      </p>
      <h3>4. Compléments à l’Accord. </h3>
      <p>
        Les modifications écrites à l’Accord et /ou à la Commande à la demande du Clients seront réalisées par l 'Entreprise moyennant un supplément et pour autant que les dites modifications soient raisonnables et réalisables.
      </p>
      <h3>5. Prix et durée de validité des offres.</h3>
      <p>
        5.1 Tous les prix s’entendent franco de port, sauf indication contraire expresse et/ou accord écrit, et s'entendent hors TVA ou autres suppléments fixés par la loi et /ou matériaux d'emballage supplémentaires. Les commandes dont la valeur est inférieure à €75,- ne sont pas traitées. En ce qui concerne les commandes dont la valeur est située entre €75,- et €1.000,-, des frais d'administration, de traitement et de transport d'un montant de 40,- sont pris en compte. Les prix des marchandises de stock sont valables durant l'année de fixation du dit prix.
      </p>
      <p>
        5.2 Les offres sont valables jusqu'à 30 jours à compter de la date de l 'offre. Les prix indiqués dans les offres s’entendent, sauf indication contraire, hors TVA. Les travaux de création artistique et les installations sont présentés ou mentionnés séparément et ne sont pas compris dans le prix. L'Entreprise a le droit de porter en compte au Client les modifications apportées à la rémunération, les coûts des matériaux bruts, le cours des taux de change ou d 'autres événements qui engendrent une hausse de prix pour l'Entreprise.
      </p>
      <h3>6. Livraison/Délai de livraison et conditionnements.</h3>
      <p>
        6.1 Livraison/Délai de livraison. Tous les délais de livraison, y compris les délais de livraison dans les offres et /ou les confirmations de commande, sont des délais limites. Le délai de livraison convenu ne sera donné qu'à titre indicatif, bien que l'Entreprise tente, dans la mesure du possible, de s'y tenir. La livraison se déroule départ usine, le cas échéant, dépôt ou tout autre emplacement fixé par l'Entreprise. L'Entreprise détermine et assure le mode de transport. L'Entreprise a le droit de livrer en parties les Biens et/ou Services. Les livraisons s'effectuent à une seule adresse, sauf accord contraire.
      </p>
      <p>
        6.2 Conditionnements. Les emballages sont toujours vendus par unité d'emballage. L'Entreprise se réserve le droit de remplir les emballages pour achever les commandes de l’Acheteur. Les emballages et/ou les étiquettes atypiques sont proposés sur demande.
      </p>
      <h3>7. Paiement. </h3>
      <p>
        Toutes les factures doivent être acquittées endéans fin de mois + 15 jours à compter de la date de facturation et ce, comme prescrit par l'Entreprise. Le paiement doit être effectué via les moyens de paiement convenus sans aucun décompte, retenue ou ajustement de quelque nature que ce soit. En cas d'arriérés de paiement, toutes les obligations de paiement du Client, quelles que soient les factures déjà envoyées, seront aussitôt exigibles. En outre, le Client est redevable à l'Entreprise, des intérêts dont le taux est supérieur à 3 % par rapport au taux d’intérêt de base Euribor pour autant que les intérêts dûs ne soient pas inférieurs aux intérêts légaux annuel, avec un minimum de €150,- .En cas de non-paiement à l'échéance, conformément à l'article L144 L144-3 et-6 du code de commerce et les directives de l'UE, nos factures seront majorées de plein droit et sans mise en demeure préalable d'un montant de €40,- par facture à titre de frais administratifs. Dans l'hypothèse où le Client ne respecte pas son obligation de paiement, il est tenu de rembourser à l'Entreprise tous les frais judiciaires et extrajudiciaires réellement engagés, parmi lesquels, mais non limités à, des frais d'avocats et d’autres tiers, d'un montant minimum de 15 % (quinze pour cent) de la somme (facturée) due par le Client à l'Entreprise, majoré des intérêts contractuels visés dans le présent article. Les paiements effectués par ou au nom du Client serviront à rembourser les obligations de paiement du Client dans l'ordre suivant : frais de recouvrement extrajudiciaires, frais de procès, intérêt et remboursement du principal. Les contestations relatives aux factures doivent être émises par le Client dans le délai de paiement.
      </p>
      <h3>8. Réserve de propriété. </h3>
      <p>
        L'Entreprise reste propriétaire des Biens livrés au Client jusqu'à ce que celui-ci ait entièrement satisfait à toutes les obligations résultant de tous les Accords relatifs aux Biens et/ou Services livrés ou à livrer, parmi lesquelles, mais pas exclusivement, l'obligation de paiement du prix d'achat des Biens et/ou Services livrés, les taxes, les intérêts, les frais et les indemnisations de tous les dommages subis par l'Entreprise en raison du non- respect des obligations découlant des Accords par le Client. Le Client est tenu de prendre soin et de faire assurer contre tous risques les Biens qu'il conserve chez lui pour l'Entreprise. La manipulation, la transformation et/ou la revente par le Client des Biens livrés par l'Entreprise à des tiers sont sans préjudice de la réserve de propriété. Il est interdit à l’Acheteur de grever de quelque façon que ce soit, de prêter ou de mettre à disposition de tiers les Biens livrés sous la réserve de propriété tant qu'il n'a pas satisfait à toutes les obligations résultant de l’Accord. En cas de non-respect (imputable ou non) de ses obligations découlant de l’Accord, le
      </p>
      <p>
        Client est tenu de retourner à l’Entreprise, à la première demande de celle-ci, tous les Biens qu'il conserve. À cette fin, le Client accorde irrévocablement l'autorisation à
      </p>
      <p>
        L’Entreprise et/ou aux tiers désignés par celle-ci de pénétrer dans tous les emplacements où se trouvent les Biens ou où sont reçus les Services. En outre, le Client est tenu d’établir de la manière prescrite par la loi, à la première demande de l’Entreprise et en faveur de celle-ci, un droit de gage sur tous les biens qui en découlent ou au moyen des Biens et/ou Services livrés par l’Entreprise et ce, même si le Client est devenu propriétaire par accession ou par confusion commerciale.
      </p>
      <h3>9. Sûreté. </h3>
      <p>
        Dans l’hypothèse où l’Entreprise suppose raisonnablement que le Client ne respectera pas, pas en totalité ou pas dans les délais, ses obligations découlant de l’Accord, le Client est alors tenu, à la première demande de l’Entreprise et de la manière indiquée par celle-ci, de constituer une sûrété suffisante pour l’exécution de ses obligations découlant de l’Accord ou de réclamer un paiement anticipé. L’Entreprise a le droit de suspendre l’exécution de ses obligations découlant de l’Accord jusqu’ à ce que le Client satisfasse à ce qui est énoncé à la ligne précédente. Si le Client n’a pas satisfait à la dite demande, dans les 15 jours à compter de la réception de la demande écrite de sûrété de l’Entreprise, toutes ses obligations seront aussitôt exigibles. En outre, l’Entreprise a le droit de résilier l’Accord moyennant une unique déclaration écrite et sans intervention judiciaire, sans préjudice du droit de l’Entreprise à une indemnisation et sans que le Client puisse faire valoir son droit à une quelconque indemnité.
      </p>
   
    </div>
    <div class="col-5">
      <h3>10. Propriété intellectuelle. </h3>
      <p>
        Tous les projets, esquisses de projet, illustrations, descriptions, dessins, modèles, documents, brochures, illustrations fournis par l’Entreprise au Client, ainsi que tous les autres éléments sur lesquels des droits de propriété peuvent être établis restent la propriété de l’Entreprise. La reproduction, la publication, la mise à disposition à des tiers ou la copie de tout ce qui est évoqué précédemment ne sont autorisées que moyennant consentement écrit explicite de l’Entreprise.
      </p>
      <h3>11. Travaux de création artistique et d’imprimerie. </h3>
      <p>
        Le travail de création artistique du Client doit toujours être délivré sous format numérique. En outre, un tirage papier doit toujours être fourni. Par ailleurs, les couleurs du travail de création artistique à imprimer seront en harmonie avec celles de la version papier. Les prix proposés valent toujours pour une version imprimée, sauf mention contraire. Le document « Livraison travaux de création artistique », pouvant être obtenu sur demande auprès de l’Entreprise, détaille les modalités de livraison du travail d’imprimerie. Les prix indiqués sur l’offre sont des adaptations exclusives qui peuvent éventuellement être demandées à posteriori par le Client. Les prix sont basés sur les quantités, les spécifications et les détails de conditionnement et ce, tels que décrits dans l’offre et /ou la confirmation de commande. La date de livraison et la production qui en découle ne peuvent être mises en œuvre qu’après réception et approbation écrite du projet final définitif. La responsabilité de l’Entreprise n’est pas engagée en cas de litiges, d’erreurs et de défaillances passées inaperçues dans les échantillons approuvés ou rectifiés par le Client. Des livraisons supérieures ou inférieures à max 10 % par rapport à la quantité convenue sont autorisées et seront facturées.
      </p>
      <h3>12. Réclamations, obligations d’inspection, force majeure et acquittement. </h3>
      <p>
        Le Client est tenu de vérifier directement après la livraison des Biens si ceux-ci correspondent à ce qui est convenu. Le Client doit immédiatement avertir l’Entreprise par écrit de tous les vices (pour les vices apparents accompagnés de photos sur les lesquelles les vices sont bien visibles), faute de quoi le Client est censé avoir reçu les Biens livrés par l’Entreprise sans vices apparents. Hormis les cas évoqués précédemment, le Client doit communiquer par écrit à l’Entreprise tout vice et/ou dommage aux Biens et/ou Services livrés par l’Entreprise, les vices relatifs à la livraison des Biens et/ou Services ou réclamations relatives à toute autre prestation de l’Entreprise et ce, immédiatement après que le vice ou le dommage est constaté, mais dans tous les cas, au plus tard dans les 15 jours suivant la livraison des Biens et/ou Services. Les réclamations et les actes de défense du Client basés sur la non-conformité des Biens ou Services se prescrivent après le délai d’un an. Dans l’hypothèse où les Biens et/ou Services livrés ne satisfont pas à l’Accord, l’Entreprise aura la possibilité, sans autre obligation, d’ajouter, de remplacer ou de réparer les Biens et/ou Services livrés et ce, au choix de l’Entreprise.
      </p>
      <h3>13. Reprises</h3>
      <p>
        Les Biens livrés ne peuvent être repris, à moins que le Client n’ait formulé une demande écrite de reprise des Biens dans les 8 jours à dater de la livraison des Biens et que l’Entreprise n’ait donné son consentement par écrit, et pour autant que les Biens soient dans leur emballage intégral, original et intact. Les reprises font l’objet d’une indemnité de 85 % de la somme globale de la valeur de la commande. Les Biens dont la valeur est inférieure à €300,- ne sont pas repris. Les produits spécialement conçus pour le Client ne sont pas repris non plus
      </p>
      <h3>14. Calculs, dimensions, poids et autres particularités</h3>
      <p>
        14.1 Spécifications et quantités. Toutes les spécifications communiquées par l’Entreprise au Client et relatives aux Biens et /ou Services, parmi lesquelles le poids, les couleurs, les illustrations, la qualité, les dimensions et autres particularités ne sont données qu’à titre indicatif. Le Client est tenu d’accepter les petits écarts par rapport aux spécifications évoquées. De tels écarts ne constituent pas un fondement pour suspendre l’exécution des obligations du Client découlant de l’Accord ou pour résilier, en tout ou en partie, l’Accord ou encore pour diminuer le prix. Dans l’hypothèse où le Client souhaite que les Biens et/ou Services répondent à certaines spécifications, il doit en informer par écrit et de manière explicite l’Entreprise avant la conclusion de l’Accord.
      </p>
      <p>
        14.2 Propriétés du Client. L’Entreprise assure contre tout risque et aux frais du Client les éléments mis éventuellement à sa disposition par ou au nom du Client.
      </p>
      <p>
        14.3 Conseils et informations. Le Client ne peut pas obtenir des droits dérivés des conseils et des informations. En outre, la responsabilité de l’Entreprise n’est pas engagée en ce qui concerne les imperfections dans les conseils et informations fournis.
      </p>
      <h3>15. Résiliation, suspension. </h3>
      <p>
        Dans l’hypothèse où le Client ne respecte pas, pas dans les délais, pas convenablement ou pas en totalité, ses obligations découlant de l’Accord, de même que s’il demande une surséance de paiement, dépose son bilan, suspend ou liquide la gestion de son entreprise, l’Entreprise a le droit, sans obligation quelconque d’indemnisation, de résilier, en tout ou en partie, l’Accord ou de suspendre l’exécution de ses obligations découlant de l’Accord. En outre, toutes les demandes sont aussitôt exigibles sur le Client sans qu’une quelconque garantie ne soit exigée. Dans le cas où le Client néglige de prendre livraison d’une commande dans un délai fixé à 15 jours, l’Entreprise ne peut plus être tenue d’exécuter ses obligations découlant de l’Accord
      </p>
      <p>
        Dans l’hypothèse où l’Entreprise ne peut assurer, en tout, en partie, temporairement ou durablement l’exécution en raison d’un cas de force majeure, l’Entreprise a le droit de résilier l’Accord ou de suspendre l’exécution ultérieure de l’Accord. 
      </p>
      <p>
        Les circonstances constitutives de force majeure sont, dans tous les cas :
      </p>
      <p>
         •	des faits de l’Entreprise lors de l’exécution de ses engagements découlant de l’Accord des personnes sollicitées (excepté intention et négligence grave du côté de l’Entreprise) ; 
       <br>  •	des faits résultant de l’intention ou de la négligence grave de la part des salariés de l’Entreprise ; 
       <br>  •	l’inadaptabilité non imputable à l’Entreprise de ses biens (de production) utilisés ; 
       <br>  •	les grèves, l’arrêt de travail, la maladie, les embargos d’importation et d’exportation ; 
       <br>  •	la négligence des tiers ;
       <br>  •	les arrêts dans la production ;
       <br>  •	les catastrophes naturelles ou nucléaires ;
       <br>  •	le terrorisme, la guerre ou les menaces de guerre.
      </p>
      <p>
        L’Entreprise peut également recourir à la force majeure si la circonstance qui empêche l’exécution se manifeste au moment où l’Entreprise aurait dû respecter son engagement.
      </p>
      <h3>
        16. Dommages et intérêts. 
      </h3>
      <p>
        La responsabilité de l’Entreprise en ce qui concerne les dommages est limitée aux dommages résultant d’intention ou de négligence grave. L’Entreprise ne peut être tenue responsable à l’égard du client pour tout préjudice immatériel ou consécutif en conséquence d’un vice dans les Biens et /ou Services Livrés. L’Entreprise ne peut être tenue responsable pour tout dommage résultant du non -respect des Biens et/ou Services livrés aux dispositions légales étrangères. L’Entreprise ne peut être tenue responsable pour tout dommage résultant de l’utilisation des Biens autre que celle à laquelle ils sont destinés . Dans l’hypothèse où et pour autant que l’Entreprise soit tenue pour responsable, cette responsabilité est limitée au prix auquel les Biens et/ou Services en question sont livrés et, dans tous les cas, à un montant de €2.500,-. La responsabilité de l’Entreprise se limite à la somme globale qui couvre sa police d’assurance.
      </p>
      <h3>
        17. Litiges. 
      </h3>
      <p>
        Tous les litiges nés de l’Accord ou des autres accords qui en résultent ou qui s’y rapportent, seront de la compétence exclusive en première instance du Tribunal de Tours en France ou relèvent, au choix de l’Entreprise, de la compétence de la juridiction du lieu du siège statuaire de l’Entreprise ou du client.
      </p>
      <p>
        18. L’Accord et tous les autres accords qui en résultent ou qui s’y rapportent, de même que les litiges à propos de leur existence, de leur validité ou de leur fin ne sont connus que par et interprétés en droit français.
      </p>
    </div>
  </div>
  <div class="row mt-4" *ngIf="translate.currentLang  === 'no'">
    <div class="col-5 offset-1">
      <h2 class="text-center">Betalings - og leveringsbetingelser</h2>
      <p class="text-center"></p>
      <h3>1. Priser</h3>
      <p>
        Alle priser er eksklusive montering i butikk, mva., skatter og andre offentlige avgifter. Emballasje, paller og
        pallekarmer debiteres.
      </p>
      <h3>2. Minimum ordreverdi</h3>
      <p>HL Display kan dessverre ikke akseptere bestillinger hvor vare verdi er under kr 700.-</p>
      <h3>3. Leveringsbetingelser</h3>
      <p>
        Dersom ikke annet er avtalt sørger HL Display Nordic AB for transport av varene for mottagers regning. Risikoen
        for varens tilfeldige undergang eller beskadigelse går over til kunden på det tidspunkt overlevering fra HLs
        transportør eller en uavhengig speditør er foretatt. Incoterms FCA Oslo. HL forskutterer all frakt. Standard
        lagerførte produkter leveres normalt ut fra lager i løpet av 4-5 arbeidsdager etter ordre. For ekspressleveranser
        tilkommer ekstra leveringsavgift på kr 500.- samt fraktkostnad for ekspressfrakt.
      </p>
      <h3>4. Lagervarer</h3>
      <p>
        HL Display Nordic AB har som mål å tilpasse produktvalg og varespekter etter Deres behov med et forbehold om
        utsolgte lagervarer.
      </p>
      <h3>5. Forbehold</h3>
      <p>
        Vi forbeholder oss retten til å endre priser og leveringsbetingelser dersom selskapets leveranseavtaler eller
        andre utenforstående forhold tilsier dette.
      </p>
      <h3>6. Betalingsbetingelser</h3>
      <p>
        Faktura forfaller til betaling 30 dager fra faktura dato dersom ikke annet er avtalt. Etter forfall beregnes
        morarenter etter gjeldene regler. Kunden plikter å oppgi korrekt fakturaadresse ved ordreinngang, samt
        tilfredsstillende referanse. Ved omfakturering legges det til et administrasjonsgebyr pålydende NOK 400,- pr.
        faktura.
      </p>
    </div>
    <div class="col-5">
      <h3>7. Forsikring</h3>
      <p>Dersom ikke annet er avtalt forsikrer HL automatisk alle forsendelser oppad begrenset til varens verdi.</p>
      <h3>8. Reklamasjoner</h3>
      <p>
        Den som vil påberope feil eller mangler ved den mottatte vare, må uten ugrunnet opphold reklamere skriftlig og
        senest innen 8 (åtte) dager etter at produktet ble mottatt. Reklamasjonsretten gjelder bare feil/mangler som
        skyldes fabrikasjonsfeil.
      </p>
      <h3>9. Returer</h3>
      <p>
        Det godtas kun retur av hele pakninger, og kun etter avtale. Ved retur av feil bestilte eller overskytende varer
        påløper et returgebyr på kr 100.- 80 % av ordreverdien krediteres. Kunden betaler returfrakt. Ordrenummer og
        fakturanummer må oppgis. Det godtas ikke returer på spesialbestilte varer. Returfrist er 3 mnd.
      </p>
      <h3>10. Mislighold fra HL Display Norge AS sin side</h3>
      <p>
        Dersom varene ikke oppfyller avtalens spesifikasjoner og de krav som er angitt og dette skyldes en
        fabrikasjonsfeil, foreligger en mangel. Se punkt 8 for reklamasjonsfrist. Dette gjelder likevel ikke dersom
        mangelen skyldes en force majeure eller forhold som kunden har ansvaret eller risikoen for.
      </p>
      <h3>11. Mislighold fra kundens side</h3>
      <p>
        Mislighold fra kundens side foreligger dersom betaling ikke skjer innenfor rammen av hva som er avtalt.
        Betalingsmislighold gir HL Display Norge AS rett til å velge mellom fortsatt å opprettholde avtalen og rentekravet
        etter punkt 6 eller heve avtalen med 14 dagers varsel. HL kan ved betalingsmislighold videre etter fritt valg
        stoppe enhver ytelse til kunden, både etter nærværende og etter andre avtaler mellom partene inntil riktig
        betaling foreligger. HL kan endelig kreve erstatning for det tap selskapet lider som følge av forsinkelsen med
        betalingen fra kundens side.
      </p>
      <h3>12. Skade</h3>
      <p>HL Display Norge AS er kun ansvarlig for produktet og ikke for skade på personer, eiendom eller annet utstyr</p>
    </div>
  </div>
  <div class="row mt-4" *ngIf="translate.currentLang  === 'nl'">
    <div class="col-5 offset-1">
      <h2 class="text-center">ALGEMENE LEVERINGSVOORWAARDEN</h2>
      <p class="text-center">HL Display België N.V., HL Display Nederland B.V. en HL Display France S.A.S.</p>
      <h3>1. Definities</h3>
      <p>
        1.1. Onderneming: hieronder wordt verstaan HL Display België N.V., HL Display Nederland B.V. en HL Display France S.A.S. en/of hun nevenvestiging of dochtermaatschappij , die een offerte uitbrengt, een order accepteert en/of een overeenkomst aangaat met de Afnemer of anderszins in een (pre- of post) contractuele relatie met Afnemer komt te staan .
      </p>
      <p>
        1.2. Afnemer: de contractuele tegenpartij aan wie een offerte is uitgebracht en /of die een order heeft geplaatst en/of met wie de Onderneming een overeenkomst heeft gesloten voor de levering van goederen of diensten en/of haar rechtsopvolgers.
      </p>
      <p>
        1.3. Goederen en Diensten: alle materialen en gereedschappen, werkzaamheden of diensten die onderwerp zijn van de Order en/of de Overeenkomst.
      </p>
      <p>
        1.4 Order(bevestiging) en/of Overeenkomst: de specificatie en het bereik/ de omvang van werkzaamheden zoals overeengekomen tussen Onderneming en Afnemer .
      </p>
      <h3>2. Toepasselijkheid.</h3>
      <p>
        Deze algemene voorwaarden ( Algemene Voorwaarden ) zijn van toepassing op alle rechtsverhoudingen waarbinnen de Onderneming handelt als (toekomstig) verkoper van Goederen en/of Diensten. Afwijkingen van deze Algemene Voorwaarden kunnen alleen schriftelijk worden overeengekomen . De toepasselijkheid van de algemene voorwaarden van de Afnemer wordt te allen tijde uitdrukkelijk uitgesloten en Afnemer doet afstand van een beroep op zijn algemene voorwaarden.
      </p>
      <h3>3. Offertes/tot stand komen van een Overeenkomst. </h3>
      <p>
        Alle offertes dan wel prijsopgaven en andere documentatie van de Onderneming zijn geheel vrijblijvend . De Overeenkomst komt tot stand door schriftelijke acceptatie door de Onderneming van een door de Afnemer geplaatste order. Monsters of getoonde en ter beschikking gestelde modellen of afbeeldingen en beschrijvingen in folders, prospecti, prijslijsten, catalogi en dergelijke betrekking hebbend op de Onderneming worden enkel beschouwd als indicatief en niet bepalend voor de uitvoering van de geleverde Goederen of Diensten.
      </p>
      <h3>4. Aanvullingen op de Overeenkomst. </h3>
      <p>
        Schriftelijke wijzigingen in de Overeenkomst en /of Order op verzoek van de Afnemer zullen door de Onderneming tegen een meerprijs worden uitgevoerd, mits deze wijzigingen redelijk uitvoerbaar zijn.
      </p>
      <h3>5. Prijzen en geldigheidsduur offertes.</h3>
      <p>
        5.1 Alle prijzen zijn op basis van franco levering, tenzij nadrukkelijk anders vermeld en /of schriftelijk overeengekomen en exclusief BTW of andere wettelijk opgelegde toeslagen en/of extra verpakkingsmaterialen. Orders met een orderwaarde lager dan €75,- worden niet in behandeling genomen. Voor orders met een orderwaarde van €75,- tot en met €1.000,- worden €40,- administratie-, handelings- en transportkosten in rekening gebracht. Prijzen van voorraadartikelen zijn geldig in het jaar waarin deze prijs is vastgelegd.
      </p>
      <p>
        5.2 Offertes zijn tot 30 dagen na offertedatum geldig. De in offertes genoemde prijzen zijn tenzij anders vermeld exclusief BTW . Werkzaamheden voor creatie artwork en installaties worden apart geoffreerd of vermeld en zijn niet in de prijs inbegrepen . De Onderneming is gerechtigd wijzigingen in loon, kostprijzen van ruwe materialen, valuta wisselkoersen of andere omstandigheden die leiden tot een prijsstijging voor de Onderneming aan de Afnemer door te berekenen .
      </p>
      <h3>6. Levering/Levertijd en verpakkingen.</h3>
      <p>
        6.1 Levering/Levertijd. Alle levertermijnen inclusief de levertermijnen in aanbiedingen en/of orderbevestigingen zijn streeftermijnen. De overeengekomen levertijd zal slechts een indicatie zijn hoewel de Onderneming hiermee zoveel mogelijk rekening zal houden. Levering vindt plaats af fabriek c.q. -magazijn of enige ander door de Onderneming te bepalen locatie. De Onderneming bepaalt de wijze van transport en draagt zorg voor de verzekering tijdens het transport. De Onderneming is gerechtigd de door haar te leveren Goederen en/of Diensten in delen te leveren. Leveringen vinden plaats op één adres, tenzij uitdrukkelijk anders overeengekomen.
      </p>
      <p>
        6.2 Verpakkingen. Afname van verpakkingen geschiedt altijd per gehele verpakkingseenheid. De Onderneming behoudt zich het recht voor om bestellingen van de Afnemer af te ronden op volle verpakkingen. Afwijkende verpakkingen en/ of labels worden op aanvraag geoffreerd .
      </p>
      <h3>7. Betaling. </h3>
      <p>Alle facturen dienen op factuurdatum einde maand + 15 dagen te zijn voldaan op de wijze zoals voorgeschreven door de Onderneming. Betaling dient plaats te vinden in de overeengekomen betalingsmiddelen zonder enige aftrek, inhouding of verrekening van welke aard ook. In het geval van achterstallige betaling zullen alle betalingsverplichtingen van de Afnemer, ongeacht of hiervoor reeds facturen zijn verstuurd, direct opeisbaar worden. In het geval van achterstallige betaling is de Afnemer aan de Onderneming een rente verschuldigd ter hoogte van 3% boven de Euribor basis rente, met dien verstande dat de verschuldigde rente nooit minder zal bedragen dan de wettelijke rente per jaar, met een minimum van €150,-. Indien de facturen niet binnen de overeengekomen betalingstermijn wordt voldaan, dan wordt, overeenkomstig artikel L 144-3 en L144-6 van de handelscode en de EU richtlijnen, €40,- per te laat betaalde factuur in rekening gebracht. Indien de Afnemer haar betalingsverplichting niet nakomt , is de Afnemer verplicht aan de Onderneming alle werkelijk gemaakte gerechtelijke en buitengerechtelijke kosten te vergoeden, waaronder, maar niet beperkt tot, de kosten van advocaten en andere derden, ten bedrage van minimaal 15 % (vijftien procent) van het door de Afnemer aan de Onderneming verschuldigde (factuur)bedrag vermeerderd met de in dit artikel bedoelde contractuele rente. Betalingen door of namens de Afnemer zullen ter aflossing van de betalingsverplichtingen van de Afnemer in de volgende volgorde worden aangewend : buitengerechtelijke incassokosten, proceskosten, rente en aflossing van de hoofdsom(men). Bezwaren tegen de facturen dienen door de Afnemer binnen de betalingstermijn gemaakt te worden . </p>
      <h3>8. Eigendomsvoorbehoud. </h3>
      <p>
        De Onderneming blijft eigenaar van de door haar aan de Afnemer geleverde Goederen totdat de Afnemer volledig heeft voldaan aan alle verplichting uit hoofde van alle Overeenkomsten met betrekking tot geleverde of nog te leveren Goederen en/of Diensten, waaronder, maar niet uitsluitend, de verplichting tot betaling van de koopprijs van de geleverde Goederen en/of Diensten, belastingen, rente, kosten en vergoeding van alle schade van de Onderneming als gevolg van niet-nakoming door de Afnemer van haar verplichtingen uit de Overeenkomsten . De Afnemer is gehouden om de Goederen gedurende de periode dat zij de Goederen voor de Onderneming houdt deugdelijk te onderhouden en te verzekeren tegen alle risico s . Bewerking, verwerking en/of doorverkoop door de Afnemer van door de Onderneming geleverde Goederen aan derden laat het eigendomsvoorbehoud onverlet . Het is de Afnemer verboden de onder eigendomsvoorbehoud geleverde Goederen op enige wijze te bezwaren, in bruikleen te geven of anderszins aan derden ter beschikking te stellen zolang hij niet heeft voldaan aan alle verplichtingen uit hoofde van de Overeenkomst. In geval van (al dan niet toerekenbare) niet-nakoming van zijn verplichtingen uit de Overeenkomst is de Afnemer gehouden alle door haar voor de Onderneming gehouden Goederen op eerste verzoek van de Onderneming aan haar te retourneren. De Afnemer verleent voor dit doel nu reeds onherroepelijk machtiging aan de Onderneming en/of aan door de Onderneming aan te wijzen derden, alle plaatsen waar de Goederen zich bevinden of de Diensten worden afgenomen te betreden. Daarnaast is de Afnemer verplicht om op eerste verzoek van de Onderneming ten behoeve van de Onderneming op de daarvoor door de wet voorgeschreven wijze een pandrecht te vestigen op alle goederen die zijn ontstaan uit of met behulp van de door de Onderneming geleverde Goederen en/of Diensten, ook als de Afnemer door natrekking of zaaksvermenging eigenaar is geworden. 
      </p>
      <h3>9. Zekerheidstelling. </h3>
      <p>
        Indien de Onderneming redelijkerwijs vermoedt dat de Afnemer zijn verplichtingen uit de Overeenkomst niet , niet volledig of niet tijdig zal nakomen, is de Afnemer verplicht op het eerste verzoek van de Onderneming op een door de Onderneming aan te geven wijze een genoegzame zekerheid te stellen voor de nakoming van zijn verplichtingen uit de Overeenkomst of vooruitbetaling te verlangen. De Onderneming is gerechtigd de nakoming van haar verplichtingen uit de Overeenkomst op te schorten totdat de Afnemer aan het in de vorige zin bepaalde heeft voldaan . Wanneer Afnemer niet binnen 14 dagen na ontvangst van een schriftelijk verzoek tot zekerheidstelling van Onderneming voldaan heeft aan dit verzoek, zullen al zijn verplichtingen direct opeisbaar worden en is de Onderneming gerechtigd de Overeenkomst door een enkele schriftelijke verklaring en zonder rechterlijke tussenkomst te ontbinden, onverminderd het recht van de Onderneming op schadevergoeding en zonder dat de Afnemer enige aanspraak op schadevergoeding kan doen gelden.
      </p>
    </div>
    <div class="col-5">
     
      
      
      <h3>10. Intellectuele eigendom. </h3>
      <p>
        Alle door de Onderneming aan de Afnemer verstrekte ontwerpen , ontwerpschetsen, afbeeldingen, omschrijvingen, tekeningen, modellen, documenten, brochures, illustraties en alle andere zaken waarop intellectuele eigendomsrechten kunnen rusten, blijven eigendom van de Onderneming. Vermenigvuldigen, openbaar maken, ter beschikking stellen aan derden of kopiëren van de in de vorige zin genoemde zaken is uitsluitend toegestaan met uitdrukkelijke schriftelijke toestemming van de Onderneming.
      </p>
      <h3>11. Artwork en drukwerk. </h3>
      <p>
        Aanlevering van artwork door eigen diensten van de Afnemer dient steeds digitaal te gebeuren . Bij de aanlevering van artwork moet er steeds een hard copy aangeleverd worden. Hierop zullen de kleuren van het te printen artwork afgestemd worden. Aangeboden prijzen zijn steeds voor 1 printversie, tenzij anders vermeld. Specificaties over hoe het drukwerk aangeleverd dient te worden staan vermeld in het document Aanlevering Artwork dat de Onderneming op aanvraag ter beschikking wordt gesteld. De op de offerte genoemde prijzen zijn exclusief aanpassingen die eventueel achteraf aangevraagd worden door de Afnemer . De prijzen zijn gebaseerd op aantallen, specificaties en verpakkingsdetails zoals ze omschreven staan in de offerte en /of orderbevestiging. Het bepalen van de leverdatum en de hieruit voortvloeiende productie kan enkel van start gaan na ontvangst en schriftelijke goedkeuring van het definitieve eindontwerp. De Onderneming is niet aansprakelijk voor afwijkingen, fouten en gebreken die onopgemerkt zijn gebleven in de door de Afnemer goedgekeurde of gecorrigeerde proeven . Meer - of minderleveringen van max. 10% ten opzichte van het overeengekomen aantal zijn toegestaan en zullen in rekening gebracht worden.
      </p>
      <h3>12. Klachten, verplichting tot inspectie, overmacht en nakoming.</h3>
      <p>
        De Afnemer is verplicht direct na aflevering van de Goederen te controleren of de Goederen voldoen aan hetgeen overeengekomen is. De Afnemer dient de Onderneming onverwijld van alle gebreken (voor zichtbare gebreken vergezeld van foto s waarop de gebreken goed zichtbaar zijn afgebeeld) schriftelijk op de hoogte te stellen, bij gebreke waarvan de Afnemer wordt geacht de door de Onderneming geleverde Goederen zonder zichtbare gebreken te hebben ontvangen. Buiten de in de vorige zin bedoelde gevallen dient de Afnemer ieder gebrek en /of schade aan de door de Onderneming geleverde Goederen en/of Diensten, gebreken met betrekking tot de aflevering van Goederen en/of Diensten of klachten met betrekking tot enige andere prestatie van de Onderneming, onmiddellijk nadat het gebrek of de schade door de Afnemer is geconstateerd schriftelijk aan de Onderneming te melden , maar in ieder geval niet later dan uiterlijk 14 dagen nadat de Goederen en/of Diensten zijn geleverd. Vorderingen en verweren van de Afnemer gebaseerd op non -conformiteit van de Goederen of Diensten, verjaren na 1 jaar. Indien de geleverde Goederen en/of Diensten niet voldoen aan de Overeenkomst, zal de Onderneming in de gelegenheid worden gesteld en niet tot meer verplicht zijn dan tot aanvulling, vervanging of reparatie van het geleverde, zulks naar keuze van de Onderneming.
      </p>
      <h3>13. Terugnames.</h3>
      <p>
        Terugname van geleverde Goederen is niet mogelijk, tenzij de Afnemer de Onderneming binnen 8 dagen na levering van de Goederen schriftelijk heeft verzocht de Goederen terug te nemen en de Onderneming daarmee schriftelijk heeft ingestemd, en mits de Goederen zijn voorzien van volledige, originele en onbeschadigde verpakkingen. Terugnames geschieden met een vergoeding van 85% van de totaalsom van de orderwaarde. Goederen met een waarde lager dan €300,- worden niet teruggenomen. Producten special geproduceerd voor de Afnemer worden niet teruggenomen .
      </p>
      <h3>14. Berekeningen, maten, gewichten en andere bijzonderheden</h3>
      <p>
        14.1 Specificatie en kwantiteiten. Alle door de Onderneming aan de Afnemer bekendgemaakte specificaties met betrekking tot de Goederen en /Diensten, waaronder gewichten, kleuren, afbeeldingen, kwaliteiten, maten en andere bijzonderheden zijn slechts een indicatie. De Afnemer is gehouden om geringe afwijkingen van genoemde specificaties te accepteren . Dergelijke afwijkingen vormen geen grond voor opschorting van de nakoming van Afnemers verplichtingen uit de Overeenkomst of voor gehele of gedeeltelijke ontbinding van de Overeenkomst of verlaging van de prijs. Indien de Afnemer wenst dat de Goederen en /of Diensten aan bepaalde specificaties dienen te voldoen dan dient hij dit vóór het sluiten van de Overeenkomst schriftelijk en 
      </p>
      <p>
        14.2 Eigendommen van de Afnemer. Eventuele door of namens de Afnemer aan de Onderneming ter beschikking gestelde zaken zal de Onderneming voor risico en op kosten van de Afnemer onder zich houden.
      </p>
      <p>
        14.3 Adviezen en informatie. De Afnemer kan geen rechten ontlenen aan adviezen en informatie en de Onderneming is niet aansprakelijk voor onvolkomenheden in verstrekte adviezen en informatie.
      </p>
      <h3>15. Ontbinding, opschorting. </h3>
      <p>
        Indien de Afnemer zijn verplichtingen uit de Overeenkomst niet , niet tijdig, niet behoorlijk of niet volledig nakomt, alsmede indien hij surséance van betaling aanvraagt, zijn faillissement wordt aangevraagd, zijn bedrijfsvoering staakt of wordt geliquideerd, is de Onderneming gerechtigd, zonder enige verplichting tot schadevergoeding de Overeenkomst geheel of gedeeltelijk te ontbinden of de nakoming van haar verplichtingen uit de Overeenkomst op te schorten en worden alle vorderingen op de Afnemer direct opeisbaar zonder dat daartoe enige ingebrekestelling is vereist . In geval de Afnemer verzuimt een bestelling in ontvangst te nemen binnen een hem daarvoor gestelde termijn van 14 dagen, kan de Onderneming niet langer gehouden worden tot nakoming van haar verplichtingen uit de Overeenkomst. Indien nakoming door de Onderneming geheel, gedeeltelijk, tijdelijk of blijvend onmogelijk is als gevolg van overmacht is de Onderneming gerechtigd de Overeenkomst te ontbinden of verdere nakoming van de Overeenkomst op te schorten. Omstandigheden die in ieder geval overmacht opleveren zijn: handelingen van door de Onderneming bij de nakoming van haar verplichtingen uit de Overeenkomst ingeschakelde personen (uitgezonderd opzet en grove nalatigheid aan de zijde van de Onderneming); handelingen veroorzaakt door opzet of grove nalatigheid van werknemers van de Onderneming ; niet aan de Onderneming toe te rekenen ongeschiktheid van de door haar gebruikte (productie)goederen; stakingen, werkonderbrekingen, ziekte, import- en exportembargo s; niet nakoming door derden; onderbrekingen in de productie; natuurlijke of nucleaire rampen; terrorisme, oorlog of oorlogsdreiging. De Onderneming kan ook een beroep doen op overmacht indien de omstandigheid die nakoming verhindert intreedt nadat de Onderneming haar verbintenis had moeten nakomen.
      </p>
      <h3>16. Schadevergoeding. </h3>
      <p>
        De aansprakelijkheid van de Onderneming voor schade is beperkt tot schade die voortkomt uit opzet of grove nalatigheid. De Onderneming is jegens de Afnemer niet aansprakelijk voor enige immateriële - of gevolgschade als gevolg van een gebrek in geleverde Goederen en/of Diensten. De Onderneming is niet aansprakelijk voor enige schade als gevolg van dat de geleverde Goederen/of Diensten niet voldoen aan buitenlandse wettelijke voorschriften. De Onderneming is niet aansprakelijk voor enige schade ontstaan als gevolg van het gebruik van de Goederen anders dan waarvoor zij redelijkerwijs zijn bedoeld. Indien en voor zover de Onderneming wel aansprakelijk is, beperkt die aansprakelijkheid zich tot de prijs waarvoor de betreffende Goederen en /of Diensten geleverd zijn en in ieder geval tot het bedrag van €2.500,-. De Onderneming is in geen geval verder aansprakelijk dan het totaalbedrag dat haar verzekeringspolis dekt.
      </p>
      <h3>17. Geschillen. </h3>
      <p>
        Ten aanzien van alle geschillen die ontstaan naar aanleiding van de Overeenkomst of nadere overeenkomsten die daarvan het gevolg zijn of die daarmee
      </p>
    </div>
  </div>
</div>

<ng-template #showInEn>
  <div class="row mt-4" *ngIf="translate.currentLang  === 'en'">
    <div class="col-5 offset-1">
      <h2 class="text-center">Terms & conditions</h2>
      <p class="text-center">These terms and conditions apply to all Contracts entered into by HL Display (UK) Limited</p>
      <h3>1. Definitions</h3>
      <p>
        'HL Display (UK)', HL Display (UK) Limited of 1-2 Horsecroft Road, The Pinnacles, Harlow, Essex, CM19 5BH 'the
        Contract' any contract for the supply of Goods and /or Services by HL Display (UK) to the Customer. 'the Customer'
        any customer of HL Display (UK) to whom Goods and /or Services are supplied by HL Display (UK) under the Contract.
        'Goods' any goods to be supplied by HL Display (UK) to the Customer under the Contract. 'Price' all charges
        expenses and other sums due to be paid by the Customer to HL Display (UK). 'Services' any services to be supplied
        by HL Display (UK) to the Customer under the Contract. 'Quotation' any offer by HL Display (UK) for the provision
        of the Goods and/or Services to the Customer. 'Terms' the terms and conditions set out in this document and any
        additional terms and conditions agreed in writing by HL Display (UK) and the Customer.
      </p>
      <h3>2. Acceptance</h3>
      <p>
        2.1 These Terms exclude all other terms and conditions including any implied by a course of dealing between HL
        Display (UK) and the Customer. Any variation of these Terms is only valid if agreed in writing by a Director of HL
        Display (UK).
      </p>
      <p>2.2 The Customer is deemed to have accepted the Terms when it accepts HL Display (UK)’s Quotation.</p>
      <p>
        2.3 For the avoidance of doubt, these Terms are to be read in conjunction with the provisions of the Unfair
        Contract Terms Act 1977, the Sale and Supply of Goods Act 1994 and the Unfair Terms in Consumer Contracts
        Regulations 1994 or any statutory modification there of. Nothing in these Terms shall affect the statutory rights
        of a consumer.
      </p>
      <h3>3. Quotations</h3>
      <p>
        3.1 Quotations are valid only for 30 days from the date of issue unless agreed in writing by HL Display (UK). Any
        items not specified on the Quotation but requested by the Customer at any time will be charged in addition.
      </p>
      <p>
        3.2 Value Added Tax and any other taxes, government charges or customs duties are not included in the Price and
        will be charged separately.
      </p>
      <p>
        3.3 Carriage charges are not included in the Price and will be charged separately. HL Display (UK) reserves the
        right to vary the carriage charges from time to time.
      </p>
      <p>
        3.4 The Price shall be paid to HL Display (UK) not later than 30 days after the date of HL Display (UK)’s invoice.
        Time shall be of the essence for payment. If the Customer does not pay within such period then HL Display (UK) may
        make a surcharge for allowing credit to the Customer between the invoice date and the date of actual payment by
        the Customer at the rate of 8% above the Bank of England base rate from time to time on the amount outstanding
        after as well as before judgement.
      </p>
      <p>
        3.5 Should for any reason HL Display (UK) be unable to trade on a credit account basis - the Customer will be
        advised of the terms and method of payment at the time the order is received by HL Display (UK). In such cases the
        order will not be processed until payment to the full value of the order is received by HL Display (UK).
      </p>
      <h3>4. Delivery</h3>
      <p>
        4.1 Unless otherwise agreed in writing time is not of the essence of the Contract but HL Display (UK) undertakes
        to use its reasonable endeavours to supply the Goods and/or Services in a reasonable time any time or date for
        delivery made by HL Display (UK) is an estimate only and HL Display (UK) shall not be liable for the consequences
        of any delay.
      </p>
  
      <p>
        4.2 HL Display (UK) reserves the right to make delivery by instalments. In such event each delivery shall
        constitute a separate Contract.
      </p>
      <p>
        4.3 Unless otherwise agreed in writing, delivery of the Goods and/or Services shall be effected at the place set
        out in the Quotation.
      </p>
      <p>
        4.4 Signature of HL Display (UK)’s delivery note shall be conclusive evidence as to the quantity and quality of
        the Goods delivered unless within 7 days of receipt of the Goods, notice is given to HL Display (UK) of any errors
        in quantity and the Customer acknowledges that this period will give him a reasonable opportunity to examine the
        Goods.
      </p>
      <p>
        4.5 In respect of any variation in colour of Goods supplied from the colour specified on the Quotation the
        Customer must return the Goods to HL Display (UK) within 14 days of the date of delivery If there is a variation
        in colour then HL Display (UK)’s only liability shall be to re-supply the Goods to the Customer and pay for the
        reasonable extra transportation costs involved in returning and resupplying the Goods. If the Goods returned
        comply with the colour specified in the Quotation then the cost of transportation to HL Display (UK) and the
        return of the Goods to the Customer shall be for the Customer’s account.
      </p>
      <h3>5. Indemnity</h3>
      <p>
        If HL Display (UK) supplies Services under the Contract at a place other than its own premises then the Customer
        shall be responsible for ensuring that the site where the Services are to be provided is prepared for the
        provision of the Services to the satisfaction of HL Display (UK) (in the case of retail outlets this would include
        clearing the site of the Customers goods) and the Customer will indemnify HL Display (UK) against any loss, damage
        or delay arising as a result of poor site condition.
      </p>
      <h3>6. Risk and Property</h3>
      <p>
        6.1 The risk of the Goods shall pass to the Customer upon delivery but the full legal and equitable ownership of
        all Goods supplied by HL Display (UK) whether under the Contract or any other contract shall remain in HL Display
        (UK) until payment in full has been made. Until HL Display (UK) is paid in full for the Goods the relationship of
        the Customer to HL Display (UK) shall be fiduciary in respect of the Goods and the Customer shall clearly identify
        the Goods as being the property of HL Display (UK).
      </p>
      <p>
        6.2 HL Display (UK) shall be entitled forthwith to recover and re-sell any such Goods to which HL Display (UK) has
        title and to enter upon the premises of the Customer with such transport as may be necessary for that purpose, if
        the Customer commits any default. The expression 'default' shall include (but not be limited to) failure to pay HL
        Display (UK) on the due date, the appointment of a receiver of the Customers business, or the presentation of a
        petition to wind up the Customer. If the Customer makes default in any payment then HL Display (UK) shall have a
        lien for any amount outstanding to HL Display (UK) on any goods the property of the Customer which may remain in
        the possession of HL Display (UK) or its agents.
      </p>
    </div>
    <div class="col-5">
      <h3>7. Warranties</h3>
      <p>
        7.1 Unless otherwise agreed in writing and signed by a director of HL Display (UK). HL Display (UK) shall not be
        liable for any recommendations, advice, opinion or statement given or made by HL Display (UK), its servants or
        representatives.
      </p>
      <p>
        7.2 HL Display (UK) will not in any circumstances be liable for any loss or damage direct or indirect or
        consequential, sustained by the Customer which may in any degree be attributable to the adoption by the Customer
        or any third party or technical information, data or advice given by or on behalf of the Customer in relation to
        the use of the Goods. For the avoidance of doubt this subsection is totally ineffective in respect of negligence
        resulting in death or personal injury.
      </p>
      <p>
        7.3 For the avoidance of doubt HL Display (UK) hereby absolves itself from any liability in respect of any damage
        caused by un-authorised alterations to the Goods after delivery.
      </p>
      <h3>8. Exclusion and Limitation of Liability</h3>
      <p>
        8.1 Where HL Display (UK) supplies Goods under the Contract save insofar as defects in those Goods cause death or
        personal injury, HL Display (UK)’s liability shall be limited as set out in clauses 8.2. 8.3 and 8.5 below.
      </p>
      <p>
        8.2 Where HL Display (UK) supplies Goods under the Contract and those Goods cause loss or damage to the Customers
        property HL Display (UK)’s liability shall not exceed the lower of the value of the property lost or damaged (or
        its repair cost, whichever is the lower) and £100,000.
      </p>
      <p>
        8.3 Where HL Display (UK) supplies Goods under the Contract and such Goods subsequently suffer loss or damage or
        deterioration caused by the negligence of HL Display (UK) or its employees or agents acting under its control then
        HL Display (UK)’s liability shall be limited to replacing those Goods lost or damaged.
      </p>
      <p>
        8.4 Where HL Display (UK) supplies Services under the Contract save insofar as the provision of those Services
        causes death or personal injury caused by the negligence of HL Display (UK) or its employees or agents acting
        under its control then HL Display (UK)’s liability shall not exceed the lower of the value of the property lost or
        damaged (or its repair cost. whichever is the lower) and £1,000,000.
      </p>
      <p>
        8.5 In no circumstances shall HL Display (UK) be liable for any consequential or indirect loss or damage of the
        Customer arising from any liability of HL Display (UK).
      </p>
      <h3>9. Force Majeure</h3>
      <p>
        9.1 Neither HL Display (UK) nor the Customer shall be liable for any failure to perform any part of the Contract
        if performance has been delayed hindered or prevented by any circumstances whatsoever which are not within the
        control of the party which is hindered delayed or prevented and are not preventable by reasonable diligence on its
        part.
      </p>
      <p>
        9.2 Without limiting Clause 9.1 above neither HL Display (UK) nor the Customer shall be liable for any failure to
        comply due to any order or request of any National, Provincial, Regional or Local Authorities or for any delays
        caused by strikes, lockouts, Act of God or any other event beyond its control. If HL Display (UK) is at any time
        delayed hindered or prevented from delivering in whole or in part of the Goods and/or Services, then HL Display
        (UK) shall be at liberty to withhold, suspend or reduce delivery to such extent as is reasonable in all the
        circumstances The Customer will then be liable for a rateable proportion of the Price. In no circumstances shall
        the Customer be entitled to rescind the Contract on the grounds of the failure by HL Display (UK) where the
        provisions of this Clause 9 apply.
      </p>
      <h3>10. Intellectual Property</h3>
      <p>
        10.1 HL Display (UK) remains the owner of all intellectual property rights in the Products and the results of
        Services, including inventions, models, designs, schedules, drawings, illustrations and all other documentation
        etc. comprised therein as supplied to the Customer. The Customer shall not copy, reproduce or circulate the above
        in whole or in part, without the prior written permission of HL Display (UK).
      </p>
      <p>
        10.2 HL Display (UK) has not verified the possible existence of third party intellectual property rights which
        might be infringed as a consequence of the sales and/or delivery of the products and services and HL Display (UK)
        shall not be liable for any loss or damage in that respect.
      </p>
      <h3>11. Termination</h3>
      <p>
        If the Customer commits a breach of these Terms or being a company a resolution or petition for its winding up is
        passed or presented or a receiver appointed or an administrator is appointed (or if an individual commits any act
        of bankruptcy or enters into a composition with his/her creditors or suffers any execution believed on his/her
        goods) or is the subject to any action which in the reasonable opinion of HL Display (UK) is prejudicial to the
        interests of HL Display (UK), HL Display (UK) shall be entitled in its absolute discretion to terminate the
        Contract or suspend its performance and all sums due to HL Display (UK) from the Customer shall become payable
        forthwith.
      </p>
      <h3>12. Returns and Custom Goods</h3>
      <p>
        No returns will be accepted without the Sellers prior authorisation. There is a re-stocking charge of 15% of the
        value of the goods returned plus all carriage charges that have been incurred. Notification of damage or shortage
        must be received within 2 days of receipt of goods. Custom made or modified products are non-returnable. No
        returns of any nature will be accepted after 60 days from despatch date. Please note that it is the customer's
        responsibility to check that goods are received in good condition prior to signing carriers delivery note.
      </p>
      <h3>13. Cancellations</h3>
      <p>
        If you have ordered by internet, under the Distance Selling Regulations, you have a statutory right to cancel your
        order within seven working days and receive a full refund for any delivery. This does not apply to items
        personalised or made to your specification for which, once your order has been received we will not accept any
        cancellation. To cancel you should email Customer Services at sales&#64;interbin.com or write to us using the address
        in our 'Contact Us' section within seven working days commencing the day after the day of delivery of your
        item(s). Where an order cannot be cancelled a refund may be given subject to our returns policy at section 12
      </p>
    </div>
  </div>
  <div class="row mt-4" *ngIf="isCustomerPortal1210Kesko && translate.currentLang  === 'fi'">
    <h2 class="text-center w-100 mb-3">Yleiset toimitusehdot</h2>
    <div class="col-5 offset-1">
      <p> <span class="subtitle">Toimitusehto:</span>  DAP</p> 
      <p> <span class="subtitle">Lavat, lavakaulukset ja rahti:</span> Veloitetaan toteutuneen mukaan. </p> 
      <p> <span class="subtitle">Maksuehto:</span> 30 pv netto Keskon sopimuksen mukaan. Veloitamme viivästyneistä maksuista korkolain mukaisen viivästyskoron. Tuotteet ovat HL Display Nordic AB:n (myyjä) omaisuutta kunnes lasku on kokonaan maksettu.
      </p> 
      <p> <span class="subtitle">Toimitusaika: </span>Varastotuotteet normaalisti noin 1 viikko, välimyyntivarauksin. Tilaustuotteilla 3-5 viikkoa. Pikalähetyksistä veloitamme 60 euron pikalähetyslisän rahtimaksun lisäksi.
      </p>
      <p> <span class="subtitle">Pienlaskutuslisä:</span> Alle 250 euron tilauksista veloitamme 10 euron pienlaskutuslisän.</p>
      <p> <span class="subtitle">Pakkauslisä: </span>Ei myyntierän mukaisista tilauksista veloitamme 25 euron lisämaksun / avattu paketti.</p>
      <p> <span class="subtitle">Painoaineistot: </span>Painoaineistoihin tehtävistä mahdollisista korjauksista veloitamme 70 euroa / tunti.</p>
      <p> <span class="subtitle">Painotuotteet: </span>Pidätämme oikeuden 10% yli- ja alitoimituksiin, laskutus toimitusmäärän mukaan.</p>
      <p> <span class="subtitle">Huomautukset: </span>Mahdolliset huomautukset on tehtävä 6 päivää tavaran saapumisesta.</p>
    </div>
    <div class="col-5">
      <p> <span class="subtitle">Hinnat: </span>Kaikki hinnat ovat verottomia. Myynti tapahtuu verottomana EU:n yhteisömyyntinä</p>
      <p> <span class="subtitle">
        Palautukset ja hyvitykset: </span> Hyvitämme ainoastaan etukäteen myyjän kanssa sovituista palautuksista täydet, avaamattomat, 
        edelleen myyntikelpoiset pakkaukset. Varastotuotteilla palautusoikeus 3 kuukautta, tilaustuotteilla ei palautusoikeutta; 
        palautuksen minimiarvo 300 EUR. Palautuksista veloitamme rahtikulut.
      </p>
      <p> <span class="subtitle">
        Force Majeure-ehto: </span> Kaikissa tarjouksissa, myynnissä ja toimitusehdoissa otetaan huomioon Force Majeure ja siitä säädetyt lainkohdat. 
        Myyjä ei ole velvollinen täyttämään sopimusta, jos luonnoneste, tulipalo, konevaurio tai siihen verrattava häiriö, lakko, työsulku, sota, 
        liikekannallepano, devalvointi, vienti- tai tuontikielto, kuljetusvälineiden puute, valmistuksen lopettaminen, liikennehäiriö tai muu yrityksestämme 
        riippumaton tapahtuma estää tavaran tai sen osan toimittamisen. Myös silloin, kun sopimuksen täyttäminen edellyttäisi uhrauksia, 
        jotka ovat kohtuuttomia verrattuna ostajalle siitä koituvaan etuun, myyjä ei ole velvollinen täyttämään sopimusta. 
        Myyjä ei ole velvollinen korvaamaan ostajalle ylivoimaisen esteen aiheuttamasta sopimuksen täyttämättä jättämisestä aiheutunutta vahinkoa 
        ja hän voi myös tällöin purkaa sopimuksen tai siirtää sen täyttämisen myöhempään ajankohtaan, eikä ole vastuussa tästä menettelystä asiakkaalle 
        aiheutuvista ylimääräisistä kuluista.
      </p>
    </div>
  </div>
</ng-template>





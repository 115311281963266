import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from './../../../environments/environment';
@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit {
  currentLang: string;
  isIntermarcheOrCarrefourBE = environment.subCustomer ==='carrefour' || environment.subCustomer ==='intermarche'; 
  isCustomerPortal1210Kesko = environment.bolag === '1210' && !environment.sales && environment.mainCountry === 'fi';
  isCoopPortal = environment.mainCustomer === 'coop';
  constructor(public translate: TranslateService) {}

  ngOnInit() {
    
  }
}

import { Component, OnInit } from '@angular/core';
import { SelectedCustomerService, Customer } from 'gung-standard';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  selectedCustomer: Customer;
  sales: boolean;
  currentLang = this.translate.currentLang;
  isCardPaymentCustomer = false;
  isFRCustomerPortalPharma =
    !environment.sales && environment.mainCountry === 'fr' && environment.mainCustomer === 'Pharmacies';
  isIntermarcheOrCarrefour = environment.subCustomer === 'intermarche' || environment.subCustomer === 'carrefour';
  public isFRCustomerPortal1600General =
    !environment.sales &&
    environment.mainCountry === 'fr' &&
    environment.bolag === '1600' &&
    environment.mainCustomer === 'General';
  public isCustNlSuperunie1600 = !environment.sales && environment.bolag === '1600' && environment.mainCountry === 'nl' && environment.mainCustomer === 'spar';

  constructor(private selectedCustomerService: SelectedCustomerService, protected translate: TranslateService) {
    this.sales = environment.sales;
  }

  ngOnInit() {
    this.selectedCustomerService.getSelectedCustomer().subscribe(cust => {
      this.selectedCustomer = cust;

      if (!!this.selectedCustomer && this.selectedCustomer.extra && !!this.selectedCustomer.extra.kus) {
        this.isCardPaymentCustomer = this.selectedCustomer.extra.kus.betkod === '99';
      }
    });
  }
}

<div class="container-fluid">
  <h1 [innerHTML]="'SELECT_CATEGORY' | translate"></h1>
  <div class="row" [class.customer]="!isSales">
    <ng-container
      *ngIf="
        !(isFRCustomerPortalPharma || isFRCustomerPortal1600General) &&
        !isNOCustomerPortal &&
        !isSECustomerPortal &&
        !isFICustomerPortal &&
        !isFRCustomerPortal1600GeneralIntermarche &&
        !isFRCustomerPortal1600GeneralCarrefour &&
        !isPortalManagersCh &&
        !isCustomerPortal1710En &&
        !isCustNlSuperunie1600
      "
    >
      <ng-container *ngTemplateOutlet="MUSKETEERS_SELECTION"></ng-container>
      <ng-container *ngTemplateOutlet="GRAVITY_SCOOP_BINS"></ng-container>
      <ng-container *ngTemplateOutlet="SHELF_MANAGEMENT_AUTOMATIC"></ng-container>
      <ng-container *ngTemplateOutlet="SHELF_MANAGEMENT_MANUAL"></ng-container>
      <ng-container *ngTemplateOutlet="FRESH_PRODUCE_SOLUTIONS_PRESENTOIRS_PANIERS"></ng-container>
      <ng-container *ngTemplateOutlet="PRICE_COMMUNICATION"></ng-container>
      <ng-container *ngTemplateOutlet="VIEW_ALL_PRODUCTS"></ng-container>
    </ng-container>

    <ng-container *ngIf="isFRCustomerPortalPharma">
      <ng-container *ngTemplateOutlet="PRICE_COMMUNICATION_PHARMA"></ng-container>
      <ng-container *ngTemplateOutlet="STORE_COMMUNICATION_BRANDING_PHARMA"></ng-container>
      <ng-container *ngTemplateOutlet="SHELF_MANAGEMENT_MANUAL_PHARMA"></ng-container>
      <ng-container *ngTemplateOutlet="PRESENTOIRS_PANIERS_PHARMA"></ng-container>
      <!--
      <ng-container *ngTemplateOutlet="PROTECTION_SAFETY_EQUIPMENT"></ng-container>
      -->
      <ng-container *ngTemplateOutlet="COSME_LINE"></ng-container>
      <ng-container *ngTemplateOutlet="LIGHTING_POWER_INFRASTRUCTURE"></ng-container>
    </ng-container>

    <ng-container *ngIf="isNOCustomerPortal || isSECustomerPortal || isFICustomerPortal">
      <ng-container *ngTemplateOutlet="SHELF_MANAGEMENT_MANUAL_CUSTOMER"></ng-container>
      <ng-container *ngTemplateOutlet="SHELF_MANAGEMENT_AUTOMATIC"></ng-container>
      <ng-container *ngTemplateOutlet="PRICE_COMMUNICATION"></ng-container>
      <ng-container *ngTemplateOutlet="STORE_COMMUNICATION_BRANDING_CUSTOMER"></ng-container>
      <ng-container *ngTemplateOutlet="DISPLAY_ACCESSORIES"></ng-container>
      <ng-container *ngTemplateOutlet="VIEW_ALL_PRODUCTS"></ng-container>
    </ng-container>

    <ng-container *ngIf="isFRCustomerPortal1600General || isFRCustomerPortal1600GeneralCarrefour">
      <!--
      <ng-container *ngTemplateOutlet="PRICE_COMMUNICATION"></ng-container>
      <ng-container *ngTemplateOutlet="SHELF_MANAGEMENT_MANUAL"></ng-container>
      <ng-container *ngTemplateOutlet="DISPLAY_ACCESSORIES"></ng-container>
      -->
      <ng-container *ngTemplateOutlet="PRICE"></ng-container>
      <ng-container *ngTemplateOutlet="DIVIDER"></ng-container>
    </ng-container>

    <ng-container *ngIf="isCustNlSuperunie1600">
      <ng-container *ngTemplateOutlet="PRICE_NL"></ng-container>
      <ng-container *ngTemplateOutlet="DIVIDER_NL"></ng-container>
    </ng-container>

    <ng-container *ngIf="isFRCustomerPortal1600GeneralIntermarche">
      <ng-container *ngTemplateOutlet="STORE_COMMUNICATION_BRANDING_INTERMARCHE"></ng-container>
      <ng-container *ngTemplateOutlet="DIVIDER_INTERMARCHE"></ng-container>
      <ng-container *ngTemplateOutlet="SHELF_MANAGEMENT_MANUAL_INTERMARCHE"></ng-container>
      <!-- <ng-container *ngTemplateOutlet="DISPLAY_ACCESSORIES_INTERMARCHE"></ng-container> -->
      <ng-container *ngTemplateOutlet="PORTE_ETIQUETTE"></ng-container>
    </ng-container>

    <ng-container *ngIf="isPortalManagersCh">
      <ng-container *ngTemplateOutlet="SHELF_MANAGEMENT_AUTOMATIC"></ng-container>
      <ng-container *ngTemplateOutlet="SHELF_MANAGEMENT_MANUAL"></ng-container>
      <ng-container *ngTemplateOutlet="FRESH_PRODUCE_SOLUTIONS_PRESENTOIRS_PANIERS"></ng-container>
      <ng-container *ngTemplateOutlet="PRICE_COMMUNICATION"></ng-container>
      <ng-container *ngTemplateOutlet="VIEW_ALL_PRODUCTS"></ng-container>
    </ng-container>

    <ng-container *ngIf="isCustomerPortal1710En">
      <ng-container *ngTemplateOutlet="PRICE_COMMUNICATION"></ng-container>
      <ng-container *ngTemplateOutlet="STORE_COMMUNICATION_BRANDING_CUSTOMER_1710"></ng-container>
      <ng-container *ngTemplateOutlet="IMPULSE_PROMOTION"></ng-container>
      <ng-container *ngTemplateOutlet="VIEW_ALL_PRODUCTS"></ng-container>
    </ng-container>

    <ng-template #MUSKETEERS_SELECTION>
      <div class="col-12 col-md-6 col-xl-4 mb-4">
        <a
          routerLink="/products"
          class="category-image"
          [queryParams]="{ filters: 'MOUSQUETAIRES_SELECTION__:__MOUSQUETAIRES_FILTER_SELECTED' }"
        >
          <img class="img-fluid" src="./assets/products_v2.jpg" alt="" />
          <div class="text-banner" translate>MUSKETEERS_SELECTION</div>
        </a>
      </div>
    </ng-template>

    <ng-template #GRAVITY_SCOOP_BINS>
      <div class="col-12 col-md-6 col-xl-4 mb-4">
        <a
          routerLink="/products"
          class="category-image"
          [queryParams]="{
            from: 'categories',
            filters: 'CATEGORY__:__' + this.getCategoryFilterId('GravityScoopBins')
          }"
        >
          <img class="img-fluid" src="https://cdn1.gung.io/fit-in/500x500/hldisplay/Concept Vrac.jpg" alt="" />
          <div class="text-banner" translate>GRAVITY_SCOOP_BINS</div>
        </a>
      </div>
    </ng-template>
    <ng-template #COSME_LINE>
      <div class="col-12 col-md-6 col-xl-4 mb-4">
        <a
          routerLink="/products"
          class="category-image"
          [queryParams]="{
            from: 'categories',
            filters: 'CATEGORY__:__' + this.getCategoryFilterId('Cosmeline')
          }"
        >
          <img class="img-fluid" src="https://cdn1.gung.io/fit-in/500x500/hldisplay/Cosmeline2.jpg" alt="" />
          <div class="text-banner">CosmeLine™</div>
        </a>
      </div>
    </ng-template>
    <ng-template #SHELF_MANAGEMENT_AUTOMATIC>
      <div class="col-12 col-md-6 col-xl-4 mb-4" *ngIf="!isCustomerPortal1210Kesko">
        <a
          routerLink="/products"
          class="category-image"
          [queryParams]="{
            from: 'categories',
            filters: 'CATEGORY__:__' + this.getCategoryFilterId('ShelfManagementAutomatic')
          }"
        >
          <img class="img-fluid" src="https://cdn1.gung.io/fit-in/500x500/hldisplay/Facing automatique.jpg" alt="" />
          <div class="text-banner" translate>SHELF_MANAGEMENT_AUTOMATIC</div>
        </a>
      </div>
    </ng-template>
    <ng-template #SHELF_MANAGEMENT_MANUAL>
      <div class="col-12 col-md-6 col-xl-4 mb-4">
        <a
          routerLink="/products"
          class="category-image"
          [queryParams]="{
            from: 'categories',
            filters: 'CATEGORY__:__' + this.getCategoryFilterId('ShelfManagementManual')
          }"
        >
          <img
            class="img-fluid"
            src="https://cdn1.gung.io/fit-in/500x500/hldisplay/Separateurs & Accessoires.jpg"
            alt=""
          />
          <div class="text-banner" translate>SHELF_MANAGEMENT_MANUAL</div>
        </a>
      </div>
    </ng-template>
    <ng-template #FRESH_PRODUCE_SOLUTIONS_PRESENTOIRS_PANIERS>
      <div class="col-12 col-md-6 col-xl-4 mb-4">
        <a
          routerLink="/products"
          class="category-image"
          [queryParams]="{
            from: 'categories',
            filters: 'CATEGORY__:__' + this.getCategoryFilterId('FreshProduceSolutions;DisplayAccessories')
          }"
        >
          <img
            class="img-fluid"
            src="https://cdn1.gung.io/fit-in/500x500/hldisplay/Presentation Fruits & Legumes.jpg"
            alt=""
          />
          <div class="text-banner">
            <span *ngIf="isSales" translate> FRESH_PRODUCE_SOLUTIONS </span>
            <span *ngIf="!isSales" translate> PRESENTOIRS_PANIERS </span>
          </div>
        </a>
      </div>
    </ng-template>
    <ng-template #PRICE_COMMUNICATION>
      <div class="col-12 col-md-6 col-xl-4 mb-4">
        <a
          routerLink="/products"
          class="category-image"
          [queryParams]="{
            from: 'categories',
            filters: 'CATEGORY__:__' + this.getCategoryFilterId('PriceCommunication')
          }"
        >
          <img class="img-fluid" src="https://cdn1.gung.io/fit-in/500x500/hldisplay/Portes Etiquettes.jpg" alt="" />
          <div class="text-banner" translate>PRICE_COMMUNICATION</div>
        </a>
      </div>
    </ng-template>
    <ng-template #PORTE_ETIQUETTE>
      <div class="col-12 col-md-6 col-xl-4 mb-4">
        <a
          routerLink="/products"
          class="category-image"
          [queryParams]="{
            from: 'categories',
            filters: 'CATEGORY__:__' + this.getCategoryFilterId('PriceCommunication')
          }"
        >
          <img
            class="img-fluid"
            src="https://cdn1.gung.io/fit-in/500x500/hldisplay/category/intermarche/Portes_Etiquettes.jpg"
            alt=""
          />
          <div class="text-banner" translate>PORTE_ETIQUETTE</div>
        </a>
      </div>
    </ng-template>
    <ng-template #VIEW_ALL_PRODUCTS>
      <div class="col-12 col-md-6 col-xl-4 mb-4">
        <a routerLink="/products" class="category-image">
          <img
            class="img-fluid"
            src="https://cdn1.gung.io/fit-in/500x500/hldisplay/Voir tours les produits_1.jpg"
            alt=""
          />
          <div class="text-banner" translate>VIEW_ALL_PRODUCTS</div>
        </a>
      </div>
    </ng-template>

    <ng-template #PRICE_COMMUNICATION_PHARMA>
      <div class="col-12 col-md-6 col-xl-4 mb-4">
        <a
          routerLink="/products"
          class="category-image"
          [queryParams]="{
            from: 'categories',
            filters: 'CATEGORY__:__' + this.getCategoryFilterId('PriceCommunication')
          }"
        >
          <img class="img-fluid" src="https://cdn1.gung.io/fit-in/500x500/hldisplay/_design/ESL.jpg" alt="" />
          <div class="text-banner" translate>PRICE_COMMUNICATION</div>
        </a>
      </div>
    </ng-template>
    <ng-template #STORE_COMMUNICATION_BRANDING>
      <div class="col-12 col-md-6 col-xl-4 mb-4">
        <a
          routerLink="/products"
          class="category-image"
          [queryParams]="{
            from: 'categories',
            filters: 'CATEGORY__:__' + this.getCategoryFilterId('StoreCommunicationBranding')
          }"
        >
          <img class="img-fluid" src="./../../../assets/Communication_and_signaletique_Pharma.jpg" alt="" />
          <div class="text-banner" translate>STORE_COMMUNICATION_BRANDING</div>
        </a>
      </div>
    </ng-template>
    <ng-template #STORE_COMMUNICATION_BRANDING_PHARMA>
      <div class="col-12 col-md-6 col-xl-4 mb-4">
        <a
          routerLink="/products"
          class="category-image"
          [queryParams]="{
            from: 'categories',
            filters: 'CATEGORY__:__' + this.getCategoryFilterId('StoreCommunicationBranding')
          }"
        >
          <img class="img-fluid" src="https://cdn1.gung.io/fit-in/500x500/hldisplay/_design/Comms.png" alt="" />
          <div class="text-banner" translate>STORE_COMMUNICATION_BRANDING</div>
        </a>
      </div>
    </ng-template>
    <ng-template #SHELF_MANAGEMENT_MANUAL>
      <div class="col-12 col-md-6 col-xl-4 mb-4">
        <a
          routerLink="/products"
          class="category-image"
          [queryParams]="{
            from: 'categories',
            filters: 'CATEGORY__:__' + this.getCategoryFilterId('ShelfManagementManual')
          }"
        >
          <img class="img-fluid" src="./../../../assets/Separateurs_and_accessoires_Pharma.jpg" alt="" />
          <div class="text-banner" translate>SHELF_MANAGEMENT_MANUAL</div>
        </a>
      </div>
    </ng-template>
    <ng-template #SHELF_MANAGEMENT_MANUAL_PHARMA>
      <div class="col-12 col-md-6 col-xl-4 mb-4">
        <a
          routerLink="/products"
          class="category-image"
          [queryParams]="{
            from: 'categories',
            filters: 'CATEGORY__:__' + this.getCategoryFilterId('ShelfManagementManual')
          }"
        >
          <img class="img-fluid" src="https://cdn1.gung.io/fit-in/500x500/hldisplay/_design/Sepr.png" alt="" />
          <div class="text-banner" translate>SHELF_MANAGEMENT_MANUAL</div>
        </a>
      </div>
    </ng-template>

    <ng-template #PRESENTOIRS_PANIERS>
      <div class="col-12 col-md-6 col-xl-4 mb-4">
        <a
          routerLink="/products"
          class="category-image"
          [queryParams]="{
            from: 'categories',
            filters: 'CATEGORY__:__' + this.getCategoryFilterId('FreshProduceSolutions;DisplayAccessories')
          }"
        >
          <img class="img-fluid" src="./../../../assets/Presentoirs_and_paniers.png" alt="" />
          <div class="text-banner" translate>PRESENTOIRS_PANIERS</div>
        </a>
      </div>
    </ng-template>
    <ng-template #PRESENTOIRS_PANIERS_PHARMA>
      <div class="col-12 col-md-6 col-xl-4 mb-4">
        <a
          routerLink="/products"
          class="category-image"
          [queryParams]="{
            from: 'categories',
            filters: 'CATEGORY__:__' + this.getCategoryFilterId('FreshProduceSolutions;DisplayAccessories')
          }"
        >
          <img class="img-fluid" src="https://cdn1.gung.io/fit-in/500x500/hldisplay/_design/Pres.jpg" alt="" />
          <div class="text-banner" translate>PRESENTOIRS_PANIERS</div>
        </a>
      </div>
    </ng-template>
    <ng-template #PROTECTION_SAFETY_EQUIPMENT>
      <div class="col-12 col-md-6 col-xl-4 mb-4">
        <a
          routerLink="/products"
          class="category-image"
          [queryParams]="{
            from: 'categories',
            filters: 'CATEGORY__:__' + this.getCategoryFilterId('ProtectionSafetyEquipment')
          }"
        >
          <img class="img-fluid" src="./../../../assets/Protection_and_security.jpg" alt="" />
          <div class="text-banner" translate>PROTECTION_SAFETY_EQUIPMENT</div>
        </a>
      </div>
    </ng-template>
    <ng-template #LIGHTING_POWER_INFRASTRUCTURE>
      <div class="col-12 col-md-6 col-xl-4 mb-4">
        <a
          routerLink="/products"
          class="category-image"
          [queryParams]="{
            from: 'categories',
            filters: 'CATEGORY__:__' + this.getCategoryFilterId('LightingPowerInfrastructure')
          }"
        >
          <img class="img-fluid" src="https://cdn1.gung.io/fit-in/500x500/hldisplay/_design/Light.jpg" alt="" />
          <div class="text-banner" translate>LIGHTING_POWER_INFRASTRUCTURE</div>
        </a>
      </div>
    </ng-template>

    <ng-template #SHELF_MANAGEMENT_MANUAL_CUSTOMER>
      <div class="col-12 col-md-6 col-xl-4 mb-4">
        <a
          routerLink="/products"
          class="category-image"
          [queryParams]="{
            from: 'categories',
            filters: 'CATEGORY__:__' + this.getCategoryFilterId('ShelfManagementManual')
          }"
        >
          <img class="img-fluid" src="./../../../assets/Shelf_management_manual.jpg" alt="" />
          <div class="text-banner" translate>SHELF_MANAGEMENT_MANUAL</div>
        </a>
      </div>
    </ng-template>
    <ng-template #SHELF_MANAGEMENT_MANUAL_INTERMARCHE>
      <div class="col-12 col-md-6 col-xl-4 mb-4">
        <a
          routerLink="/products"
          class="category-image"
          [queryParams]="{
            from: 'categories',
            filters: 'CATEGORY__:__' + this.getCategoryFilterId('ShelfManagementManual')
          }"
        >
          <img
            class="img-fluid"
            src="https://cdn1.gung.io/fit-in/500x500/hldisplay/category/intermarche/Shelf_man.jpg"
            alt=""
          />
          <div class="text-banner" translate>SHELF_MANAGEMENT_MANUAL</div>
        </a>
      </div>
    </ng-template>
    <ng-template #STORE_COMMUNICATION_BRANDING_CUSTOMER>
      <div class="col-12 col-md-6 col-xl-4 mb-4">
        <a
          routerLink="/products"
          class="category-image"
          [queryParams]="{
            from: 'categories',
            filters: 'CATEGORY__:__' + this.getCategoryFilterId('StoreCommunicationBranding')
          }"
        >
          <img class="img-fluid" src="./../../../assets/Communication_and_signaletique.jpg" alt="" />
          <div class="text-banner" translate>STORE_COMMUNICATION_BRANDING</div>
        </a>
      </div>
    </ng-template>
    <ng-template #STORE_COMMUNICATION_BRANDING_INTERMARCHE>
      <div class="col-12 col-md-6 col-xl-4 mb-4">
        <a
          routerLink="/products"
          class="category-image"
          [queryParams]="{
            from: 'categories',
            filters: 'CATEGORY__:__' + this.getCategoryFilterId('StoreCommunicationBranding')
          }"
        >
          <img
            class="img-fluid"
            src="https://cdn1.gung.io/fit-in/500x500/hldisplay/category/intermarche/Store.jpg"
            alt=""
          />
          <div class="text-banner" translate>STORE_COMMUNICATION_BRANDING</div>
        </a>
      </div>
    </ng-template>
    <ng-template #DISPLAY_ACCESSORIES>
      <div class="col-12 col-md-6 col-xl-4 mb-4">
        <a
          routerLink="/products"
          class="category-image"
          [queryParams]="{
            from: 'categories',
            filters: 'CATEGORY__:__' + this.getCategoryFilterId('DisplayAccessories')
          }"
        >
          <img class="img-fluid" src="./../../../assets/Display_accessory.jpg" alt="" />
          <div class="text-banner" translate>DISPLAY_ACCESSORIES</div>
        </a>
      </div>
    </ng-template>
    <ng-template #DISPLAY_ACCESSORIES_INTERMARCHE>
      <div class="col-12 col-md-6 col-xl-4 mb-4">
        <a
          routerLink="/products"
          class="category-image"
          [queryParams]="{
            from: 'categories',
            filters: 'CATEGORY__:__' + this.getCategoryFilterId('DisplayAccessories')
          }"
        >
          <img
            class="img-fluid"
            src="https://cdn1.gung.io/fit-in/500x500/hldisplay/category/intermarche/Display_ass.jpg"
            alt=""
          />
          <div class="text-banner" translate>DISPLAY_ACCESSORIES</div>
        </a>
      </div>
    </ng-template>
    <ng-template #DIVIDER>
      <div class="col-12 col-md-6 col-xl-6 mb-4">
        <a
          routerLink="/products"
          class="category-image"
          [queryParams]="{
            from: 'categories',
            filters: 'CATEGORY__:__' + this.getCategoryFilterId('ShelfManagementManual')
          }"
        >
          <img class="img-fluid" src="https://cdn1.gung.io/fit-in/500x500/hldisplay/Divider.jpg" alt="" />
          <div class="text-banner" translate>SHELF_MANAGEMENT_MANUAL</div>
        </a>
      </div>
    </ng-template>
    <ng-template #DIVIDER_NL>
      <div class="col-12 col-md-6 col-xl-6 mb-4">
        <a
          routerLink="/products"
          class="category-image"
          [queryParams]="{
            from: 'categories',
            filters: 'CATEGORY__:__' + this.getCategoryFilterId('ShelfManagementManual')
          }"
        >
          <img class="img-fluid" src="https://cdn1.gung.io/fit-in/500x500/hldisplay/nl_header_2_small.png" alt="" />
          <div class="text-banner" translate>GENERAL_SHELF_MATERIALS</div>
        </a>
      </div>
    </ng-template>
    <ng-template #DIVIDER_INTERMARCHE>
      <div class="col-12 col-md-6 col-xl-4 mb-4">
        <a
          routerLink="/products"
          class="category-image"
          [queryParams]="{
            from: 'categories',
            filters: 'CATEGORY__:__' + this.getCategoryFilterId('ShelfManagementAutomatic')
          }"
        >
          <img
            class="img-fluid"
            src="https://cdn1.gung.io/fit-in/500x500/hldisplay/category/intermarche/Divider.png"
            alt=""
          />
          <div class="text-banner" translate>SHELF_MANAGEMENT_AUTOMATIC</div>
        </a>
      </div>
    </ng-template>
    <ng-template #PRICE>
      <div class="col-12 col-md-6 col-xl-6 mb-4">
        <a
          routerLink="/products"
          class="category-image"
          [queryParams]="{
            from: 'categories',
            filters: 'CATEGORY__:__' + this.getCategoryFilterId('PriceCommunication')
          }"
        >
          <img class="img-fluid" src="https://cdn1.gung.io/fit-in/500x500/hldisplay/Price.jpg" alt="" />
          <div class="text-banner" translate>PRICE_COMMUNICATION</div>
        </a>
      </div>
    </ng-template>
    <ng-template #PRICE_NL>
      <div class="col-12 col-md-6 col-xl-6 mb-4">
        <a
          routerLink="/products"
          class="category-image"
          [queryParams]="{
            from: 'categories',
            filters: 'CATEGORY__:__' + this.getCategoryFilterId('PriceCommunication')
          }"
        >
          <img class="img-fluid" src="https://cdn1.gung.io/fit-in/500x500/hldisplay/nl_header_7_small.jpg" alt="" />
          <div class="text-banner" translate>PRIJSRAILS</div>
        </a>
      </div>
    </ng-template>
    <ng-template #STORE_COMMUNICATION_BRANDING_CUSTOMER_1710>
      <div class="col-12 col-md-6 col-xl-4 mb-4">
        <a
          routerLink="/products"
          class="category-image"
          [queryParams]="{
            from: 'categories',
            filters: 'CATEGORY__:__' + this.getCategoryFilterId('StoreCommunicationBranding')
          }"
        >
          <img
            class="img-fluid"
            src="https://cdn1.gung.io/fit-in/500x500/hldisplay/category/currys-customer-1710/Store_Communication.jpg"
            alt=""
          />
          <div class="text-banner" translate>STORE_COMMUNICATION_BRANDING</div>
        </a>
      </div>
    </ng-template>
    <ng-template #IMPULSE_PROMOTION>
      <div class="col-12 col-md-6 col-xl-4 mb-4">
        <a
          routerLink="/products"
          class="category-image"
          [queryParams]="{
            from: 'categories',
            filters: 'CATEGORY__:__' + this.getCategoryFilterId('ImpulsePromotion')
          }"
        >
          <img
            class="img-fluid"
            src="https://cdn1.gung.io/fit-in/500x500/hldisplay/category/currys-customer-1710/Impulse_Promotion_2.jpg"
            alt=""
          />
          <div class="text-banner" translate>IMPULSE_PROMOTION</div>
        </a>
      </div>
    </ng-template>
  </div>
</div>

<div class="row">
  <div class="col-12 d-flex justify-content-center align-items-center">
    <div class="spacer"></div>
  </div>
</div>

<span *ngIf="currentLang === 'fr'">
  <div class="row">
    <div class="col-12">
      <div class="extra-text-header">Créer une meilleure expérience d’achat</div>
    </div>
    <div class="col-8 offset-2">
      <div class="extra-text" *ngIf="isFRCustomerPortalPharma">
        Nous accompagnons les pharmaciens depuis plus de 20 ans pour créer des officines plus soucieuses de
        l’environnement qui renforcent l’expérience d’achat des clients.
      </div>
      <div class="extra-text" *ngIf="!isFRCustomerPortalPharma">
        Nous aidons les distributeurs et les marques à travers le monde à créer des environnements magasin attractifs et
        rentables qui renforcent l'expérience d'achat des consommateurs.
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="usp-text-header">Ce qui nous rend unique ?</div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-3 mb-4">
      <div class="usp-spacer"></div>
      <div class="usp-text-header-2">La passion de la distribution</div>
      <div class="usp-text">
        Nos solutions sont installées dans plus de 295 000 points de vente à travers le monde et nous sommes fiers
        d’être le partenaire privilégié des plus grandes marques et des plus grands distributeurs.
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3 mb-4">
      <div class="usp-spacer"></div>
      <div class="usp-text-header-2">Un état d’esprit</div>
      <div class="usp-text">
        Nous développons des produits et des services de qualité et nous tenons à toujours agir en accord avec des
        valeurs de travail et des valeurs sociales équitables.
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3 mb-4">
      <div class="usp-spacer"></div>
      <div class="usp-text-header-2">De fortes racines familiales</div>
      <div class="usp-text">
        L'engagement de notre famille de collaborateurs incarne l'esprit entrepreneurial qui a fondé notre groupe.
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3 mb-4">
      <div class="usp-spacer"></div>
      <div class="usp-text-header-2">Leadership industriel</div>
      <div class="usp-text">
        Grâce à une présence sur plus de 50 marchés et avec des capacités de production dans quatre pays, nous pouvons
        garantir un processus rapide et flexible où que vous soyez.
      </div>
    </div>
  </div>
</span>

<span *ngIf="currentLang === 'en'">
  <div class="row">
    <div class="col-12">
      <div class="extra-text-header">Creating the better shopping experience</div>
    </div>
    <div class="col-8 offset-2">
      <div class="extra-text">
        We help retailers and brands around the world to create attractive and profitable in-store environments that
        strengthen the consumer's shopping experience.
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-3 mb-4">
      <div class="usp-spacer"></div>
      <div class="usp-text-header-2">Grow sales</div>
      <div class="usp-text">
        More than 70% of shopping decisions are made in stores. Improving product presentation and reducing real or
        perceived out-of-stock improves sales.
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3 mb-4">
      <div class="usp-spacer"></div>
      <div class="usp-text-header-2">Inspire shoppers</div>
      <div class="usp-text">
        Our experience from working with the world’s leading retailers and brands has taught us how to create solutions
        that drive differentiation, inspire shoppers and make their shopping trip worthwhile.
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3 mb-4">
      <div class="usp-spacer"></div>
      <div class="usp-text-header-2">Drive automation</div>
      <div class="usp-text">
        Our merchandising systems free up staff by automating repetitive tasks so store personnel can spend time where
        it really matters – engaging with shoppers.
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3 mb-4">
      <div class="usp-spacer"></div>
      <div class="usp-text-header-2">Reduce waste</div>
      <div class="usp-text">
        Waste is both costly and, to a large degree, avoidable. Efforts to reduce food and packaging waste as well as
        the usage of virgin plastic all carry great societal and financial benefits.
      </div>
    </div>
  </div>
</span>

<span *ngIf="currentLang === 'nl' && !isCustNlSuperunie1600">
  <div class="row">
    <div class="col-12">
      <div class="extra-text-header">De betere winkelervaring creëren</div>
    </div>
    <div class="col-8 offset-2">
      <div class="extra-text">
        We helpen retailers en merken over de hele wereld om aantrekkelijke en winstgevende winkelomgevingen te creëren
        die de winkelervaring van de consument versterken.
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-3 mb-4">
      <div class="usp-spacer"></div>
      <div class="usp-text-header-2">Laat de verkoop groeien</div>
      <div class="usp-text">
        Meer dan 70% van de winkelbeslissingen worden op de winkelvloer genomen. Het verbeteren van de
        productpresentatie en het verminderen van echte of vermeende niet-voorraad verbetert de verkoop.
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3 mb-4">
      <div class="usp-spacer"></div>
      <div class="usp-text-header-2">Inspireer de consument</div>
      <div class="usp-text">
        Onze ervaring door samen te werken met 's werelds toonaangevende retailers en merken heeft ons geleerd hoe we
        oplossingen kunnen creëren die differentiatie stimuleren, consumenten inspireren en hun winkel uitstap de moeite
        waard maken.
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3 mb-4">
      <div class="usp-spacer"></div>
      <div class="usp-text-header-2">Automatisering aandrijven</div>
      <div class="usp-text">
        Onze merchandisingsystemen maken personeel vrij door repetitieve taken te automatiseren, zodat het
        winkelpersoneel tijd kan besteden aan waar het echt toe doet: interactie met het winkelend publiek.
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3 mb-4">
      <div class="usp-spacer"></div>
      <div class="usp-text-header-2">Afval verminderen</div>
      <div class="usp-text">
        Verspilling is kostelijk en grotendeels vermijdbaar. Pogingen om voedsel- en verpakkingsafval te verminderen
        alsook het gebruik van nieuwe plastic hebben allemaal grote maatschappelijke en financiële voordelen.
      </div>
    </div>
  </div>
</span>

<span *ngIf="currentLang === 'nl' && isCustNlSuperunie1600">
  <div class="row">
    <div class="col-12">
      <div class="extra-text-header">Het creëren van een betere winkelervaring</div>
    </div>
    <div class="col-8 offset-2">
      <div class="extra-text">
        Wij helpen retailers en merken over de hele wereld om aantrekkelijke en winstgevende winkelomgevingen te creëren
        die de winkelervaring van consumenten versterken.
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-3 mb-4">
      <div class="usp-spacer"></div>
      <div class="usp-text-header-2">Groei van verkoop</div>
      <div class="usp-text">
        Meer dan 70% van de aankoopbeslissingen worden in de winkel genomen. Het verbeteren van de productpresentatie en
        het verminderen van daadwerkelijke of waargenomen tekorten verbetert de verkoop.
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3 mb-4">
      <div class="usp-spacer"></div>
      <div class="usp-text-header-2">Inspireer consumenten</div>
      <div class="usp-text">
        Onze ervaring van het werken met 's werelds toonaangevende retailers en merken heeft ons geleerd hoe oplossingen
        te creëren die differentiatie stimuleren, consumenten inspireren en hun winkeltrip de moeite waard maken.
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3 mb-4">
      <div class="usp-spacer"></div>
      <div class="usp-text-header-2">Stimuleer automatisering</div>
      <div class="usp-text">
        Onze merchandising-systemen bevrijden personeel door repetitieve taken te automatiseren, zodat winkelpersoneel
        tijd kan besteden waar het echt toe doet - interactie met shoppers.
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3 mb-4">
      <div class="usp-spacer"></div>
      <div class="usp-text-header-2">Verminder verspilling</div>
      <div class="usp-text">
        Verspilling is zowel kostbaar als, in grote mate, vermijdbaar. Inspanningen om voedsel- en
        verpakkingsverspilling te verminderen, evenals het gebruik van nieuw plastic, hebben aanzienlijke
        maatschappelijke en financiële voordelen.
      </div>
    </div>
  </div>
</span>

<span *ngIf="currentLang === 'no'">
  <div class="row">
    <div class="col-12">
      <div class="extra-text-header">Skape en bedre handleopplevelse</div>
    </div>
    <div class="col-8 offset-2">
      <div class="extra-text">
        Vi hjelper butikker og merkevareleverandører rundt om i verden med å skape attraktive og lønnsomme butikkmiljøer
        som gir konsumentene en bedre handleopplevelse.
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-3 mb-4">
      <div class="usp-spacer"></div>
      <div class="usp-text-header-2">Øke salget</div>
      <div class="usp-text">
        Mer enn 70% av kjøpsbeslutningene blir tatt i butikken. Derfor vil bedre produktpresentasjon ved å alltid ha
        varer i front på hyllene gi økt salg.
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3 mb-4">
      <div class="usp-spacer"></div>
      <div class="usp-text-header-2">Inspirere de handlende</div>
      <div class="usp-text">
        Våre erfaringer fra å jobbe med verdens ledende butikkjeder og merkevareleverandører har lært oss at det er
        svært viktig å skape løsninger som gjør at butikken skiller seg ut og inspirerer shopperen slik at handleturen
        er verdt besøket.
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3 mb-4">
      <div class="usp-spacer"></div>
      <div class="usp-text-header-2">Drive automatisering</div>
      <div class="usp-text">
        Våre eksponeringsløsninger frigjør tid for de ansatte ved å automatisere gjentagende oppgaver slik at
        betjeningen kan bruke tiden sin der det virkelig utgjør en forskjell - til dialog med kundene i butikken.
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3 mb-4">
      <div class="usp-spacer"></div>
      <div class="usp-text-header-2">Redusere svinn</div>
      <div class="usp-text">
        Svinn er kostbart og kan i mange tilfeller unngås. Tiltak for å redusere matvaresvinn, redusere avfall fra
        forpakninger og bruk av jomfruelig plast er bærekraftig og kan gi økonomiske gevinster.
      </div>
    </div>
  </div>
</span>

<span *ngIf="currentLang === 'fi'">
  <div class="row">
    <div class="col-12">
      <div class="extra-text-header">Parempaa ostokokemusta luomassa</div>
    </div>
    <div class="col-8 offset-2">
      <div class="extra-text">
        Autamme vähittäiskauppaa ja brändejä ympäri maailmaa luomaan houkuttelevia ja kannattavia myymäläratkaisuja
        parantamaan kuluttajien ostokokemusta.
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-3 mb-4">
      <div class="usp-spacer"></div>
      <div class="usp-text-header-2">Kasvata myyntiä</div>
      <div class="usp-text">
        Yli 70 % ostopäätöksistä tehdään myymälässä. Parantamalla tuotteiden esillepanoa sekä näkyvyyttä hyllyssä myynti
        on tehokkaampaa.
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3 mb-4">
      <div class="usp-spacer"></div>
      <div class="usp-text-header-2">Inspiroi kuluttajia</div>
      <div class="usp-text">
        Kokemuksemme maailman johtavien vähittäiskauppojen ja brändien kanssa työskentelystä on opettanut meidät luomaan
        ratkaisuja, jotka auttavat erottautumaan, inspiroivat kuluttajia ja tekevät ostoskäynnistä käynnin arvoisen.
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3 mb-4">
      <div class="usp-spacer"></div>
      <div class="usp-text-header-2">Säästä aikaa automatisoinnilla</div>
      <div class="usp-text">
        Hyllyautomaatio auttaa vähentämään toistuviin tehtäviin kuluvaa aikaa. Myymälän henkilökunta voi siis tehdä
        työtä siellä, missä sillä on todella merkitystä – asioimalla asiakkaiden kanssa.
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3 mb-4">
      <div class="usp-spacer"></div>
      <div class="usp-text-header-2">Vähennä jätettä</div>
      <div class="usp-text">
        Hävikki on kallista ja suuressa määrin myös vältettävissä. Ruokahävikin, pakkausjätteen sekä raakamuovin käytön
        vähentämisellä on merkittäviä yhteiskunnallisia ja taloudellisia etuja.
      </div>
    </div>
  </div>
</span>

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-category-listing',
  templateUrl: './category-listing.component.html',
  styleUrls: ['./category-listing.component.scss']
})
export class CategoryListingComponent implements OnInit {
  isSales = environment.sales;
  isFRCustomerPortalPharma =
    !environment.sales && environment.mainCountry === 'fr' && environment.mainCustomer === 'Pharmacies';
  isNOCustomerPortal = !environment.sales && environment.mainCountry === 'no';
  isSECustomerPortal = !environment.sales && environment.mainCountry === 'se';
  isFICustomerPortal = !environment.sales && environment.mainCountry === 'fi';
  isFRCustomerPortal1600General =
    !environment.sales &&
    environment.mainCountry === 'fr' &&
    environment.bolag === '1600' &&
    environment.mainCustomer === 'General' &&
    !environment.subCustomer;
  isFRCustomerPortal1600GeneralIntermarche = 
    !environment.sales &&
    environment.mainCountry === 'fr' &&
    environment.bolag === '1600' &&
    environment.mainCustomer === 'General' &&
    environment.subCustomer === 'intermarche';
  isFRCustomerPortal1600GeneralCarrefour = 
    !environment.sales &&
    environment.mainCountry === 'fr' &&
    environment.bolag === '1600' &&
    environment.mainCustomer === 'General' &&
    environment.subCustomer === 'carrefour';
  isPortalManagersCh = environment.managers && environment.mainCountry === 'ch';
  public isCustomerPortal1710En = !environment.sales && environment.bolag === '1710' && environment.mainCountry === 'en';
  public isCustomerPortal1210Kesko = environment.bolag === '1210' && !environment.sales && environment.mainCountry === 'fi';
  public isCustNlSuperunie1600 = !environment.sales && environment.bolag === '1600' && environment.mainCountry === 'nl' && environment.mainCustomer === 'spar';
  currentLang: string;

  constructor(protected translate: TranslateService) {}

  ngOnInit() {
    if (!['en', 'fr', 'no', 'fi'].includes(this.translate.currentLang) && !this.isCustNlSuperunie1600) {
      this.currentLang = 'en';
    } else {
      this.currentLang = this.translate.currentLang;
    }
  }

  getCategoryFilterId(categoryId: string): string {
    if (this.isSales) {
      switch (categoryId) {
        case 'GravityScoopBins':
          return 'Concept Vrac';
        case 'ShelfManagementAutomatic':
          return 'Facing automatique';
        case 'ShelfManagementManual':
          return 'Séparateurs & Accessoires';
        case 'FreshProduceSolutions':
          return 'Présentation Fruits & Légumes';
        case 'PriceCommunication':
          return 'Porte-étiquettes';
        case 'ProtectionSafetyEquipment':
          return 'Protection et sécurité';
      }
    }

    // the category products filter are built based on item.extra.hlPimProduct.ProductExternalCategory (categoryId)
    // and the name of the filters (CategoryName) is fecth from item.extra.i18n[selectedLanguage].ProductExternalCategory[productExternalCategory]
    // HOWEVER for currentLanguage FR there are some filters whose ids are different but the name are the same
    // they are the next ones
    // In order to not have several filters with names duplicated in product list we need to merge them (ids ; separated)

    // Name:  Digital & Mise en lumière
    // Ids: LightingPowerInfrastructure and DigitalScreensAccessories
    // if (this.currentLang === 'fr' && ['LightingPowerInfrastructure', 'DigitalScreensAccessories'].includes(categoryId)) {
    //   return 'LightingPowerInfrastructure;DigitalScreensAccessories';
    // }

    // // Name: Présentoirs & Paniers
    // // Ids: DisplayAccessories and FreshProduceSolutions
    // if (this.currentLang === 'fr' && ['FreshProduceSolutions', 'DisplayAccessories'].includes(categoryId)) {
    //   return 'DisplayAccessories;FreshProduceSolutions';
    // }

    return categoryId;
  }
}

<div class="">
  <table class="table table-bordered">
    <thead>
      <tr>
        <th width="1%" scope="col"></th>
        <th scope="col" translate>ITEM_NUMBER</th>
        <th scope="col" translate>NAME</th>
        <th scope="col" translate>PRICE</th>
        <th scope="col" translate>COLOR_SIZE</th>
        <th scope="col" translate>BOX</th>
        <th scope="col" translate>AVAILABILITY</th>
        <th width="150px" scope="col"></th>
      </tr>
    </thead>
    <tbody>
      @for (product of mappedData; track product.id; let idx = $index, e = $even) {
      <tr>
        <th scope="row">
          @if (!!product.extra.images && !!product.extra.images[0]) {
          <img loading="lazy" class="img-fluid" src="https://d27ahaa1qqlr90.cloudfront.net/fit-in/250x250/filters:fill(white):background_color(white):etag({{
                  product.extra.images[0].s3ETag
                }}):format(jpeg)/{{ product.extra.images[0].s3Uri }}" alt="" />
          }
        </th>
        <td>
          {{ product.id }}
          @if(!!currentCustomer && currentCustomer.extra.kuarMapped?.[product.id]?.artbeskr2) {
            <span class="d-block">({{ currentCustomer.extra.kuarMapped[product.id]?.artbeskr2 }})</span>
          }
        </td>
        <td>
          <a class="product-link" routerLink="/product/{{ product.id }}">
            @if (product.extra.i18n[currentLang].artbeskrspec) {
            {{ product.extra.i18n[currentLang].artbeskrspec }}
            } @else if (product.extra.ar.artbeskrspec) {
            {{ product.extra.ar.artbeskrspec }}
            } @else {
            {{ product.extra.ar.artbeskr }}
            }
          </a>
        </td>
        <td>
          <b>
            @if (product.price) {
            <span>
              <hl-display-price [price]="product.price" type="NET" />
              @if(displayDiscountFromPriceList && product.price?.backendPrice?.levels?.[0]?.extra?.prl?.proc1 > 0){
              <span class="text-disc">(-{{ product.price.backendPrice.levels[0].extra.prl.proc1 }}%)</span>
              }
            </span>
            } @else {
            <hl-display-price [price]="product.price" displayOnlyPrice="true" type="STAFFED" />
            }

          </b>
          <span> {{ getAssortmentType(product.extra) }}</span>
        </td>
        <td>
          {{ product.extra.hlPimItem?.ItemColourName?.join(',') }}
          @if (product.extra.hlPimItem?.ItemColourName?.join(',') != '' && productSize[product.id] != '') {
          <br />
          }
          {{ productSize[product.id] }}
        </td>
        <td>
          {{ product.extra.stepAmount }}
        </td>
        <td>
          <div class="d-flex align-items-center">

            <!-- {{product.extra.availabilities | json}} -->

            <app-hl-display-availability *ngIf="product?.extra?.availabilities"
              [availability]="product.extra.availabilities[stockId]">
            </app-hl-display-availability>
            <!-- @if (
                (
                  product.extra.availabilities['0'] ||
                  product.extra.availabilities['*'] ||
                  (stockId && product.extra.availabilities[stockId])
                ).currentAvailability > 0
              ) {
                <i class="fa-solid fa-circle fa-1x text-success"></i>
                <span class="ml-2" translate>AVAILABLE</span>
              } @else {
                <i class="fa-solid fa-circle fa-1x text-warning"></i>
                <span class="ml-2" translate>MADE_TO_ORDER</span>
              } -->
          </div>
        </td>
        <td>
          <app-hl-display-buy-btn class="w-100" [stepAmount]="product.extra.stepAmount"
            [minimumOrderQuantity]="getMinimumOrderQuantity(product.extra)" [id]="product.id" />
          <a class="product-link" routerLink="/product/{{ product.id }}">
            <button type="button" class="btn btn-outline-primary mt-2 w-100" style="white-space: nowrap" translate>
              MORE_INFO
            </button>
          </a>
        </td>
      </tr>
      } @empty {
      <tr>
        <td colspan="9" class="text-center"><i class="fa fa-spinner fa-spin fa-7x"></i></td>
      </tr>
      }
    </tbody>
  </table>
</div>

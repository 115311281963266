import { Component, OnInit } from '@angular/core';
import { Subscription, first, forkJoin, map, of, switchMap, timer } from 'rxjs';
import {
  BaseViewConfigService,
  GungModalService,
  NavbarcartComponent,
  NavbarCartService,
  SaveCartModalConfigService,
  SelectedCustomerService
} from 'gung-standard';
import { environment } from '../../../environments/environment';
import { HlDisplayDummyArticleService } from '../../services/hl-display-dummy-article.service';

@Component({
  selector: 'app-hl-display-navbar-cart',
  templateUrl: './hl-display-navbar-cart.component.html',
  styleUrls: ['./hl-display-navbar-cart.component.scss']
})
export class HlDisplayNavbarCartComponent extends NavbarcartComponent implements OnInit {
  private orderLineItemNotInJeevesProductId = '155035';

  public cartContentsTotalValue = 0;
  public highlight = false;
  private blinkSubscription: Subscription;
  isPortalNo = environment.mainCountry === 'no';
  public currencyCode;

  public isManagersNisa = environment.managers && environment.mainCountry === 'en';

  public isCurrysPortal =
    environment.bolag === '1710' &&
    !environment.sales &&
    !environment.managers &&
    environment.mainCountry === 'en' &&
    environment.mainCustomer === '';

  constructor(
    protected navbarCartService: NavbarCartService,
    saveCartModalConfigService: SaveCartModalConfigService,
    protected gungModalService: GungModalService,
    public dummyArticleService: HlDisplayDummyArticleService,
    protected baseViewConfigService: BaseViewConfigService,
    private selectedCustomerService: SelectedCustomerService
  ) {
    super(navbarCartService, saveCartModalConfigService, gungModalService, baseViewConfigService);
  }

  ngOnInit() {
    const sub = this.navbarCartService.getContents().subscribe(contents => {
      this.cartContents = contents;
      this.cartTotalRows = contents.rows.length;
      this.cartTotalItems = contents.rows.map(row => row.cartRow.qty).reduce((acc, curr) => acc + curr, 0);

      // standard way to implement the totalValue (from navBar cart service)
      this.cartContentsTotalValue = this.cartContents.rows
        .filter(row => row.product.id !== this.orderLineItemNotInJeevesProductId)
        .map(row => row.price.cartRowUnitPriceInclRowDiscount.value * row.cartRow.qty)
        .reduce((acc, curr) => acc + curr, 0);

      // add values from order items not in jeeves
      this.cartContents.rows
        .filter(row => row.product.id === this.orderLineItemNotInJeevesProductId)
        .forEach(row => {
          this.cartContentsTotalValue += (row.cartRow.extra.orp.vb_pris || 0) * (row.cartRow.extra.orp.ordantal || 0);
        });

      this.selectedCustomerService
        .getSelectedCustomer()
        .pipe(first())
        .subscribe(selectedCustomer => {
          if (!!selectedCustomer.extra && selectedCustomer.extra.kus) {
            this.currencyCode = selectedCustomer.extra.kus.valkod || contents.currency;
          }

          this.currencyCode = contents.currency;
        });
    });

    this.subs.push(sub);

    this.subs.push(
      of()
        .pipe(
          switchMap(() =>
            forkJoin({
              newLength: this.navbarCartService.getContents().pipe(map(ncm => ncm.rows.length)),
              oldLength: of(this.cartContents.rows.length)
            })
          )
        )
        .subscribe(({ newLength, oldLength }) => this.checkHighlight(newLength, oldLength))
    );
  }

  private checkHighlight(newLength: number, oldLength: number) {
    if (newLength > oldLength) {
      this.highlight = true;
      if (this.blinkSubscription) {
        this.blinkSubscription.unsubscribe();
      }
      this.blinkSubscription = timer(3500).subscribe(() => (this.highlight = false));
    }
  }
}

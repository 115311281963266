import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'gung-standard';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-index-page',
  templateUrl: './index-page.component.html',
  styleUrls: ['./index-page.component.scss']
})
export class IndexPageComponent implements OnInit {
  private onCategoriesPage: boolean;
  public onHomeUser: boolean;
  private signedIn = false;
  isFRCustomerPortalPharma =
    !environment.sales && environment.mainCountry === 'fr' && environment.mainCustomer === 'Pharmacies';
  public isFRCustomerPortal1600General =
    !environment.sales &&
    environment.mainCountry === 'fr' &&
    environment.bolag === '1600' &&
    environment.mainCustomer === 'General';
  isUkBolag = environment.bolag === '1710';
  isSales = environment.sales;
  onLogin = false;
  customerPortalSignedOut = false;

  constructor(private router: Router, authService: AuthService) {
    authService.getIsAuthenticated().subscribe(authed => {
      this.signedIn = authed;
      this.customerPortalSignedOut = !this.signedIn && !this.isSales;
    });

    this.onHomeUser = this.router.url === '/home-user';
    this.onCategoriesPage = this.router.url === '/categories';
    this.onLogin = this.router.url.includes('/login');
    this.router.events.subscribe(event => {
      if (this.router.url === '/categories') {
        this.onCategoriesPage = true;
      } else {
        this.onCategoriesPage = false;
      }

      this.onLogin = this.router.url.includes('/login');
      this.onHomeUser = this.router.url === '/home-user';
    });
  }

  public grayBg(): boolean {
    return !this.onLogin;
  }

  ngOnInit() { }

  public isOnCategoriesPage(): boolean {
    return this.onCategoriesPage;
  }

  public isSignedIn(): boolean {
    return this.signedIn;
  }
}

import { Injectable, OnInit } from '@angular/core';
import { HlDisplayProductListConfigService } from './hl-display-product-list-config.service';
import {
  ProductService,
  PriceService,
  CustomerProductPrice,
  AuthService,
  SelectedCustomerService,
  ProductSortService,
  AssortmentService,
  GungFlowService,
  CloudPimFiltersService,
  CurrentAssortmentService
} from 'gung-standard';
import { ProductCategoryFilterService } from './filters/product-category-filter.service';
import { ProductMerchandiseTypeFilterService } from './filters/product-merchandise-type-filter.service';
import { ProductLengthFilterService } from './filters/product-length-filter.service';
import { ProductDepthFilterService } from './filters/product-depth-filter.service';
import { ProductGondolaBrandFilterService } from './filters/product-gondola-brand-filter.service';
import { ProductEtiquetteFilterService } from './filters/product-etiquette-filter.service';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from 'gung-standard';
import { first, mergeMap, map, tap, switchMap } from 'rxjs';
import { of, merge, forkJoin } from 'rxjs';
import { ProductCapacityFilterService } from './filters/product-capacity-filter.service';
import { ProductColorFilterService } from './filters/product-color-filter.service';
import { ProductShelfManufacturerFilterService } from './filters/product-shelf-manufacturer-filter.service';
import { HlPriceService, HlDisplayCustomerProductPrice } from './hl-price.service';
import { HlProductListConfigByCategoryService } from './hl-product-list-config-by-category.service';
import { environment } from '../../environments/environment';
import { ProductItemGroupFilterService } from 'gung-standard-jeeves';
import { ProductMousquetairesFilterService } from './filters/product-mousquetaires-filter.service';
import { MetadataService } from 'gung-standard';
import { TranslateService } from '@ngx-translate/core';
import { ProductAssortmentTypeFilterService } from './filters/product-assortment-type-filter.service';
import { HlDisplayBlockedProductsHelperService } from './hl-display-blocked-products-helper.service';
import { HlDisplayHelperService } from './hl-display-helper.service';
import { ProductExternalCategoryFilterService } from './filters/product-external-category-filter.service';
import { ProductExternalSubCategoryFilterService } from './filters/product-external-sub-category-filter.service';
import { ProductMainFunctionFilterService } from './filters/product-main-function-filter.service';
import { ProductStoreCategoryFilterService } from './filters/product-store-category-filter.service';
import { HlProductService } from './hl-product.service';
import { HlDisplayOrderService } from './hl-display-order.service';
import { ProductProductShelfTypeFilterService } from './filters/product-product-shelf-type-filter.service';
import { ProductWidthFilterService } from './filters/product-width-filter.service';
import { HlDisplayPortalFeaturesService } from './hl-display-portal-features.service';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayProductListConfigByOrderService extends HlDisplayProductListConfigService {
  private _isCustNlSuperunie1600 =
    !environment.sales &&
    environment.bolag === '1600' &&
    environment.mainCountry === 'nl' &&
    environment.mainCustomer === 'spar';

  constructor(
    public productService: HlProductService,
    protected productCategoryFilter: ProductCategoryFilterService,
    protected productMerchandiseTypeFilter: ProductMerchandiseTypeFilterService,
    protected productLengthFilter: ProductLengthFilterService,
    protected productDepthFilter: ProductDepthFilterService,
    protected productGondolaBrandFilter: ProductGondolaBrandFilterService,
    protected productEtiquetteFilter: ProductEtiquetteFilterService,
    protected productCapacityFilter: ProductCapacityFilterService,
    protected productColorFilter: ProductColorFilterService,
    protected productShelfManufacturerFilter: ProductShelfManufacturerFilterService,
    protected productItemGroupFilter: ProductItemGroupFilterService,
    productMousquetairesFilter: ProductMousquetairesFilterService,
    protected productAssortmentTypeFilter: ProductAssortmentTypeFilterService,
    protected priceService: HlPriceService,
    private route: ActivatedRoute,
    private orderService: OrderService,
    protected authService: AuthService,
    metadataServie: MetadataService,
    selectedCustomerService: SelectedCustomerService,
    translateService: TranslateService,
    productSortService: ProductSortService,
    assortmentService: AssortmentService,
    protected gungFlowService: GungFlowService,
    private hlDisplayBlockedProductsHelperService: HlDisplayBlockedProductsHelperService,
    helperService: HlDisplayHelperService,
    protected productCategoryFilterService: ProductCategoryFilterService,
    protected productExternalCategoryFilterService: ProductExternalCategoryFilterService,
    protected productExternalSubCategoryFilterService: ProductExternalSubCategoryFilterService,
    protected productMainFunctionFilterService: ProductMainFunctionFilterService,
    protected productStoreCategoryFilterService: ProductStoreCategoryFilterService,
    protected hlDisplayOrderService: HlDisplayOrderService,
    protected productProductShelfTypeFilterService: ProductProductShelfTypeFilterService,
    protected productWidthFilterService: ProductWidthFilterService,
    protected cloudPimFiltersService: CloudPimFiltersService,
    protected currentAssortmentService: CurrentAssortmentService,
    protected hlDisplayPortalFeaturesService: HlDisplayPortalFeaturesService
  ) {
    super(
      productService,
      productCategoryFilter,
      productMerchandiseTypeFilter,
      productLengthFilter,
      productDepthFilter,
      productGondolaBrandFilter,
      productEtiquetteFilter,
      productCapacityFilter,
      productColorFilter,
      productShelfManufacturerFilter,
      productItemGroupFilter,
      productMousquetairesFilter,
      productAssortmentTypeFilter,
      metadataServie,
      authService,
      selectedCustomerService,
      translateService,
      productSortService,
      priceService,
      assortmentService,
      gungFlowService,
      helperService,
      productCategoryFilterService,
      productExternalCategoryFilterService,
      productExternalSubCategoryFilterService,
      productMainFunctionFilterService,
      productStoreCategoryFilterService,
      hlDisplayOrderService,
      productProductShelfTypeFilterService,
      productWidthFilterService,
      cloudPimFiltersService,
      currentAssortmentService,
      hlDisplayPortalFeaturesService
    );
  }

  getIsProductOld(priceList: string | undefined): boolean {
    if (!priceList) {
      return true;
    }
    if (environment.allowedPriceLists.length === 0) {
      return false;
    }

    return !environment.allowedPriceLists.includes(priceList + '');
  }

  getItems() {
    const routeParams = this.route.snapshot.params;
    if (routeParams.orderId != null) {
      return this.orderService.getOrder(routeParams.orderId).pipe(
        first(),
        switchMap(order =>
          forkJoin({
            order: of(order),
            blockedProducts: !environment.sales
              ? this.hlDisplayBlockedProductsHelperService
                  .checkBlockedProducts(
                    order.rows.map(row => row.productId),
                    this._isCustNlSuperunie1600
                  )
                  .pipe(first())
              : of({})
          })
        ),
        map(({ order, blockedProducts }) =>
          !environment.sales ? order.rows.filter(row => !blockedProducts[row.id]) : order.rows
        ),
        map(rows => {
          const blockedItems = this.getBlockedItems();
          return rows.filter(row => !blockedItems.includes(row.id));
        }),
        map(rows => rows.filter(row => row.extra.orp.ordradnrstrpos === 0)),
        map(rows => rows.map(row => row.id)),
        mergeMap(productIds => {
          return forkJoin([
            this.productService.getProductsByIds(productIds).pipe(first()),
            this.priceService.getCurrentCustomerPrices(productIds).pipe(first())
          ]);
        }),
        map(d => {
          const products = d[0];
          const prices = d[1];
          return products.map(p => {
            const filteredPrices = prices.filter(p2 => p2.productId === p.id);
            if (filteredPrices.length === 0) {
              p.extra.oldProduct = true;
              return p;
            }
            const price = filteredPrices[0] as HlDisplayCustomerProductPrice;
            p.extra.oldProduct = this.getIsProductOld(price.priceList);
            return p;
          });
        }),
        map(products => [...products.filter(p => !p.extra.oldProduct), ...products.filter(p => p.extra.oldProduct)])
      );
    } else {
      return of([]);
    }
  }
}

<div class="card product-list-card">
  <div class="card-body mt-3">
    <div class="product-image d-none d-md-block">

      <ng-template ngTemplateOutlet *ngIf="!data.extra.oldProduct; then productImg; else oldProductImg"></ng-template>
      <ng-template #productImg>
        <a routerLink="/product/{{ data.id }}">
          <img
            class="img-fluid"
            *ngIf="!!data.extra.images && !!data.extra.images[0]"
            src="https://cdn1.gung.io/fit-in/250x250/filters:etag({{ data.extra.images[0].s3ETag }})/{{
              data.extra.images[0].s3Uri
            }}"
            alt=""
          />
        </a>
      </ng-template>
      <ng-template #oldProductImg>
        <div class="old-product-image">
          <a routerLink="/product/{{ data.id }}">
            <img src="assets/old-product.png" class="img-fluid" />
            <div class="text text-center" translate>OLD_PRODUCT_IMAGE</div>
          </a>
        </div>
      </ng-template>
    </div>

    <div class="product-info-wrapper p-3">
      <ng-template ngTemplateOutlet *ngIf="!data.extra.oldProduct; then productTitle; else oldProductTitle">
      </ng-template>
      <ng-template #productTitle>
        <div class="product-name mb-1 h5 font-weight-bold">
          <a class="product-link" routerLink="/product/{{ data.id }}">{{ getName() }}</a>
        </div>
        <div class="product-text">
          {{ getDescription() }}
        </div>
        <div class="product-text" *ngIf="getSecondaryDescription() !== ''">
          <span style="font-weight: bold" translate>DESCRIPTION</span><span style="font-weight: bold">:</span>
          {{ getSecondaryDescription() }}
        </div>
        <div class="h5 font-weight-bold price">
          <span translate>PRICE</span>:
          <span *ngIf="price && !isCloettaPortal; else staffedPrice"
            >{{
              price.customerNetPrice.value
                | gungCurrency
                  : price.customerNetPrice.currencyCode
                  : (isPortalNo ? 'code' : 'symbol-narrow')
                  : '1.2-2'
                  : (isCurrysPortal || isManagersNisa ? 'en-US' : 'sv_SE')
            }}
            <span *ngIf="hasArticlesOutsidePriceLists">{{ getAssortmentType(data.extra) }}</span>
            <span *ngIf="!hasArticlesOutsidePriceLists && price.backendPriceLevel.extra.isComplementaryPriceList"
              >(S)</span
            >
          </span>
          <ng-template *ngIf="price" #staffedPrice>
            <hl-display-price [price]="price" displayOnlyPrice="true" type="STAFFED"></hl-display-price>
          </ng-template>
        </div>
        <div>
          <span class="d-block">
            <span translate>ID</span>: {{ data.id }}
            <span *ngIf="isPodEnabled && data.extra._kuar">({{ data.extra._kuar }})</span>
            <span *ngIf="(isCoopPortal || isManagersNisa) && data.extra?._idToCustomer"
              >({{ data.extra._idToCustomer }})</span
            >
          </span>
          <span class="d-block" *ngIf="data.extra.hlPimItem.ItemColourName"
            ><span translate>COLOR</span>: {{ data.extra.hlPimItem.ItemColourName.join(',') }}</span
          >
          <span *ngIf="productSize" class="d-block"><span translate>SIZE</span>: {{ productSize }} </span>
          <span class="d-block"><span translate>PACK_SIZE</span>: {{ data.extra.stepAmount }}</span>

          <span *ngIf="data.extra.ar.antalperpall" class="d-block"
            ><span translate>QTY_PER_PALLET</span>: {{ data.extra.ar.antalperpall }}</span
          >
          <span
            *ngIf="
              isSales &&
              price &&
              hasArticlesOutsidePriceLists &&
              getAssortmentType(data.extra) === '(W)' &&
              data.extra.ar.q_basecost_start &&
              data.extra.ar.q_basecost_unit
            "
            class="d-block"
          >
            <span translate>BASECOST_S_U</span>:
            {{
              data.extra.ar.q_basecost_start
                | gungCurrency: price.customerNetPrice.currencyCode : (isPortalNo ? 'code' : 'symbol-narrow') : '1.2-2'
            }}
            /
            {{
              data.extra.ar.q_basecost_unit
                | gungCurrency: price.customerNetPrice.currencyCode : (isPortalNo ? 'code' : 'symbol-narrow') : '1.2-2'
            }}
          </span>
        </div>
        <div>
          <span translate>AVAILABILITY</span>:
          <app-hl-display-availability *ngIf="data.extra?.availabilities" [availability]="availability">
          </app-hl-display-availability>
        </div>
      </ng-template>
      <ng-template #oldProductTitle>
        <div class="product-link">
          <div class="product-name">{{ data.name }}</div>
          <div class="product-text">{{ data.description }}</div>
        </div>
      </ng-template>

      <!-- <div class="product-info-table">
        <div class="table-responsive">
          <table class="table table-sm">
            <ng-template ngTemplateOutlet *ngIf="isSales; then productTableSales; else productTable"></ng-template>
            <ng-template #productTableSales>
              <thead>
                <tr>
                  <th class="d-table-cell text-center" style="width: 20%">
                    <span translate>PRODUCT_ID</span>
                  </th>

                  <th class="d-table-cell text-center" style="width: 40%">
                    <span translate>PRICE</span>
                  </th>

                  <th class="d-table-cell text-center" style="width: 30%">
                    <span translate>AVAILABILITY</span>
                  </th>

                  <th class="d-table-cell text-center" translate style="width: 30%">QUANTITY</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center d-table-cell" style="width: 20%">
                    {{ data.id }}
                  </td>

                  <td class="text-center d-table-cell" style="width: 40%">
                    <span *ngIf="price"
                      >{{
                        price.customerNetPrice.value
                          | gungCurrency
                            : price.customerNetPrice.currencyCode
                            : (isPortalNo ? 'code' : 'symbol-narrow')
                            : '1.2-2'
                      }}
                      <span *ngIf="hasArticlesOutsidePriceLists">{{ getAssortmentType(data.extra) }}</span>
                      <span
                        *ngIf="!hasArticlesOutsidePriceLists && price.backendPriceLevel.extra.isComplementaryPriceList"
                        >(S)</span
                      >
                    </span>
                  </td>

                  <td class="text-center d-table-cell" style="width: 30%">
                    <app-hl-display-availability
                      *ngIf="data.extra?.availabilities"
                      [availability]="data.extra.availabilities['0']"
                    >
                    </app-hl-display-availability>
                  </td>

                  <td class="text-center d-table-cell" style="width: 30%">
                    <app-hl-display-buy-btn
                      [stepAmount]="data.extra.stepAmount"
                      *ngIf="!data.extra.oldProduct"
                      [minimumOrderQuantity]="getMinimumOrderQuantity(data.extra)"
                      [id]="data.id"
                    >
                    </app-hl-display-buy-btn>
                  </td>
                </tr>
              </tbody>
            </ng-template>

            <ng-template #productTable>
              <thead>
                <tr>
                  <th class="d-table-cell text-center" style="width: 12.5%">
                    <span translate>PRODUCT_ID</span>
                  </th>

                  <ng-template ngTemplateOutlet *ngIf="!!data.extra.hlPimItem.Lengthmm; then lengthTh; else depthTh">
                  </ng-template>

                  <ng-template #lengthTh>
                    <th class="d-table-cell text-center" [style.width]="isUkBolag ? '17.5%' : '12.5%'">
                      <span translate>LENGTH</span>
                    </th>
                  </ng-template>

                  <ng-template #depthTh>
                    <th class="d-table-cell text-center" [style.width]="isUkBolag ? '17.5%' : '12.5%'">
                      <span translate>DEPTH</span>
                    </th>
                  </ng-template>

                  <th class="text-center" [style.width]="isUkBolag ? '20%' : '15%'">
                    <span translate>PACK_SIZE</span>
                  </th>

                  <th class="d-table-cell text-center" [style.width]="isUkBolag ? '17.5%' : '12.5%'">
                    <span translate>PRICE</span>
                  </th>

                  <th class="d-table-cell text-center" [style.width]="isUkBolag ? '22.5%' : '42.5%'">
                    <span translate>AVAILABILITY</span>
                  </th>

                  <th class="d-table-cell text-center" translate>QUANTITY</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center d-table-cell" style="width: 12.5%">
                    {{ data.id }}
                  </td>

                  <ng-template ngTemplateOutlet *ngIf="!!data.extra.hlPimItem.Lengthmm; then lengthTd; else depthTd">
                  </ng-template>
                  <ng-template #lengthTd>
                    <td class="text-center d-table-cell" style="width: 12.5%">
                      <span> {{ data.extra.hlPimItem.Lengthmm }}</span>
                    </td>
                  </ng-template>
                  <ng-template #depthTd>
                    <td class="text-center d-table-cell" style="width: 12.5%">
                      <span>{{ data.extra.hlPimItem.Depthmm }}</span>
                    </td>
                  </ng-template>

                  <td class="text-center" style="width: 15%">{{ data.extra.stepAmount }}</td>

                  <td class="text-center d-table-cell" style="width: 12.5%">
                    <span class="text-nowrap" *ngIf="price">
                      {{
                        price.customerNetPrice.value
                          | gungCurrency
                            : price.customerNetPrice.currencyCode
                            : (isPortalNo ? 'code' : 'symbol-narrow')
                            : '1.2-2'
                      }}
                      <span *ngIf="hasArticlesOutsidePriceLists">{{ getAssortmentType(data.extra) }}</span>
                      <span
                        *ngIf="!hasArticlesOutsidePriceLists && price.backendPriceLevel.extra.isComplementaryPriceList"
                        >(S)</span
                      >
                    </span>
                  </td>

                  <td class="text-center d-table-cell" style="width: 42.5%">
                    <app-hl-display-availability
                      *ngIf="data.extra?.availabilities"
                      [availability]="data.extra.availabilities['0']"
                    >
                    </app-hl-display-availability>
                  </td>

                  <td class="text-ckesko settingsenter d-table-cell">
                    <app-hl-display-buy-btn
                      [stepAmount]="data.extra.stepAmount"
                      *ngIf="!data.extra.oldProduct"
                      [minimumOrderQuantity]="getMinimumOrderQuantity(data.extra)"
                      [id]="data.id"
                    >
                    </app-hl-display-buy-btn>
                  </td>
                </tr>
              </tbody>
            </ng-template>
          </table>
        </div>
      </div> -->
    </div>
    <div class="product-btns mb-3 mt-auto">
      <app-hl-display-buy-btn
        [stepAmount]="data.extra.stepAmount"
        *ngIf="!data.extra.oldProduct"
        [minimumOrderQuantity]="getMinimumOrderQuantity(data.extra)"
        [id]="data.id"
      >
      </app-hl-display-buy-btn>
      <a class="product-link" routerLink="/product/{{ data.id }}">
        <button
          type="button"
          class="btn btn-block btn-outline-primary btn-sm mt-2 w-100"
          style="white-space: nowrap"
          translate
        >
          MORE_INFO
        </button>
      </a>
    </div>
  </div>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductListConfigService, Product } from 'gung-standard';
import { HlProductListConfigByCategoryService } from '../../services/hl-product-list-config-by-category.service';
import { FilterListComponent } from 'gung-list';

@Component({
  selector: 'app-product-list-by-category',
  template: '<lib-filter-list #productListByCategory [configService]="productListConfigService"></lib-filter-list>',
  styleUrls: ['./product-list-by-category.component.css'],
  providers: [{ provide: ProductListConfigService, useClass: HlProductListConfigByCategoryService }]
})
export class ProductListByCategoryComponent {
  constructor(public productListConfigService: ProductListConfigService) {}
  @ViewChild('productListByOrder') productList: FilterListComponent<Product>;
}

import { Inject, Injectable, Optional } from '@angular/core';
import { PipeService, SelectedCustomerService } from 'gung-standard';

import { map, skipWhile } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class HlPipeService extends PipeService {
  private selectedCustomerFinancialGrpCode: string | null = null;
  constructor(
    protected selectedCustomerService: SelectedCustomerService,
    protected translateService: TranslateService,
    @Optional()
    @Inject('environment')
    protected environment: { [s: string]: any }
  ) {
    super();
    selectedCustomerService
      .getSelectedCustomer()
      .pipe(
        skipWhile(customer => !customer),
        map(customer => customer.extra.kus.q_financialgrpcode)
      )
      .subscribe(grpCode => (this.selectedCustomerFinancialGrpCode = grpCode));
  }

  preprocessCurrency(value: any): number | string {
    if (this.selectedCustomerFinancialGrpCode === null) {
      return value;
    }
    if (!this.environment.discountCustomerFinancialGroup[this.selectedCustomerFinancialGrpCode]) {
      // the financial grp does not exist in the env
      return value;
    }
    // financial grp exists in env, calculate discount
    const percentageToAdd: number =
      this.environment.discountCustomerFinancialGroup[this.selectedCustomerFinancialGrpCode];

    return value * (1 + percentageToAdd);
  }

  transformCurrency(
    transformed: string | null,
    input: any,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string
  ): string | null {
    if (this.translateService.currentLang !== 'fr') {
      return transformed;
    }
    if (display !== '') {
      return transformed + ' HT';
    } else {
      return transformed;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  PriceService,
  ProductCardComponent,
  CartService,
  CustomerProductPrice,
  MetadataService,
  Product,
  SelectedCustomerService,
  Availability,
  Customer
} from 'gung-standard';
import { first, forkJoin } from 'rxjs';
import { HlDisplayPortalFeaturesService, PODSettingsGroup } from '../../services/hl-display-portal-features.service';

@Component({
  selector: 'app-hl-display-product-card-list',
  templateUrl: './hl-display-product-card-list.component.html',
  styleUrls: ['./hl-display-product-card-list.component.scss']
})
export class HlDisplayProductCardListComponent extends ProductCardComponent implements OnInit {
  price: CustomerProductPrice;
  private customerId: string;

  currentLang = this.translateService.currentLang;
  productSize: string;

  availability: Availability;

  isPODEnabled: boolean;

  currentCustomer: Customer;

  constructor(
    private priceService: PriceService,
    cartService: CartService,
    protected metadataService: MetadataService,
    protected customerService: SelectedCustomerService,
    protected translateService: TranslateService,
    protected portalFeaturesService: HlDisplayPortalFeaturesService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.priceService
      .getCurrentCustomerPrice(this.data.id)
      .pipe(first())
      .subscribe(price => {
        this.price = price;
      });

    forkJoin({
      podSettingsGroup: this.portalFeaturesService.getPortalFeatureGroup('podSettingsGroup'),
      selectedCustomer: this.customerService.getSelectedCustomer()
    })
      .pipe(first())
      .subscribe(({ podSettingsGroup, selectedCustomer }) => {
        let podSettings = podSettingsGroup as PODSettingsGroup;

        this.isPODEnabled = podSettings.podenabled;

        if (podSettings.podenabled) {
          this.customerId = selectedCustomer.id;
          this.currentCustomer = selectedCustomer;
          this.data.extra._kuar = this.currentCustomer.extra.kuarMapped?.[this.data.id]?.artnrkund || '';
        }

        this.data.extra._kuar = this.currentCustomer?.extra.kuarMapped?.[this.data.id]?.artnrkund || '';
      });

    this.productSize = this.getProductSize(this.data);
    this.availability = this.getProductAvailability(this.data);
  }

  private getProductAvailability(product: Product): Availability {
    if (product.extra.availabilities) {
      const availabilities = Object.keys(product.extra.availabilities).map(key => product.extra.availabilities[key]);
      if (availabilities.length > 0) {
        return availabilities[0];
      }
    }

    return null;
  }

  getProductSize(product: Product): string {
    let productSize: string;
    if (product.extra.hlPimItem.Size && product.extra.hlPimItem.Size !== '-') {
      productSize = product.extra.hlPimItem.Size;
    } else if (
      product.extra.hlPimProduct.ProductExternalCategory === 'PriceCommunication' &&
      product.extra.hlPimItem.LabelHeightmm
    ) {
      productSize = '';
      if (product.extra.hlPimItem.LabelHeightmm && product.extra.hlPimItem.LabelHeightmm !== '-') {
        productSize += `H:${product.extra.hlPimItem.LabelHeightmm} mm`;
      }
      if (product.extra.hlPimItem.Widthmm && product.extra.hlPimItem.Widthmm !== '-') {
        if (productSize) {
          productSize += ` x `;
        }
        productSize += `W:${product.extra.hlPimItem.Widthmm}mm`;
      }
    } else {
      productSize = '';
      if (product.extra.hlPimItem.Widthmm && product.extra.hlPimItem.Widthmm !== '-') {
        productSize += `W:${product.extra.hlPimItem.Widthmm}mm`;
      }
      if (product.extra.hlPimItem.Depthmm && product.extra.hlPimItem.Depthmm !== '-') {
        if (productSize) {
          productSize += ` x `;
        }
        productSize += `D:${product.extra.hlPimItem.Depthmm}mm`;
      }
      if (product.extra.hlPimItem.Heightmm && product.extra.hlPimItem.Heightmm !== '-') {
        if (productSize) {
          productSize += ` x `;
        }
        productSize += `H:${product.extra.hlPimItem.Heightmm}mm`;
      }
    }

    return productSize;
  }

  getName(): string {
    return this.data.name;
  }

  getKuarDesc(): string {
    return this.currentCustomer.extra.kuarMapped?.[this.data.id]?.artnrkund || '';
  }

  getDescription(): string {
    return this.data.description;
  }

  getSecondaryDescription() {
    return '';
  }

  getMinimumOrderQuantity(extra: any): number {
    return extra.stepAmount;
  }

  getAssortmentType(extra: any): string {
    if (extra.assortmentType === 'C') {
      return '';
    }

    return '(' + extra.assortmentType + ')';
  }
}

<div *ngIf="!!product && !!price">
  <div class="row">
    <div class="col-1 d-flex align-items-center justify-content-center">
      <button class="btn btn-light delete-button" (click)="removeRow()"><i class="fas fa-times"></i></button>
    </div>
    <div class="col-1 d-flex flex-column justify-content-center">
      <img
        class="img-fluid cart-item-image"
        src="https://d27ahaa1qqlr90.cloudfront.net/fit-in/100x100/filters:fill(white):background_color(white):format(jpeg)/{{
          product.extra.images[0].s3Uri
        }}"
        alt=""
      />
    </div>
    <div class="col-2 d-flex flex-column justify-content-center">
      <div class="product-name">
        <b>{{ product.id }}</b> - {{ product.name }}
      </div>
    </div>
    <div class="col-2 d-flex align-items-center justify-content-center">
      <app-hl-display-buy-btn
        [stepAmount]="product.extra.stepAmount"
        [minimumOrderQuantity]="getMinimumOrderQuantity(product)"
        [id]="data.productId"
      ></app-hl-display-buy-btn>
    </div>
    <div class="col-1 d-flex align-items-center justify-content-center">
      <span *ngIf="data.extra.validationData.availabilityData.currentAvailability < data.qty; else available">
        <span *ngIf="isSales">{{ data.extra.validationData.availabilityData.currentAvailability }}</span>
        <span *ngIf="!isSales"
          >{{ data.extra.validationData.availabilityData.currentAvailability }}
          <span class="quantity-above-than-available">*</span></span
        >
      </span>
      <ng-template #available>
        <span translate>ON_STOCK</span>
      </ng-template>
    </div>
    <div *ngIf="shouldShowFirstAvailableDateInCheckout" class="col-1 d-flex align-items-center justify-content-center px-0">
      {{ data.extra.validationData.firstAvailableDate | date: (is1210Bolag ? 'yyyy-MM-dd' : 'dd-MM-yy') }}
    </div>
    <div *ngIf="!shouldShowFirstAvailableDateInCheckout" class="col-1 d-flex align-items-center justify-content-center px-0"></div>
    <div class="col-2 d-flex align-items-center justify-content-center flex-column">
      <div>
        <b>
          {{
            price.customerNetPrice.value | gungCurrency: price.customerNetPrice.currencyCode: 'symbol-narrow' : '1.2-2'
          }}
        </b>
      </div>
    </div>
    <div class="vhr col-2 d-flex align-items-center justify-content-center flex-column">
      <div>
        <b>
          {{
            price.customerNetPrice.value * data.qty | gungCurrency: price.customerNetPrice.currencyCode: 'symbol-narrow' : '1.2-2'
          }}
      </b>
      </div>
    </div>
  </div>
  <div class="row hr"></div>
</div>

{
  "GO_TO_ALL_PRODUCTS": "Visa alla produkter",
  "SHOP_BY_PRODUCT_LIST": "Produkter",
  "OPEN_ORDERS_QUOTE": "Öppna O&O",
  "ORGANISATION_NO": "Org. nr",
  "CREATE_ORDER": "Lägg order",
  "ORDER": "Order",
  "SUPER_GROUP": "Supergrupp",
  "FINANCIAL_GROUP": "Affärsgrupp",
  "DISCOUNT_PERCENT": "Rabatt (procent)",
  "SELECT_CUSTOMER_MODAL_TITLE": "Kundinformation",
  "SELECTED_CUSTOMER_OPTION": "Den valda kunden är för tillfället",
  "SELECTED_CUSTOMER_CONTINUE": "Vill du fortsätta eller byta kund till",
  "CUSTOMER_CONTINUE": "Behåll nuvarande",
  "CUSTOMER_CHANGE": "Byt kund",
  "ORDER_ID": "Order id",
  "CREATE_QUOTE": "Skapa offert",
  "SALJ_SALJARENAMN": "Säljare",
  "INVOICE_ADDRESS": "Faktureringsadress",
  "QUOTE_TO_ORDER": "Skapa order av offert",
  "ADD_ROW": "Lägg till rad",
  "ADD_ORDER_ROW": "Lägg till orderrad",
  "SUBMIT_ROWS": "Skicka in orderrader",
  "PREV": "Föregående",
  "XDL_DELLEVTILLATENBESKR": "Delleverans tillåten",
  "X2T_TRANSPORTORSNAMN": "Transportör",
  "FTGNAMN": "Kundnamn",
  "FTGPOSTADR2": "Adress",
  "FTGPOSTNR": "Postnr",
  "FTGPOSTADR3": "Ort",
  "MEETING": "Möte",
  "MEETING_TYPE": "Mötetyp",
  "SALES_CYCLE": "Försäljningscykel",
  "MEETING_SYSTEM": "systemet",
  "MEETINGS": "Möten",
  "UPDATE_MEETING": "Förändra",
  "SYNC_OUTLOOK": "Sync Outlook",
  "CHANGE_DATE_ON_ALL_ROWS": "Välj datum för samtliga rader",
  "UNABLE_TO_DELIVER_ROWS": "Kan ej leverera följande rader",
  "AVAILABLE_QUANTITY": "Tillgängligt antal",
  "REDUCE_QUANTITY": "Minska antal",
  "REMOVE_ROW": "Ta bort artikel från order",
  "DELIVERY_TRACKING": "Leveransspårning",
  "FINAL_FREIGHT_CONDITIONS": "",
  "EXPECTED_DELIVERY_DAYS": "Förväntat antal dagar till leverans",
  "AVAILABLE_SHORT": "Tillg.",
  "SEARCH_STORE_NAME_NUMBER": "Sök i ditt butiks namn eller nummer",
  "SPECIFICATION": "Specifikation",
  "COLOUR": "Färg",
  "MORE_INFO": "Mer information",
  "TRACKING_EMAIL": "Spårning av e-post",
  "TRACKING_EMAIL_TEMP": "Spårning av e-post temp",
  "TRACKING_LINK_TEMP_EMAIL": "Använd om kunden vill att spårningslänken skickas till ett tillfälligt e-postmeddelande",
  "INVOICE_EMAIL": "Fakturamail",
  "CUSTOMER_TRACKING_EMAIL_UPDATE_SUCCESS": "Spårning av e-post uppdaterad",
  "CUSTOMER_TRACKING_EMAIL_UPDATE_ERROR": "Fel vid försök att uppdatera spårningsmeddelandet",
  "GOODS_LABEL": "Varumärke",
  "GRAVITY_SCOOP_BINS": "GRAVITY_SCOOP_BINS",
  "DISPLAY_ACCESSORIES": "Displaytillbehör",
  "SHELF_MANAGEMENT_AUTOMATIC": "Frammatningssystem - Automatisk",
  "LIGHTING_POWER_INFRASTRUCTURE": "LIGHTING_POWER_INFRASTRUCTURE",
  "PRICE_COMMUNICATION": "Priskommunikation",
  "FRESH_PRODUCE_SOLUTIONS": "FRESH_PRODUCE_SOLUTIONS",
  "PROTECTION_SAFETY_EQUIPMENT": "PROTECTION_SAFETY_EQUIPMENT",
  "SHELF_MANAGEMENT_MANUAL": "Frammatningssystem - Manuell",
  "VIEW_ALL_PRODUCTS": "Se alla produkter",
  "MUSKETEERS_SELECTION": "MUSKETEERS_SELECTION",
  "PRESENTOIRS_PANIERS": "PRESENTOIRS_PANIERS",
  "STORE_COMMUNICATION_BRANDING": "Butikskommunikation",
  "CAMPAIGN_CODE": "Välkomstkod",
  "CLICK": "Klick",
  "HERE": "här",
  "DELIVERY_TRACKING_LINK": "Länk",
  "CART_ITEM_QTY_ABOVE_AVAILABLE_QTY": "<span class='quantity-above-than-available'>*</span> Den önskade mängden av en eller flera artiklar är inte tillgänglig. Angivet leveransdatum tar hänsyn till varans påfyllningstid.",
  "CUSTOMER_PORTAL_ADDRESS2": "Gatuadress",
  "CUSTOMER_PORTAL_ADDRESS1": "C/O",
  "HEADER_TEXT": "Välkommen till HL Display <b>kundportal</b><br>Vi önskar er en bra shoppingupplevelse",
  "IN_STOCK_BELOW_REQUESTED_QTY": "Låg mängd i lager",
  "ASSORTMENT_TYPE": "Sortiment",
  "PRODUCT_ASSORTMENT_TYPE_FILTER_CUSTOMER_PRICE_LIST": "Kund PL",
  "PRODUCT_ASSORTMENT_TYPE_FILTER_STANDARD_PRICE_LIST": "Standard PL",
  "PRODUCT_ASSORTMENT_TYPE_FILTER_WAREHOUSE": "Lager",
  "CARRIER_CODE": "Transportörskod",
  "SET_PASSWORD": "Ändra lösenord",
  "RESET_PASSWORD_REQUEST_SENT": "Lösenordsändring skickad",
  "PASSWORD_CONFIRM": "Bekräfta lösenord",
  "EMAIL_INVOICE": "E-postfaktura",
  "EMAIL_QUOTE": "Offert via e-post",
  "EMAIL_QUOTE_TEMPORARY": "E-postoffert tillfälligt *",
  "EMAIL_TEXT": "E-posttext",
  "MESSAGE_EMAIL_QUOTE_TEMP": "Informationen som läggs till i det här fältet är det e-postmeddelande som offerten skickas till. Om du lämnar detta tomt skickas offertet till ”E-postoffert”",
  "SEND_QUOTE": "Skicka offert",
  "OUTSIDE_ASSORTMENT": "Utanför sortiment",
  "BLOCKED": "Blockerad",
  "OFFERS": "Offers",
  "ACTIVITIES": "Activities",
  "ADD_MEETING": "Add meeting",
  "MEETING_DATE": "Meeting date",
  "PRODUCT_SYSTEM": "Produktgrupp",
  "MERCHANDISE_BRAND": "Varumärke",
  "FRONT": "Artikelfront",
  "LABEL_TYPE": "Etiketttyp",
  "SPRING": "Pusherstyrka",
  "SHEFT_TYPE": "Hylltyp",
  "SHELF_MANUFACTURER": "Hylltillverkare",
  "SHELF_LENGHT": "Hylllängd",
  "SHELF_DEPTH": "Hylldjup",
  "SAMPLE_ORDER": "Sample",
  "SAMPLE": "Sample",
  "OFFER": "Offer",
  "PURCHASED": "Purchased",
  "DURATION": "Varaktighet",
  "HOURS": "Timmar",
  "PORTE_ETIQUETTE": "Etiketthållare",
  "DELIVERY_ADDRESS": "Lieferungsanschrift",
  "IMPULSE_PROMOTION": "Impulse & Promotion",
  "CONTACT_NAME": "Name",
  "EMAIL_QUOTE_ORDER_CONFIRMATION": "E-Mail-Angebot/Bestellbestätigung",
  "EMAIL_QUOTE_ORDER_CONFIRMATION_TEMPORARY": "E-Mail-Angebot / Auftragsbestätigung - temporär *",
  "EMAIL_QUOTE_TEMPORARY_REQUIRED": "E-Mail-Angebot/Bestellbestätigung - temporäres Feld erforderlich",
  "CREDIT_BLOCKED_MODAL_HTML": "Das Guthaben des Kunden <b>{{customerName}}</b> ist gesperrt, daher können keine Bestellungen aufgegeben werden.",
  "CREDIT_SOFT_BLOCKED_MODAL_HTML": "Der Kunde <b>{{customerName}}</b> kann nur Angebote abgeben, weil der aktuelle Warenkorbwert {{total}} {{currency}} beträgt und sein verbleibendes Guthaben {{credit}} {{currency}}.",
  "CREDIT_SOFT_BLOCKED": "Nur Angebote verfügbar",
  "EMAIL_QUOTE_TEMPORARY_INVALID": "Ungültige E-Mail-Adresse angegeben!",
  "NO_FREIGHT_NO_VAT": "Excl. BTW en vracht",
  "ORDER_TITLE": "Bestellung",
  "EMAIL_ORDER_CONFIRMATION_TEMPORARY": "Temporäre Bestellbestätigungs-E-Mail",
  "MESSAGE_EMAIL_ORDER_CONFIRMATION_TEMP": "Die in diesem Feld hinzugefügten Informationen sind die E-Mail-Adresse, an die die Bestellbestätigung gesendet wird. Wenn Sie dieses Feld leer lassen, wird die Bestellbestätigung an die Standard-E-Mail-Adresse gesendet.",
  "CREDIT_BLOCKED_MODAL_HTML_1210_FI": "Leider kann die Bestellung nicht gespeichert werden. Bitte wenden Sie sich an den Kundendienst: info.fi@hl-display.com / +358 9 562 9180",
  "CREDIT_BLOCKED_MODAL_HTML_1210_SE": "Leider kann die Bestellung nicht gespeichert werden. Bitte wenden Sie sich an den Kundendienst: info.se@hl-display.com / +46 8 683 7363",
  "CREDIT_BLOCKED_MODAL_HTML_1210_NO": "Leider kann die Bestellung nicht gespeichert werden. Bitte wenden Sie sich an den Kundendienst: info.no@hl-display.com / +47 66 983 600",
  "CREDIT_BLOCKED_MODAL_HTML_1810_DE": "Leider kann die Bestellung nicht gespeichert werden. Bitte wenden Sie sich an den Kundendienst: xxx@hl-display.com / xxx",
  "CREDIT_BLOCKED_MODAL_HTML_2400_CH": "Leider kann die Bestellung nicht gespeichert werden. Bitte wenden Sie sich an den Kundendienst: xxx@hl-display.com / xxx",
  "CREDIT_BLOCKED_MODAL_HTML_1600_FR_OR_NL": "Leider konnten wir Ihre Bestellung nicht bestätigen. Könnten Sie unseren Kundenservice unter 02 47 48 85 00 kontaktieren? Dank im Voraus.",
  "CREDIT_BLOCKED_MODAL_HTML_1600_BE": "Leider konnten wir Ihre Bestellung nicht bestätigen. Könnten Sie unseren Kundenservice unter 02 47 48 85 00 kontaktieren? Dank im Voraus.",
  "INTERNAL_CREDIT_BLOCKED_MODAL_HTML": "Leider kann die Bestellung nicht gespeichert werden, wenden Sie sich bitte an den Kundendienst.",
  "CREDIT_BLOCKED_MODAL_HTML_CUSTOMER_SERVICE": "Leider kann die Bestellung nicht gespeichert werden. Bitte wenden Sie sich an den Kundendienst: {customerServiceEmail} / {customerServicePhoneNumber}",
  "BASECOST_S_U": "Basecost S / U",
  "PRODUCT_LIST": "Product list",
  "SEE_YOUR_AGREED_ASSORTMENT_AND_PRICES": "See your agreed assortment and prices",
  "ORDER_HISTORY": "Order history",
  "FIND_YOUR_PREVIOUS_ORDERS_TWO_YEAR_BACK": "Find your previous orders two year back",
  "FAVORITE_LIST": "Favorite list",
  "GO_TO_YOUR_FAVOURITE_PRODUCTS": "Go to your favourite products",
  "CONTACT_US": "Contact us",
  "FIND_OUR_CONTECT_INFORMATION_BY_CLICKING_HERE": "Find our contect information by clicking here",
  "HELP_US_IMPROVE": "Help us improve",
  "IF_YOU_WANT_TO_SEND_US_FEEDBACK_OR_HAVE_IDEAS_FOR_IMPROVEMENTS": "If you want to send us feedback or have ideas for improvements on the portal, please use this form."
}
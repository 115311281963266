import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CartService, PipeService, PriceService, SalesCartConfigService, SelectedCustomerService } from 'gung-standard';
import { first, switchMap, tap } from 'rxjs';
import { format } from 'date-fns';
import { environment } from '../../../environments/environment';
import { HlDeliveryDateService } from '../../services/hl-delivery-date.service';
import {
  SalesCartCartService,
  SalesCartPipeService,
  SalesCartPriceService,
  SalesCartSalesCartConfigService
} from '../checkout/steps/hl-sales-cart-step/hl-sales-cart-step.component';

@Component({
  selector: 'app-hl-display-checkout-add-row-modal',
  templateUrl: './hl-display-checkout-add-row-modal.component.html',
  styleUrls: ['./hl-display-checkout-add-row-modal.component.css']
})
export class HlDisplayCheckoutAddRowModalComponent implements OnInit {
  private orderLineItemNotInJeevesProductId = '155035';
  private orderLineItemNotInJeevesNextIndex = 0;

  modalRows: {
    productId: string;
    productPartialId: string;
    quantity: number;
    price: number;
    discount: number;
    name: string;
  }[] = [];
  discountFactor: number;

  constructor(
    public activeModal: NgbActiveModal,
    private cartService: CartService,
    private selectedCustomerService: SelectedCustomerService,
    private deliveryDateService: HlDeliveryDateService
  ) {}

  ngOnInit() {
    this.cartService
      .getCurrentCart()
      .pipe(first())
      .subscribe(cartRows => {
        const cartRowsItemsNotInJeeves = cartRows.filter(
          cartRow => cartRow.productId === this.orderLineItemNotInJeevesProductId
        );

        // get next index to be partialId
        if (cartRowsItemsNotInJeeves.length > 0) {
          const cartRowsItemsNotInJeevesPartialIds = cartRowsItemsNotInJeeves.map(cartRow =>
            Number.parseInt(cartRow.productPartialId, 10)
          );
          this.orderLineItemNotInJeevesNextIndex = Math.max(...cartRowsItemsNotInJeevesPartialIds) + 1;
        }
      });
  }

  removeRow(index) {
    this.modalRows.splice(index, 1);
  }

  addNewRow() {
    this.modalRows.push({
      productId: this.orderLineItemNotInJeevesProductId,
      productPartialId: '' + this.orderLineItemNotInJeevesNextIndex,
      quantity: 1,
      discount: 0,
      name: '',
      price: 0
    });

    this.orderLineItemNotInJeevesNextIndex++;
  }

  submitRows() {
    if (this.modalRows.length === 0) {
      this.activeModal.close();
      return;
    }

    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(
        first(),
        tap(customer => {
          const customerFinancialGroup = customer.extra.kus.q_financialgrpcode;
          this.discountFactor = environment.discountCustomerFinancialGroup[customerFinancialGroup]
            ? environment.discountCustomerFinancialGroup[customerFinancialGroup]
            : 0;
        }),
        switchMap(customer => this.deliveryDateService.getDeliveryDates(customer.extra.kus.levsattkod).pipe(first()))
      )
      .subscribe(deliveryDates => {
        const firstDateAvailable = deliveryDates.find(date => date.valid);
        const deliveryDate = firstDateAvailable ? firstDateAvailable.date : new Date();

        for (const modalRow of this.modalRows) {
          const theOrp = {
            vb_pris: modalRow.price * (1 / (1 + this.discountFactor)),
            ordantal: modalRow.quantity,
            rabatt1: modalRow.discount,
            artbeskr: modalRow.name,
            ordberlevdat: format(deliveryDate, 'yyyy-MM-dd')
          };

          this.cartService.addToCart(modalRow.productId, modalRow.quantity, undefined, modalRow.productPartialId);

          // This doesn't call SalesCartCartService, thats why the workaround above
          this.cartService.setExtra(
            modalRow.productId,
            {
              orp: theOrp,
              _discountCustomerFinancialGroupInfo: {
                appliedFactor: this.discountFactor,
                appliedTo: modalRow.price,
                appliedFrom: 'HlDisplayCheckoutAddRowModalComponent'
              }
            },
            undefined,
            modalRow.productPartialId
          );
        }

        this.activeModal.close();
      });
  }

  validateQuantity(value: number, rowIndex: number): void {
    if (value <= 0) {
      this.modalRows[rowIndex].quantity = 1;
    }
  }
}

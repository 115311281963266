import { Component, ComponentFactoryResolver, ChangeDetectorRef } from '@angular/core';
import { ListLayoutSingleComponent } from 'gung-list';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-top-product-list-layout',
  templateUrl: './top-product-list-layout.component.html',
  styleUrls: ['./top-product-list-layout.component.scss']
})
export class TopProductListLayoutComponent<T> extends ListLayoutSingleComponent<T> {
  isUkBolag = environment.bolag === '1710';
  private isFRCustomerPortalPharma =
    !environment.sales && environment.mainCountry === 'fr' && environment.mainCustomer === 'Pharmacies';
  public isFRCustomerPortal1600General =
    !environment.sales &&
    environment.mainCountry === 'fr' &&
    environment.bolag === '1600' &&
    environment.mainCustomer === 'General';

  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
    changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService
  ) {
    super(componentFactoryResolver, changeDetectorRef);
  }

  getTitle(): string {
    if (this.isFRCustomerPortalPharma || this.isFRCustomerPortal1600General) {
      return this.translateService.instant('TOP_PURCHASED_TITLE_PHARMA');
    }

    return this.translateService.instant('TOP_PURCHASED_TITLE');
  }
}

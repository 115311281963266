<app-hl-display-navbar></app-hl-display-navbar>
<!-- temporary comment: https://trello.com/c/LU1UBise/544-customer-portal-all-slider-as-a-header-for-promotions-should-look-exactly-like-this-https-wwwposcentrecouk -->
<div
  class="container-sales"
  *ngIf="!isSales"
  [style.height.px]="isOnCategoriesPage() ? 430 : 0"
  [ngClass]="{
    'logged-out': !isSignedIn(),    
  }"
>
  <app-hl-display-slider-promotion *ngIf="isOnCategoriesPage()"></app-hl-display-slider-promotion>
</div>

<!-- REMOVE THIS IN FUTURE -->
<!-- <div class="navbar-top-image" *ngIf="!isSales && !isFRCustomerPortal1600General" [ngClass]="{
    'active': isOnCategoriesPage(),
    'logged-out':!isSignedIn(),
    'customer': !isSales,
    'h-0': !isOnCategoriesPage(),
    'navbar-top-image-customer-default': !isFRCustomerPortalPharma,
    'navbar-top-image-customer-pharmacie': isFRCustomerPortalPharma
  }">

  <div class="d-flex flex-column justify-content-around h-100 p-0">
    <div class="header-wrapper" *ngIf="isOnCategoriesPage()">
      <div class="header-text" [innerHTML]="'HEADER_TEXT' | translate">
      </div>
      <div>
        <a class="btn header-button" [ngClass]="{ 'btn-customer-portal-go-to-products': !isSales }"
          routerLink="/products">{{'GO_TO_ALL_PRODUCTS' | translate}}</a>
      </div>
    </div>
  </div>
</div> -->
<!-- END REMOVE THIS -->
<div [ngClass]="{ 'gray-bg': grayBg() }">
  <div
    class="main"
    [ngClass]="{
      'on-category-page': isOnCategoriesPage(),
      'uk-bolag-categories-top-page': isUkBolag,
      'container-sales': isSales,
      'container-customer-signed-out': customerPortalSignedOut,
      'container': !onHomeUser,
      'container-fluid p-0': onHomeUser
    }"
  >
    <router-outlet> </router-outlet>
  </div>
</div>
<app-footer></app-footer>

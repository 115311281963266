import { Component, OnInit } from '@angular/core';
import {
  AuthService,
  CartItemComponent,
  CartService,
  PriceService,
  Product,
  ProductInputQuantityConfigService,
  ProductService
} from 'gung-standard';
import { environment } from '../../../environments/environment';
import { first } from 'rxjs';
import {
  HlDisplayPortalFeaturesService,
  CheckoutSettingsGroup
} from '../../services/hl-display-portal-features.service';

@Component({
  selector: 'app-hl-display-cart-item',
  templateUrl: './hl-display-cart-item.component.html',
  styleUrls: ['./hl-display-cart-item.component.css']
})
export class HlDisplayCartItemComponent extends CartItemComponent implements OnInit {
  isPortalNo = environment.mainCountry === 'no';
  is1210Bolag = environment.bolag === '1210';
  public isManagersNisa = environment.managers && environment.mainCountry === 'en';
  isSales = false;
  shouldShowFirstAvailableDateInCheckout = false;

  constructor(
    protected productInputQuantityConfigService: ProductInputQuantityConfigService,
    public productService: ProductService,
    protected cartService: CartService,
    protected priceService: PriceService,
    protected authService: AuthService,
    protected hlDisplayPortalFeaturesService: HlDisplayPortalFeaturesService
  ) {
    super(productService, cartService, priceService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.authService
      .getRoles()
      .pipe(first())
      .subscribe(roles => {
        this.isSales = roles.includes('SALES');
      });
    this.hlDisplayPortalFeaturesService
      .getPortalFeatureGroup('orderCheckoutSettingsGroup')
      .pipe(first())
      .subscribe(orderCheckoutSettingsGroup => {
        let orderCheckoutGroup = orderCheckoutSettingsGroup as CheckoutSettingsGroup;
        this.shouldShowFirstAvailableDateInCheckout = orderCheckoutGroup.shouldShowFirstAvailableDateInCheckout;
      });
  }

  getMinimumOrderQuantity(product: Product): number {
    return this.productInputQuantityConfigService.getMinimumOrderQuantity(product.id, product);
  }
}

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { format, parse } from 'date-fns';
import { DateUtilService } from 'gung-common';
import {
  AuthService,
  Availability,
  AvailabilityService,
  CalendarDate,
  CartRow,
  CartRowPrice,
  CartService,
  CartTotalsService,
  Customer,
  GungFlowService,
  GungModalService,
  MetadataService,
  PriceService,
  Product,
  ProductInputQuantityConfigService,
  ProductService,
  SelectedCustomerService
} from 'gung-standard';
import { JeevesSalesCartListComponent, JeevesSalesCartListRow } from 'gung-standard-jeeves';
import { first, forkJoin, map, of, tap } from 'rxjs';
import { HlCalendarDate } from '../../services/hl-delivery-date.service';
import {
  CheckoutSettingsGroup,
  HlDisplayPortalFeaturesService,
  OpenOrdersSettingsGroup,
  OrderEditSettingsGroup,
  PriceSettingsGroup
} from '../../services/hl-display-portal-features.service';
import { langExists } from '../../services/hl-product.service';
import { HlDisplayCheckoutAddRowModalComponent } from '../hl-display-checkout-add-row-modal/hl-display-checkout-add-row-modal.component';
import { environment } from './../../../environments/environment';

/** 
  As of 9/10/2024, this component is not used AT ALL. This is deprecated, and is left for legacy purposes. 
  
  With the changes and realignment with the portal alignment project, only the HlCartListComponent is used for the first checkout-step. 

  DO NOT USE THIS COMPONENT, UNLESS ANY CHANGES ARE TO MADE TO THE WAS HL WANTS THEIR CHECKOUT TO LOOK. 
*/
@Component({
  selector: 'app-hl-display-sales-cart-list',
  templateUrl: './hl-display-sales-cart-list.component.html',
  styleUrls: ['./hl-display-sales-cart-list.component.scss']
})
export class HlDisplaySalesCartListComponent extends JeevesSalesCartListComponent implements OnInit, OnDestroy {
  private orderLineItemNotInJeevesProductId = '155035';
  isSalesPortal = false;
  isPortalDe = environment.mainCountry === 'de';
  isPortalFr = environment.mainCountry === 'fr';
  shouldAddInstallationChargeAutomatically = false;
  shouldAddInstallationChargeManually = false;
  showComments = false;
  readOnlyPrice: boolean = false;
  productIdInstallationCharge = undefined;
  canEditOrderRow = false;

  updated = true; // flag to not add multiple installation
  oldPrice;
  hasChaged = false;
  constructor(
    protected productInputQuantityConfigService: ProductInputQuantityConfigService,
    protected productService: ProductService,
    protected cartService: CartService,
    protected priceService: PriceService,
    protected availabilityService: AvailabilityService,
    protected selectedCustomerService: SelectedCustomerService,
    protected dateUtilService: DateUtilService,
    private modalService: NgbModal,
    private metadataService: MetadataService,
    private gungModalService: GungModalService,
    private cartTotalsService: CartTotalsService,
    protected flowService: GungFlowService,
    protected translateService: TranslateService,
    protected authService: AuthService,
    protected hlDisplayPortalFeaturesService: HlDisplayPortalFeaturesService
  ) {
    super(
      productService,
      cartService,
      priceService,
      availabilityService,
      selectedCustomerService,
      dateUtilService,
      flowService
    );
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.mappedData, event.previousIndex, event.currentIndex);

    this.cartService.reorderCart(
      this.mappedData.map(row => ({
        productId: row.cartRow.productId,
        targetStockId: row.cartRow.targetStockId,
        productPartialId: row.cartRow.productPartialId
      }))
    );
  }

  ngOnInit() {
    super.ngOnInit();
    forkJoin({
      user: this.authService.getCurrentUser().pipe(first()),
      roles: this.authService.getRoles().pipe(first()),
      checkoutSettingsGroup: this.hlDisplayPortalFeaturesService
        .getPortalFeatureGroup('orderCheckoutSettingsGroup')
        .pipe(first()),
      orderEditSettingsGroup: this.hlDisplayPortalFeaturesService
        .getPortalFeatureGroup('orderEditSettingsGroup')
        .pipe(first()),
      openOrderSettings: this.hlDisplayPortalFeaturesService.getPortalFeatureGroup('openOrdersSettingsGroup').pipe(
        first(),
        map(group => group as OpenOrdersSettingsGroup)
      )
    }).subscribe(({ user, roles, checkoutSettingsGroup, orderEditSettingsGroup, openOrderSettings }) => {
      let checkoutSettings = checkoutSettingsGroup as CheckoutSettingsGroup;
      let orderEditGroup = orderEditSettingsGroup as OrderEditSettingsGroup;
      this.isSalesPortal = roles.includes('SALES');
      this.canEditOrderRow = orderEditGroup.canEditOrderRow;
      this.readOnlyPrice = user.extra.readOnlyPrice && !orderEditGroup.canEditOrderRow;
      this.showComments = checkoutSettings.shouldShowCommentsInCartList;
      this.shouldAddInstallationChargeAutomatically = checkoutSettings.addInstallationChargeAutomatically;
      this.shouldAddInstallationChargeManually = checkoutSettings.addInstallationChargeManually;
      this.isPortalDe = openOrderSettings.mainCountry.trim().toLowerCase() === 'de';
      this.isPortalFr = openOrderSettings.mainCountry.trim().toLowerCase() === 'fr';
      this.productIdInstallationCharge = '900070';

      // = this.isPortalDe
      //   ? '900070'
      //   : this.shouldAddInstallationCharge
      //   ? '183215'
      // : undefined;
    });
  }

  parseDate(dateString: string): Date {
    return parse(dateString, 'yyyy-MM-dd', new Date());
  }

  onDeliveryDateSelect(row: JeevesSalesCartListRow, selectedDate: HlCalendarDate) {
    const oldVal = row.deliveryDate || row.minDate;

    const deliveryDate = new NgbDate(
      selectedDate.estimatedShippingDate.getFullYear(),
      selectedDate.estimatedShippingDate.getMonth() + 1,
      selectedDate.estimatedShippingDate.getDate()
    );

    if (oldVal === deliveryDate) {
      return;
    }

    if (!deliveryDate) {
      this.cartService.removeExtraField(row.productId, 'orp.ordberlevdat', row.targetStockId);
      return;
    }

    this.cartService.setExtra(
      row.productId,
      {
        estDeliveryDate: format(selectedDate.date, 'yyyy-MM-dd'),
        orp: {
          ordberlevdat: format(new Date(deliveryDate.year, deliveryDate.month - 1, deliveryDate.day), 'yyyy-MM-dd')
        }
      },
      row.targetStockId,
      row.productPartialId
    );
  }

  public updateQty(row: JeevesSalesCartListRow, value: any) {
    this.hasChaged = false;
    let newQty = Number.parseInt(value.replace(/[^0-9]/g, ''), 10);
    const oldQty = row.quantity;
    const minimumOrderQuantity = this.productInputQuantityConfigService.getMinimumOrderQuantity(
      row.product.id,
      row.product
    );

    if (this.isSalesPortal) {
      // for sales portal when adjust the value, ignore the stepAmount
      if (newQty < minimumOrderQuantity) {
        newQty = minimumOrderQuantity;
      }
    } else {
      if (newQty % minimumOrderQuantity === 0) {
      } else {
        const valueRoundedUp = Math.trunc(newQty / minimumOrderQuantity) * minimumOrderQuantity + minimumOrderQuantity;
        newQty = valueRoundedUp;
      }
    }
    // check qty
    if (oldQty === newQty) {
      return;
    }
    // value has changed, propagate throught the cart
    this.cartService.setProductQuantity(row.productId, newQty, row.targetStockId, row.productPartialId);

    if (!!row.productPartialId && row.productId === this.orderLineItemNotInJeevesProductId) {
      this.cartService.setExtra(
        row.productId,
        {
          orp: {
            ordantal: newQty
          }
        },
        row.targetStockId,
        row.productPartialId
      );
    }
  }

  updateOverridePrice(row: JeevesSalesCartListRow, value: any) {
    this.hasChaged = false;
    const stringValue = value as string;
    const formattedValue = stringValue.replace(',', '.');
    const numValue = parseFloat(formattedValue);
    super.updateOverridePrice(row, numValue);
  }

  overrideDate(row: JeevesSalesCartListRow, value: CalendarDate) {
    if (this.dateUtilService.getFormattedDateString(value?.date) === row?.deliveryDate) {
      // Don't change if it is the same date
      return;
    }
    const hl: HlCalendarDate = value as HlCalendarDate;
    this.onDeliveryDateSelect(row, hl);
  }

  addNewOrderLine(): void {
    this.modalService.open(HlDisplayCheckoutAddRowModalComponent, { size: 'lg', backdrop: 'static' });
  }

  getName(product: Product): string {
    const itemGroup = this.metadataService.getMetadataValue('vg', 'varugruppbeskr', product.extra.ar.varugruppkod);
    let itemDesc = product.extra.ar.artbeskr;
    if (langExists(environment.mainCountry)(product)) {
      itemDesc = product.extra.i18n[environment.mainCountry].artbeskr || itemDesc;
    }
    return `${itemGroup} - ${itemDesc}`;
  }

  openCommentsModal(row: JeevesSalesCartListRow) {
    this.gungModalService.openEditNoteModal((row.cartRow.extra.orp && row.cartRow.extra.orp.Editext) || '').then(
      result => {
        if (!result) {
          return;
        }
        if (result.note.trim() !== '') {
          this.cartService.setExtra(
            row.productId,
            {
              orp: {
                ...row.cartRow.extra.orp,
                Editext: result.note
              }
            },
            row.targetStockId,
            row.productPartialId
          );
        } else {
          this.cartService.removeExtraField(row.productId, 'orp.Editext', row.targetStockId, row.productPartialId);
        }
      },
      dissmissReason => {
        console.log(dissmissReason);
      }
    );
  }

  updateMappedData(d: JeevesSalesCartListRow[]) {
    super.updateMappedData(d);
    this.updated = true;
    /*if (this.is1210Bolag && this.isSalesPortal) { GO THROUGH
      this.mappedData.map(av => {
        const firstDate = Object.keys(av.product.extra.availabilities[0].extra.availabilities).filter(key => {
          return av.product.extra.availabilities[0].extra.availabilities[key] > 0;
        })[0];

        if (av.product.extra.availabilities[0].extra.availabilities[firstDate] != 100000) {
          av.product.extra.availabilities[0].maxFutureStock = av.product.extra.availabilities[0].extra.availabilities[firstDate]
          av.product.extra.availabilities[0].dateMaxFutureStock = this.dateUtilService.changeDateFormat(firstDate, 'yyMMdd')
        }
      })
    }*/
    if (this.shouldAddInstallationChargeAutomatically) {
      this.addInstallationChargeFixed(0.1);
    }
  }

  addInstallationChargeFixed(value: number) {
    const productId = this.productIdInstallationCharge;
    const installationRow = this.mappedData?.find(d => d.productId === productId);

    forkJoin([
      this.selectedCustomerService.getSelectedCustomer().pipe(first()),
      this.cartTotalsService.getTotals().pipe(first())
    ]).subscribe(([customer, totals]) => {
      const customerFinancialGroup = customer.extra.kus.q_financialgrpcode;
      const discountFactor = environment.discountCustomerFinancialGroup[customerFinancialGroup]
        ? environment.discountCustomerFinancialGroup[customerFinancialGroup]
        : 0;

      let price: string;
      if (installationRow) {
        price = ((totals.totalValue - installationRow.price.cartRowTotalPriceInclRowDiscount.value) * value).toFixed(2);
      } else {
        price = (totals.totalValue * value).toFixed(2);
      }

      const deliveryDate =
        (this.mappedData.length > 0 && this.mappedData[0].deliveryDate) || format(new Date(), 'yyyy-MM-dd');

      const theOrp = {
        vb_pris: price,
        ordantal: 1,
        rabatt1: undefined,
        artbeskr: this.translateService.instant('INSTALLATION_COST'),
        ordberlevdat: deliveryDate
      };

      if (!installationRow && this.mappedData.length > 0) {
        this.oldPrice = price;
        this.cartService.addToCart(productId, 1);

        this.cartService.setExtra(productId, {
          orp: theOrp,
          _discountCustomerFinancialGroupInfo: {
            appliedFactor: discountFactor,
            appliedTo: price,
            appliedFrom: 'HlDisplaySalesCartListComponent - addInstallationCharge()'
          }
        });
      }

      if (this.oldPrice !== price && !this.hasChaged) {
        this.hasChaged = true;
        this.cartService.setExtra(productId, {
          orp: theOrp,
          _discountCustomerFinancialGroupInfo: {
            appliedFactor: discountFactor,
            appliedTo: price,
            appliedFrom: 'HlDisplaySalesCartListComponent - addInstallationCharge()'
          }
        });
      }

      if (installationRow && this.mappedData.length === 1) {
        this.cartService.removeRow(installationRow.cartRow);
      }
    });
  }

  addInstallationCharge(value: number) {
    if (!this.updated) {
      return;
    }
    this.updated = false;
    const productId = this.productIdInstallationCharge;
    const installationRow = this.mappedData?.find(d => d.productId === productId);
    if (installationRow) {
      this.cartService.removeRow(installationRow.cartRow);
    }
    forkJoin([
      this.selectedCustomerService.getSelectedCustomer().pipe(first()),
      this.cartTotalsService.getTotals().pipe(first())
    ]).subscribe(([customer, totals]) => {
      const customerFinancialGroup = customer.extra.kus.q_financialgrpcode;
      const discountFactor = environment.discountCustomerFinancialGroup[customerFinancialGroup]
        ? environment.discountCustomerFinancialGroup[customerFinancialGroup]
        : 0;

      const price = (totals.totalValue * value).toFixed(2);
      const deliveryDate =
        (this.mappedData.length > 0 && this.mappedData[0].deliveryDate) || format(new Date(), 'yyyy-MM-dd');

      const theOrp = {
        vb_pris: price,
        ordantal: 1,
        rabatt1: undefined,
        artbeskr: this.translateService.instant('INSTALLATION_COST'),
        ordberlevdat: deliveryDate
      };
      this.cartService.addToCart(productId, 1);

      // This doesn't call SalesCartCartService, thats why the workaround above
      this.cartService.setExtra(productId, {
        orp: theOrp,
        _discountCustomerFinancialGroupInfo: {
          appliedFactor: discountFactor,
          appliedTo: price,
          appliedFrom: 'HlDisplaySalesCartListComponent - addInstallationCharge()'
        }
      });
    });
  }

  public mapData(
    products: Product[],
    prices: CartRowPrice[],
    availabilities: Availability[],
    cartRows: CartRow[],
    customer: Customer
  ): JeevesSalesCartListRow[] {
    return cartRows.map(cr => {
      const product = products.find(p => p.id === cr.productId);
      const price = prices.find(
        p => p.productId === cr.productId && (!cr.productPartialId || p.productPartialId === cr.productPartialId)
      );
      const av = availabilities.find(a => a.productId === cr.productId);
      const minDateJs: Date = this.getMinDate(av);
      const toReturn: JeevesSalesCartListRow = {
        productId: cr.productId,
        productPartialId: cr.productPartialId,
        name: product.name,
        quantity: cr.qty,
        originalPrice: price.customerGrossPrice.value,
        currency: price.customerNetPrice.currencyCode,
        minDate: new NgbDate(minDateJs.getFullYear(), minDateJs.getMonth() + 1, minDateJs.getDate()),
        targetStockId: cr.targetStockId,
        cartRowPriceElement: price,
        deliveryMethod: customer.extra.kus.levsattkod,
        cartRow: cr,
        currentAvailability: av.currentAvailability,
        product,
        price
      };
      if (cr.extra && cr.extra.orp && cr.extra.orp.rabatt1) {
        toReturn.discountPercent = cr.extra.orp.rabatt1;
      }
      if (cr.extra && cr.extra.orp && cr.extra.orp.rabatt2) {
        toReturn.discountPercent2 = cr.extra.orp.rabatt2;
      }
      if (cr.extra && cr.extra.orp && cr.extra.orp.vb_pris) {
        toReturn.overridePrice = cr.extra.orp.vb_pris;
      }
      if (cr.extra && cr.extra.orp && cr.extra.orp.artbeskr) {
        toReturn.description = cr.extra.orp.artbeskr;
      }
      if (this.getDeliveryDateFromRow(cr)) {
        const setDate = new Date(this.getDeliveryDateFromRow(cr));
        toReturn.deliveryDate = format(setDate, 'yyyy-MM-dd');
      }
      return toReturn;
    });
  }

  updateData() {
    const ids = this.data.map(c => c.productId);
    const cachedIds = Object.keys(this.cachedData);
    const toGetIds = ids.filter(id => !cachedIds.includes(id));

    const sub = forkJoin({
      products: this.productService.getProductsByIds(toGetIds).pipe(first()),
      prices: this.priceService.getCartRowPricesObservable(this.data).pipe(first()),
      availabilities: this.availabilityService.getAvailabilities(toGetIds, undefined, true).pipe(
        first(),
        map(avs => avs.map(av => ({ ...av, availabilities: av.extra.availabilities })))
      ),
      cartRows: of(this.data),
      customer: this.selectedCustomerService.getSelectedCustomer().pipe(first())
    })
      .pipe(
        first(),
        tap(resp => {
          this.cachedData = {
            ...this.cachedData,
            ...toGetIds
              .map(id => ({
                product: resp.products.find(p => p.id === id),
                price: resp.prices.find(p => p.productId === id),
                availability: resp.availabilities.find(av => av.productId === id)
              }))
              .reduce((acc, curr) => ({ ...acc, [curr.product.id]: curr }), {})
          };
        }),
        map(data => {
          const cachedProducts = Object.values(this.cachedData).map(d => d.product);
          const cachedPrices = data.prices;
          const cachedAvs = Object.values(this.cachedData).map(d => d.availability);
          return this.mapData(cachedProducts, cachedPrices, cachedAvs, data.cartRows, data.customer);
        })
      )
      .subscribe(d => this.updateMappedData(d));

    this.subscriptions.push(sub);
  }
}

import {
  Component,
  ComponentFactoryResolver,
  ChangeDetectorRef,
  Optional,
  HostListener,
  AfterViewInit
} from '@angular/core';
import { Location } from '@angular/common';
import {
  FilterListComponent,
  FilterListService,
  AbstractSelectionService,
  FilterSelectionAction,
  FilterListRoutingService
} from 'gung-list';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from 'gung-standard';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-hl-product-list',
  templateUrl: './hl-product-list.component.html',
  styleUrls: ['./hl-product-list.component.css']
})
export class HlProductListComponent extends FilterListComponent<Product> implements AfterViewInit {
  private smallScreeenWidth = 576;
  private currentLayout = 0;
  isSmallScreeen = false;

  private isSales = environment.sales;

  ngAfterViewInit(): void {
    const layouts = this.configService.getLayouts();
    if (this.isSales && window.innerWidth < this.smallScreeenWidth) {
      this.currentLayout = 1;
      this.isSmallScreeen = true;
    }
    const toRender = layouts[this.currentLayout];
    this.renderLayout(toRender.getListLayoutComponent(), toRender.getListItemComponent());
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (!this.isSales) {
      return;
    }
    const windowObject = event.target || window;
    this.isSmallScreeen = windowObject.innerWidth < this.smallScreeenWidth;
    if (this.isSmallScreeen && this.currentLayout !== 1) {
      this.changeLayout(1);
      this.currentLayout = 1;
    }
  }

  setMultipleSelections(actions: FilterSelectionAction[]) {
    super.setMultipleSelections(actions);
    this.renderFilters = JSON.parse(JSON.stringify(this.renderFilters)); // Force to refresh the headr and filters
  }
}

import { Component, OnInit, ComponentFactoryResolver, ChangeDetectorRef } from '@angular/core';
import { ListLayoutComponent, ListLayoutSingleComponent } from 'gung-list';
import { CartService } from 'gung-standard';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import {
  HlDisplayPortalFeaturesService,
  CheckoutSettingsGroup
} from '../../services/hl-display-portal-features.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-hl-display-cart-list-layout',
  templateUrl: './hl-display-cart-list-layout.component.html',
  styleUrls: ['./hl-display-cart-list-layout.component.css']
})
export class HlDisplayCartListLayoutComponent<T> extends ListLayoutSingleComponent<T> {
  shouldShowFirstAvailableDateInCheckout = false;

  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
    changeDetectorRef: ChangeDetectorRef,
    private cartService: CartService,
    private modalService: NgbModal,
    protected hlDisplayPortalFeaturesService: HlDisplayPortalFeaturesService
  ) {
    super(componentFactoryResolver, changeDetectorRef);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.hlDisplayPortalFeaturesService
      .getPortalFeatureGroup('orderCheckoutSettingsGroup')
      .pipe(first())
      .subscribe(orderCheckoutSettingsGroup => {
        let orderCheckoutGroup = orderCheckoutSettingsGroup as CheckoutSettingsGroup;
        this.shouldShowFirstAvailableDateInCheckout = orderCheckoutGroup.shouldShowFirstAvailableDateInCheckout;
      });
  }

  public removeAll(content): void {
    this.modalService.open(content).result.then(
      closeResult => {
        if (closeResult === 'CLEAR_CART') {
          this.cartService.clearCart();
        }
      },
      dissmissReason => {
        console.log(dissmissReason);
      }
    );
  }
}

<div class="form-group" *ngIf="loaded; else loading">
  <div class="input-group">
    <input class="form-control" placeholder="yyyy-mm-dd" name="dp" ngbDatepicker #d="ngbDatepicker" container="body"
      [markDisabled]="markDisabled" (dateSelect)="dateSelected($event)" [minDate]="minDate"
      [startDate]="firstDatePickerDate" [(ngModel)]="ngModel" [readonly]="true" />
    <div class="input-group-append d-flex">
      <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
        <i class="fas fa-calendar"></i>
      </button>
    </div>
  </div>
</div>

<ng-template #loading> LOADING... </ng-template>

<div *ngIf="order">
  <div class="d-flex flex-row justify-content-between align-items-center mb-4 mt-4">
    <h2 class="title-header">
      <small>
        <b>
          {{ (order.extra.oh?.ordstat === 0 ? 'QUOTE' : 'ORDER_TITLE') | translate }} {{ order.id }}
          <span *ngIf="order.extra.deliveryCustomer || order.extra._deliveryCustomerName">
            - {{ order.extra._deliveryCustomerName || order.extra.deliveryCustomer.name }}</span
          >
        </b>
      </small>
    </h2>
    <!--    <div class="form-group">
      <div ngbDropdown placement="bottom-right" class="d-inline-block">
        <button class="btn btn-outline-primary action-button" id="dropdownBasic1" ngbDropdownToggle
          translate>ACTIONS</button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-container">
          <gung-jeeves-jeeves-edit-order-modal (orderChange)='orderUpdated($event)'
            *ngIf="order.extra.oh.ordstat === 0 && isSales" [order]="order">
          </gung-jeeves-jeeves-edit-order-modal>
          <button ngbDropdownItem (click)="reroute('/download/order-excel/' + order.id)" translate>XLSX</button>
          <button ngbDropdownItem (click)="reroute('/download/order-pdf/' + order.id)" translate>PDF</button>
          <button ngbDropdownItem (click)="reroute('/download/order-zip/' + order.id)" translate>IMAGES</button>
          <button ngbDropdownItem translate>QUOTE_TO_ORDER</button>
        </div>
      </div>
    </div> -->
    <lib-action-btn [item]="order" [config]="configService"></lib-action-btn>
  </div>
  <div class="row mb-3">
    <div class="col">
      <lib-gung-accordion>
        <lib-gung-accordion-item id="panel-1">
          <ng-template libGungAccordionHeader>{{ 'DETAILS_AND_CONDITIONS' | translate }}</ng-template>
          <ng-template libGungAccordionBody>
            <div class="row">
              <div class="col-12 col-md-6 mb-2">
                <h5 translate>DETAILS</h5>
                <lib-gung-text-input-horizontal
                  [labelClass]="['col-md-5']"
                  renderStatic="true"
                  gungTranslate="ORDER_NUMBER"
                  [(ngModel)]="order.extra.oh.ordernr"
                >
                </lib-gung-text-input-horizontal>
                <lib-gung-text-input-horizontal
                  [labelClass]="['col-md-5']"
                  renderStatic="true"
                  gungTranslate="CUSTOMER_REFERENCE"
                  [(ngModel)]="order.extra.oh.kundref2"
                >
                </lib-gung-text-input-horizontal>

                <lib-gung-meta-output
                  [labelClass]="['col-md-5']"
                  [value]="order.extra.oh.ordstat"
                  [gungTranslate]="'ORDER_STATUS'"
                  metadataTable="x7"
                  metaOptionName="ordstatbeskr"
                  [horizontal]="true"
                >
                </lib-gung-meta-output>
                <lib-gung-date-output
                  [labelClass]="['col-md-5']"
                  [horizontal]="true"
                  [gungTranslate]="'ORDER_DATE'"
                  [value]="order.extra.oh.orddatum"
                  [dateFormat]="dateFormat"
                >
                </lib-gung-date-output>
                <lib-gung-date-output
                  [labelClass]="['col-md-5']"
                  [horizontal]="true"
                  [gungTranslate]="'ESTIMATED_DELIVERY_DATE'"
                  [value]="order.extra.oh.ordberlevdat"
                  [dateFormat]="dateFormat"
                >
                </lib-gung-date-output>
              </div>
              <div class="col-12 col-md-6">
                <h5 translate>CONDITIONS</h5>
                <lib-gung-meta-output
                  [labelClass]="['col-md-5']"
                  [horizontal]="true"
                  [gungTranslate]="'PAYMENT_TERMS'"
                  [value]="order.extra.oh.betkod"
                  metadataTable="x2"
                  metaOptionName="betvillbeskr"
                >
                </lib-gung-meta-output>
                <lib-gung-meta-output
                  [labelClass]="['col-md-5']"
                  [horizontal]="true"
                  [gungTranslate]="'DELIVERY_TERMS'"
                  [value]="order.extra.oh.levvillkkod"
                  metadataTable="x2e"
                  metaOptionName="levvillkbeskr"
                >
                </lib-gung-meta-output>
                <lib-gung-meta-output
                  [labelClass]="['col-md-5']"
                  [horizontal]="true"
                  [gungTranslate]="'DELIVERY_METHOD'"
                  [value]="order.extra.oh.levsattkod"
                  metadataTable="x2f"
                  metaOptionName="levsattbeskr"
                >
                </lib-gung-meta-output>
                <div *ngIf="isSales && order.extra.deliveries && order.extra.deliveries.length > 0">
                  <b translate>DELIVERY_TRACKING</b>
                  <div *ngFor="let delivery of order.extra.deliveries">
                    <a *ngIf="delivery.q_hl_tracktrace_url_fra" href="{{ delivery.q_hl_tracktrace_url_fra }}"
                      >{{ 'DELIVERY' | translate }} - {{ delivery.ordberlevdat | date: dateFormat }}</a
                    >
                    <a *ngIf="delivery.q_hl_tracktrace_url" href="{{ delivery.q_hl_tracktrace_url }}"
                      >{{ 'DELIVERY' | translate }} - {{ delivery.ordberlevdat | date: dateFormat }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </lib-gung-accordion-item>

        <lib-gung-accordion-item id="panel-2">
          <ng-template libGungAccordionHeader>{{ 'SHIPPING_INFORMATION' | translate }}</ng-template>
          <ng-template libGungAccordionBody>
            <div class="row">
              <div class="col-12 col-md-6 mb-2">
                <h5 translate>ADDRESS</h5>

                <div [ngSwitch]="addressInfoDisplayVersion">
                  <div *ngSwitchCase="'POD'">
                    <lib-gung-text-input-horizontal
                      [labelClass]="['col-12 col-md-6']"
                      renderStatic="true"
                      gungTranslate="ADDRESS1"
                      [(ngModel)]="order.extra.pod.ftgpostadr1"
                    ></lib-gung-text-input-horizontal>
                    <lib-gung-text-input-horizontal
                      [labelClass]="['col-12 col-md-6']"
                      renderStatic="true"
                      gungTranslate="ADDRESS2"
                      [(ngModel)]="order.extra.pod.ftgpostadr2"
                    ></lib-gung-text-input-horizontal>
                    <lib-gung-text-input-horizontal
                      [labelClass]="['col-12 col-md-6']"
                      renderStatic="true"
                      gungTranslate="CITY"
                      [(ngModel)]="order.extra.pod.ftgpostadr3"
                    ></lib-gung-text-input-horizontal>
                    <lib-gung-text-input-horizontal
                      [labelClass]="['col-12 col-md-6']"
                      renderStatic="true"
                      gungTranslate="ZIP"
                      [(ngModel)]="order.extra.pod.ftgpostnr"
                    >
                    </lib-gung-text-input-horizontal>
                    <lib-gung-meta-output
                      [labelClass]="['col-12 col-md-6']"
                      [horizontal]="true"
                      [gungTranslate]="'COUNTRY'"
                      [value]="order.extra.pod.landskod"
                      metadataTable="xr"
                      metaOptionName="land"
                    >
                    </lib-gung-meta-output>
                  </div>
                  <div *ngSwitchCase="'Delivery'">
                    <lib-gung-text-input-horizontal
                      [labelClass]="['col-12 col-md-6']"
                      renderStatic="true"
                      gungTranslate="ADDRESS1"
                      [(ngModel)]="order.extra.oh.delivaddr2"
                    ></lib-gung-text-input-horizontal>
                    <lib-gung-text-input-horizontal
                      [labelClass]="['col-12 col-md-6']"
                      renderStatic="true"
                      gungTranslate="ADDRESS2"
                      [(ngModel)]="order.extra.oh.delivaddr3"
                    ></lib-gung-text-input-horizontal>

                    <lib-gung-text-input-horizontal
                      [labelClass]="['col-12 col-md-6']"
                      renderStatic="true"
                      gungTranslate="CITY"
                      [(ngModel)]="order.extra.oh.delivaddrcityexclzip"
                    ></lib-gung-text-input-horizontal>
                    <lib-gung-text-input-horizontal
                      [labelClass]="['col-12 col-md-6']"
                      renderStatic="true"
                      gungTranslate="ZIP"
                      [(ngModel)]="order.extra.oh.delivaddrzipcode"
                    >
                    </lib-gung-text-input-horizontal>
                    <lib-gung-meta-output
                      [labelClass]="['col-12 col-md-6']"
                      [horizontal]="true"
                      [gungTranslate]="'COUNTRY'"
                      [value]="order.extra.oh.delivaddrcountrycode"
                      metadataTable="xr"
                      metaOptionName="land"
                    >
                    </lib-gung-meta-output>
                  </div>
                  <div *ngSwitchDefault>
                    <lib-gung-text-input-horizontal
                      [labelClass]="['col-12 col-md-6']"
                      renderStatic="true"
                      gungTranslate="ADDRESS1"
                      [(ngModel)]="order.extra.oh.ordlevadr2"
                    ></lib-gung-text-input-horizontal>
                    <lib-gung-text-input-horizontal
                      [labelClass]="['col-12 col-md-6']"
                      renderStatic="true"
                      gungTranslate="ADDRESS2"
                      [(ngModel)]="order.extra.oh.ordlevadr3"
                    ></lib-gung-text-input-horizontal>

                    <lib-gung-text-input-horizontal
                      [labelClass]="['col-12 col-md-6']"
                      renderStatic="true"
                      gungTranslate="CITY"
                      [(ngModel)]="order.extra.oh.ordlevadr4"
                    ></lib-gung-text-input-horizontal>
                    <lib-gung-text-input-horizontal
                      [labelClass]="['col-12 col-md-6']"
                      renderStatic="true"
                      gungTranslate="ZIP"
                      [(ngModel)]="order.extra.oh.ftgpostnr"
                    >
                    </lib-gung-text-input-horizontal>
                    <lib-gung-meta-output
                      [labelClass]="['col-12 col-md-6']"
                      [horizontal]="true"
                      [gungTranslate]="'COUNTRY'"
                      [value]="order.extra.oh.ordlevadrlandskod"
                      metadataTable="xr"
                      metaOptionName="land"
                    >
                    </lib-gung-meta-output>
                  </div>
                </div>
              </div>
              <div class="col-12" *ngIf="!isSales && order.extra.deliveries && order.extra.deliveries.length > 0">
                <b translate>DELIVERY_TRACKING</b>
                <div *ngFor="let delivery of order.extra.deliveries">
                  <a *ngIf="delivery.q_hl_tracktrace_url_fra" href="{{ delivery.q_hl_tracktrace_url_fra }}">{{
                    delivery.q_hl_tracktrace_url_fra
                  }}</a>
                  <a *ngIf="delivery.q_hl_tracktrace_url" href="{{ delivery.q_hl_tracktrace_url }}">{{
                    delivery.q_hl_tracktrace_url
                  }}</a>
                </div>
              </div>
            </div>
          </ng-template>
        </lib-gung-accordion-item>

        <lib-gung-accordion-item id="panel-3" *ngIf="!isManagersPortal">
          <ng-template libGungAccordionHeader>{{ 'ADDITIONAL_INFORMATION' | translate }}</ng-template>
          <ng-template libGungAccordionBody>
            <div class="form-group row">
              <label for="oh.editext" class="col-form-label text-right-sm text-muted col-12 col-sm-4" translate
                >MESSAGE</label
              >
              <div class="col">
                <textarea
                  *ngIf="!isSales"
                  class="form-control"
                  style="min-height: 200px"
                  type="text"
                  name="editext"
                  id="oh.editext"
                  [(ngModel)]="order.extra.oh.editext"
                  [disabled]="isSales && order.extra.oh.ordstat === 0 ? undefined : true"
                ></textarea>
                <textarea
                  *ngIf="isSales"
                  class="form-control"
                  style="min-height: 200px"
                  type="text"
                  name="editext"
                  id="oh.editext"
                  [(ngModel)]="order.extra.oh.edit"
                  [disabled]="isSales && order.extra.oh.ordstat === 0 ? undefined : true"
                ></textarea>
              </div>
            </div>
            <button
              *ngIf="isSales && order.extra.oh.ordstat === 0"
              type="button"
              class="btn btn-primary float-right mb-2"
              (click)="saveOrderMessage($event)"
              translate
            >
              SAVE
            </button>
          </ng-template>
        </lib-gung-accordion-item>
      </lib-gung-accordion>
    </div>
  </div>

  <div class="row">
    <div class="col-12 table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="d-none d-sm-table-cell" translate>PRODUCT_ID</th>
            <th translate>NAME</th>
            <th class="d-none d-lg-table-cell" translate>ESTIMATED_DELIVERY_DATE</th>
            <th translate>QUANTITY</th>
            <th class="d-none d-sm-table-cell" translate>UNIT_PRICE</th>
            <th class="d-none d-sm-table-cell" translate>DISCOUNT</th>
            <th translate>TOTAL_PRICE</th>
            <th *ngIf="order.extra.oh.ordstat === 0 && isSales"></th>
          </tr>
        </thead>
        <tbody *ngIf="order">
          <tr *ngFor="let row of order.rows" [ngClass]="row.extra.orp.ordradst === 90 ? 'deleted' : ''">
            <td class="d-none d-sm-table-cell">
              <a routerLink="/product/{{ row.productId }}">{{ row.productId }}</a>
            </td>
            <td>
              <a routerLink="/product/{{ row.productId }}">{{ row.extra.orp.artbeskr }}</a>
            </td>
            <td class="d-none d-lg-table-cell">
              {{ row.extra.orp.ordberlevdat | date: dateUtilService.dateFormat }}
            </td>
            <td>
              {{ row.quantity }}
            </td>
            <td class="d-none d-sm-table-cell">
              {{ row.extra.orp.vb_pris | gungCurrency: order.extra.oh.valkod : 'symbol-narrow' : '1.2-2' }}
            </td>
            <td class="d-none d-sm-table-cell">{{ row.extra.orp.rabatt1 }}%</td>
            <td>
              {{ row.extra.orp.vbordradsum | gungCurrency: order.extra.oh.valkod : 'symbol-narrow' : '1.2-2' }}
            </td>
            <td *ngIf="order.extra.oh.ordstat === 0 && isSales">
              <app-hl-display-edit-orderrow-modal (orderRowChange)="orderUpdated($event)" [orderRow]="row">
              </app-hl-display-edit-orderrow-modal>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="col-12" *ngIf="order.extra.oh.ordstat === 0 && isSales">
      <button class="btn btn-primary btn-block" (click)="addRow()" translate>ADD_ROW</button>
    </div>
    <div class="col-12">
      <hr class="hr" />
    </div>
    <div class="col-12 text-right">
      <h5>
        <span translate>TOTAL</span>:
        <span class="sum-label">
          {{ getTotal(order.rows) | gungCurrency: order.extra.oh.valkod : 'symbol-narrow' : '1.2-2' }}
        </span>
      </h5>
    </div>
  </div>
</div>
